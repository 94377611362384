import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { DragAndDropContext } from 'contexts';
import { IPosition, IDimension } from 'declarations';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'theme';

type ClassNames = 'root' | 'indication';

type StyleProps = {
  pos: IPosition
  dim: IDimension
  placeDim: IDimension
};

const useStyles = createUseStyles<ClassNames, StyleProps, Theme>({
  root: ({
    pos, dim, placeDim,
  }) => ({
    position: 'absolute',
    width: dim.w * (placeDim.w / 4),
    height: dim.h * (placeDim.h / 2),
    left: pos.x * (placeDim.w / 4),
    top: pos.y * (placeDim.h / 2),
    padding: 5,
  }),
  indication: ({ theme }) => ({
    width: '100%',
    height: '100%',
    strokeWidth: 10,
    stroke: theme.color.primary,
    fill: 'transparent',
    opacity: 0.5,
    borderRadius: 10,
  }),
});

export default function DropIndicator({ pos, dim, enable }: Props) {
  const theme = useTheme<Theme>();
  const { placeDim } = useContext(DragAndDropContext);
  const classes = useStyles({
    pos,
    dim,
    theme,
    placeDim,
  });

  return enable ? (
    ReactDOM.createPortal(
      <div className={classes.root}>
        <svg className={classes.indication}>
          <rect width="100%" height="100%" rx="10" />
        </svg>
      </div>, document.getElementById('module-place')!,
    )

  ) : <></>;
}

type Props = {
  pos: IPosition
  dim: IDimension
  enable: boolean
};
