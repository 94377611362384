import React from 'react';
import ReactDOM from 'react-dom';
import 'css/general.css';
import { App } from 'App';
import {
  ApolloProvider, ApolloClient, HttpLink, split, ApolloLink,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import { SentryLink, excludeGraphQLFetch } from 'apollo-link-sentry';
import { print } from 'graphql';
import { matchPath } from 'react-router-dom';
import { RouteConfig } from '@sentry/react/types/reactrouter';
import cache from './graphql/cache';
import reportWebVitals from './reportWebVitals';

const history = createBrowserHistory();

const routes: RouteConfig[] = [
  { path: '/room/:roomId', exact: true },
  { path: '/room/:roomId/:tab', exact: true },
  { path: '/audience/:roomId', exact: true },
  { path: '/', exact: true },
  { path: '/' },
];

Sentry.init({
  dsn: 'https://4e3425e2b51d48228105a2736253c646@o263407.ingest.sentry.io/5907181',
  integrations: [new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
  })],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENV || 'development',
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  beforeBreadcrumb: excludeGraphQLFetch,
});

const httpLinkURI = process.env.REACT_APP_API_URL || 'http://localhost:4002/graphql';

const apolloSentryLink = new SentryLink({
  uri: httpLinkURI,
  setTransaction: false,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeError: true,
  },
});

const errorLink = onError(({
  networkError, graphQLErrors, operation,
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      const { message, locations, path } = err;
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );

      if (process.env.NODE_ENV === 'production') {
        Sentry.withScope((scope) => {
          scope.setExtra('query', print(operation.query));
          scope.setExtra('variables', operation.variables);

          Sentry.captureEvent({
            level: "error",
            message: `[GraphQL error]: ${message}`,
            extra: {
              locations,
              path,
              extensions: err.extensions,
            },
          });
        });
      }
    });
  }
  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_URL || 'ws://localhost:4002/graphql',
  options: {
    reconnect: true,
    timeout: 30000,
  },
});

const httpLink = new HttpLink({
  uri: httpLinkURI,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('host-token');// ff5d9ce2-1f5f-4c45-a91e-aef4b37fa059
  return {
    headers: {
      ...headers,
      'x-munative-lite-token': token ? `RoomHost ${token}` : '',
      // Add headers for sentry distributed tracing
      ...Sentry.getCurrentHub().traceHeaders(),
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    // new ApolloLink((operation, forward) => {
    //   console.log(operation.operationName);
    //   return forward(operation);
    // }),
    apolloSentryLink,
    splitLink,
  ]),
  cache,
  defaultOptions: {
    watchQuery: {
      // fetchPolicy: 'network-only',
      // nextFetchPolicy: 'cache-first',
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    {/* <React.StrictMode> */}
    <App history={history} />
    {/* </React.StrictMode> */}
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
