import React from 'react';
import Space from 'components/basic/Space';
import Icon from 'components/basic/Icon';
import { Alignment } from 'declarations';
import classes from './style.module.css';

export default React.forwardRef((
  {
    className,
    icon,
    iconColor,
    value,
    placeholder,
    onChange,
    onFocus,
    type = 'text',
    style,
    alignment,
    disabled = false,
  }: Props,
  ref: React.LegacyRef<HTMLInputElement>,
) => {
  return (
    <div 
      className={[classes.root, className].join(' ')} 
      style={{
        padding: icon ? "5px 8px" : "8px 8px" ,
        ...style
      }}
    >
      {icon && (
        <>
          <Space gap={5} h />
          <Icon icon={icon} color={iconColor || "var(--color-primary)"} size={30} />
          {' '}
          <Space gap={10} h />
        </>
      )}
      <input
        disabled={disabled}
        ref={ref}
        className={classes.input}
        style={{ textAlign: alignment }}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        min={type === 'number' ? 0 : undefined}
      />
    </div>
  );
});

type Props = {
  className?: string
  icon?: string
  iconColor?: string
  value: string | number
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  type?: string
  style?: React.CSSProperties
  alignment?: Alignment
  disabled?: boolean
};
