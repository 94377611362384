import React, { useEffect, useRef, useState } from 'react';
import Input from 'components/basic/Input/index';
import CircularProcessing from 'components/composed/CircularProcessbar';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import { ICoreProps } from 'components/module/moduleDefine';
import Icon from 'components/basic/Icon';
import Space from 'components/basic/Space';
import Tooltip from 'components/basic/Tooltip';
import { TimerModuleData } from 'graphql/generated/graphql';
import { fmtTimeMS } from 'utils/time';
import { TimerStatus, useTimer } from 'hooks/useTimer';
import classes from './style.module.css';

function NumberInput({ value, setValue, disabled }: InputProps) {
  const me = useRef<HTMLInputElement>(null);

  function changeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    if (!Number.isNaN(parseInt(val, 10))) {
      setValue(parseInt(val, 10));
    } else if (val === '') {
      setValue(0);
      me.current!.select();
    }
  }

  return (
    <Input
      disabled={disabled}
      ref={me}
      type="number"
      value={value.toString()}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e)}
      style={{ width: '30%' }}
      alignment="center"
    />
  );
}

type InputProps = {
  value: number
  setValue: (value: number) => void
  disabled: boolean
};

export default function MunativeTimer({
  data,
  setEditData,
  dispatchUpdateModule,
  isEdit,
  realDim,
  audience,
}: ICoreProps<TimerModuleData>) {
  const [title, setTitle] = useState('Timer');

  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(10);

  const titleRef = useRef<HTMLDivElement>(null);

  const [shaking, setShaking] = useState(false);

  const timer = useTimer(data.state, (partialState) => {
    setEditData({
      timer: {
        state: {
          startTime: data.state.startTime,
          totalTime: data.state.totalTime,
          elapsedTime: data.state.elapsedTime,
          ...partialState,
        },
      },
    });
    dispatchUpdateModule();
  });

  function play() {
    setShaking(false);
    timer.play();
  }

  function pause() {
    timer.pause();
  }

  function stop() {
    timer.reset();
    setShaking(true);

    setTimeout(() => {
      setShaking(false);
    }, 500);
  }

  useEffect(() => {
    setTitle(data.title);
    setMinute(Math.floor(data.state.totalTime / 60));
    setSecond(Math.floor((data.state.totalTime) % 60));
  }, [data]);

  function addTime(sec: number) {
    timer.addTime(sec);
  }

  useEffect(() => {
    if (isEdit) {
      const newTotalTime = (minute * 60 + second);

      setEditData({
        timer: {
          title,
          state: {
            totalTime: newTotalTime,
            elapsedTime: data.state.elapsedTime,
            startTime: data.state.startTime,
          },
        },
      });
    }
  }, [data.state, setEditData, isEdit, minute, second, title]);

  if (!audience) {
    return (
      <>
        <div className={['defaultView'].join(' ')}>
          <div className={classes.title} ref={titleRef}>
            {title}
          </div>
          <div className={shaking ? 'shake' : ''}>
            <CircularProcessing
              width={realDim.w * 0.7}
              thicken={realDim.w * 0.05}
              color={timer.status === TimerStatus.Running ? "var(--color-primary)" : "var(--color-secondary)"}
              max={timer.totalTime === 0 ? 1 : timer.totalTime}
              value={timer.remainTime}
            >
              <div className={classes.center}>
                <span onClick={timer.status === TimerStatus.Running ? pause : play}>
                  <Icon
                    icon={timer.status === TimerStatus.Running ? 'pause' : 'play'}
                    color={timer.status === TimerStatus.Running ? "var(--color-primary)" : "var(--color-secondary)"}
                    className={classes.button}
                    size={realDim.w * 0.2}
                  />
                </span>
                <div className={classes.timeLabel}>
                  {fmtTimeMS(timer.remainTime)}
                </div>
              </div>
            </CircularProcessing>
          </div>
          <Space v gap={realDim.h * 0.05} />
          <div className={classes.bottomBar}>
            <span style={{ fontSize: '20px' }}>{fmtTimeMS(timer.totalTime)}</span>
            <span onClick={() => stop()}>
              <Icon icon="stop" className={classes.button} size={realDim.w * 0.15} color="red" />
            </span>
            <span className={classes.moreTimeButton}>
              <Icon icon="more_time" size={realDim.w * 0.12} color={"var(--color-primary)"} />
              <Tooltip className={classes.tooltipFrame}>
                <span className={classes.moreTimeButton} onClick={() => addTime(10)}>
                  10s
                </span>
                <span className={classes.moreTimeButton} onClick={() => addTime(60)}>
                  1m
                </span>
                <span className={classes.moreTimeButton} onClick={() => addTime(5 * 60)}>
                  5m
                </span>
              </Tooltip>
            </span>
          </div>
        </div>

        <div className={['editView', isEdit ? 'active' : ''].join(' ')}>
          <Input
            alignment="center"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '70%' }}
          />
          <div className={classes.form} data-tip data-for={timer.status === TimerStatus.Running ? 'cantSet' : ''}>
            {timer.status === TimerStatus.Running ? (
              <>
                <ReactTooltip id="cantSet" place="top" variant="warning" float={false}>
                  {'You can\'t setting times while the timer is working!'}
                </ReactTooltip>
              </>
            ) : null}
            <NumberInput value={minute} setValue={(v) => setMinute(v > 1440 ? 1440 : v)} disabled={timer.status === TimerStatus.Running} />
            <span style={{ fontSize: '30px', margin: '0 10px' }}>:</span>
            <NumberInput value={second} setValue={(v) => setSecond(v > 60 ? 60 : v)} disabled={timer.status === TimerStatus.Running} />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="defaultView">
        <div className={classes.title} ref={titleRef}>
          {title}
        </div>
        <Space h gap={10} />
        <div className={shaking ? 'shake' : ''}>
          <CircularProcessing
            width={realDim.w * 0.7}
            thicken={realDim.w * 0.05}
            color={timer.status === TimerStatus.Running ? "var(--color-primary)" : "var(--color-secondary)"}
            max={timer.totalTime === 0 ? 1 : timer.totalTime}
            value={timer.remainTime}
          >
            <div className={classes.center}>
              <Icon
                icon={timer.status === TimerStatus.Running ? 'play' : 'pause'}
                size={realDim.w * 0.2}
                color={timer.status === TimerStatus.Running ? "var(--color-primary)" : "var(--color-secondary)"}
              />
              <div className={classes.timeLabel}>
                {fmtTimeMS(timer.remainTime)}
              </div>
            </div>
          </CircularProcessing>
        </div>

      </div>
    </>
  );
}
