import { InMemoryCache } from '@apollo/client';

export default new InMemoryCache({
  typePolicies: {
    Display: {
      fields: {
        modules: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Room: {
      fields: {
        individuals: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Subscription: {
      fields: {
        batchIndividualCreated: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        batchIndividualUpdated: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        batchIndividualDeleted: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
  possibleTypes: {
    Module: [
      'AmendmentModule',
      'LinkModule',
      'MotionModule',
      'RollCallModule',
      'SpeakersListModule',
      'TextModule',
      'TimerModule',
      'VoteModule',
    ],
  },
});
