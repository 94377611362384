import { useState, useEffect } from 'react';

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   const id = setInterval(() => {
  //     if (windowSize.width !== innerWidth || windowSize.height !== innerHeight)
  //       setWindowSize({
  //         width: window.innerWidth,
  //         height: window.innerHeight,
  //       })
  //   }, 1000)
  //   return () => clearInterval(id)
  // }, [])

  return windowSize;
}
