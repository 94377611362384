import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useParams } from 'react-router-dom';
import HeaderBar from 'components/section/HeaderBar/index';
import {
  useAudienceQuery,
  ModuleCreatedSubscription,
  ModuleCreatedSubscriptionVariables,
  ModuleUpdatedSubscription,
  ModuleUpdatedSubscriptionVariables,
  RoomUpdatedSubscription,
  RoomUpdatedSubscriptionVariables,
  DisplayUpdatedSubscriptionVariables,
  DisplayUpdatedSubscription,
  RollCallUpdatedSubscriptionVariables,
  RollCallUpdatedSubscription,
} from 'graphql/generated/graphql';
import { RoomContext, DragAndDropContext } from 'contexts';
import {
  MODULE_UPDATED_SUBSCRIPTION,
  MODULE_CREATED_SUBSCRIPTION,
  ROOM_UPDATED_SUBSCRIPTION,
  DISPLAY_UPDATED_SUBSCRIPTION,
  ROLL_CALL_UPDATED_SUBSCRIPTION,
} from 'graphql/subscriptions';
import AudienceModulePlace from './AudienceModulePlace';
import classes from './style.module.css';

export default function Audience() {
  const [placeDim, setPlaceDim] = useState({ w: 0, h: 0 });

  const { roomId } = useParams<{ roomId: string, tab: string }>();

  const { data, loading, subscribeToMore } = useAudienceQuery({ variables: { RID: roomId } });

  // module created and updated
  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubsModuleCreated = subscribeToMore<ModuleCreatedSubscription, ModuleCreatedSubscriptionVariables>({
      document: MODULE_CREATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const createdModule = subscriptionData.data.moduleCreated;
        if (createdModule && createdModule.display?._id === prev.room?.currentDisplay?._id) {
          return {
            ...prev,
            room: prev.room ? {
              ...prev.room,
              currentDisplay: prev.room.currentDisplay ? {
                ...prev.room.currentDisplay,
                modules: R.append(createdModule, prev.room.currentDisplay.modules),
              } : prev.room.currentDisplay,
            } : prev.room,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsModuleCreated);

    const unSubsModuleUpdated = subscribeToMore<ModuleUpdatedSubscription, ModuleUpdatedSubscriptionVariables>({
      document: MODULE_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedModule = subscriptionData.data.moduleUpdated;
        if (updatedModule) {
          const existingIndex = R.findIndex(R.propEq('_id', updatedModule._id))(prev.room?.currentDisplay?.modules || []);
  

          if (updatedModule.display?._id && updatedModule.display._id === prev.room?.currentDisplay?._id) {
            if (existingIndex === -1) {
              return {
                ...prev,
                room: {
                  ...prev.room,
                  currentDisplay: {
                    ...prev.room.currentDisplay,
                    modules: [...prev.room.currentDisplay.modules, updatedModule]
                  },
                },
              };
            } else {
              return {
                ...prev,
                room: {
                  ...prev.room,
                  currentDisplay: {
                    ...prev.room.currentDisplay,
                    modules: R.update(existingIndex, updatedModule, prev.room.currentDisplay.modules),
                  },
                },
              };
            }
          }

          return {
            ...prev,
            room: prev.room ? {
              ...prev.room,
              currentDisplay: prev.room.currentDisplay ? {
                ...prev.room.currentDisplay,
                modules: R.remove(existingIndex, 1, prev.room.currentDisplay.modules),
              } : prev.room.currentDisplay,
            } : prev.room,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsModuleUpdated);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  // display updated
  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubsDisplayUpdated = subscribeToMore<DisplayUpdatedSubscription, DisplayUpdatedSubscriptionVariables>({
      document: DISPLAY_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedDisplay = subscriptionData.data.displayUpdated;
        if (updatedDisplay) {
          if (updatedDisplay._id === prev.room?.currentDisplay?._id) {
            return {
              ...prev,
              room: {
                ...prev.room,
                currentDisplay: updatedDisplay,
              },
            };
          }
        }
        return prev;
      },
    });

    unSubs.push(unSubsDisplayUpdated);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  // rollcall updated
  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubsRollCallUpdated = subscribeToMore<RollCallUpdatedSubscription, RollCallUpdatedSubscriptionVariables>({
      document: ROLL_CALL_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      // only update rollcall cache, don't need to update query
    });

    unSubs.push(unSubsRollCallUpdated);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  // room updated
  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubsRoomUpdated = subscribeToMore<RoomUpdatedSubscription, RoomUpdatedSubscriptionVariables>({
      document: ROOM_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedRoom = subscriptionData.data.roomUpdated;
        if (updatedRoom) {
          return {
            ...prev,
            room: updatedRoom,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsRoomUpdated);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  if (loading) return <>Loading</>;
  if (!data?.room) return <>Not Found</>;
  return (
    <DragAndDropContext.Provider
      value={{
        dragStatus: { action: 'null' },
        setDragStatus: () => { },
        placeDim,
        setPlaceDim,
        setReady: () => { },
        ready: true,
      }}
    >

      <RoomContext.Provider value={{
        _id: data.room._id,
        name: data.room.name,
        joinCode: data.room.joinCode,
        expireTime: new Date(0),
        timeExpired: false,
        selectedId: '',
        setSelectedId: () => { },
      }}
      >
        <div className={classes.root}>
          <HeaderBar mode="audience" roomName={data.room.name} />
          <div className={classes.displayArea}>
            {data.room.currentDisplay
              && (
                <AudienceModulePlace
                  modules={data.room.currentDisplay.modules}
                  highlight={data.room.currentDisplay.highlightModule?._id}
                />
              )}
          </div>
        </div>
      </RoomContext.Provider>
    </DragAndDropContext.Provider>
  );
}
