import React, {
  useRef, useEffect, useContext,
} from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'theme';
import useWindowSize from 'hooks/useWindowSize';
import { DragAndDropContext } from 'contexts';
import AudienceBaseFrame from 'pages/Audience/AudienceBaseFrame';

export default function AudienceModulePlace({ modules, highlight }: Props) {
  const theme = useTheme<Theme>();
  const placeRef = useRef<HTMLInputElement>(null);

  const {
    setPlaceDim,
  } = useContext(DragAndDropContext);

  const { width: winW, height: winH } = useWindowSize();

  useEffect(() => {
    if (placeRef.current) {
      setPlaceDim({ w: placeRef.current.offsetWidth, h: placeRef.current.offsetHeight });
      // setOffset({ x: placeRef.current.getBoundingClientRect().left, y: placeRef.current.getBoundingClientRect().top });
    }
  }, [placeRef, winW, winH, setPlaceDim]);

  return (
    <>
      <div 
        style={{
          position: 'relative',
          flex: 1,
          flexShrink: 0,
          maxWidth: '129.6vh',
          height: '84.24vh',
          boxShadow: "0 0 4px 0 gray",
          backgroundColor: 'white',
        }} 
        ref={placeRef}
      >
        {modules.map((m) => <AudienceBaseFrame key={m._id} module={m} highlight={highlight} />)}
        {/* result:{640 < winH && winH * 0.9 - placeDim.w < 1 ? "ok" : "under-size"} */}
      </div>
      {/* <div className={classes.debugInfo}>
        [Debug Info] <br />
        Window Size:{winW},{winH} <br />
        Place Size: {placeDim.w},{placeDim.h}
        <br />
        Per-Module Size: {placeDim.w / 3},{placeDim.h / 2}
        <br />
        Place offset: {offset.x},{offset.y}
        <br />
        [Size Check]
        <br />
        Width Error:{winH * 0.9 - placeDim.w}
        <br />
        w-result:{winH * 0.9 - placeDim.w < 1 ? "ok" : "under-size"}
        <br />
        h-result:{640 < winH ? "ok" : "under-size"}
        <br />
      </div> */}
    </>
  );
}

type Props = {
  modules: any[]
  highlight: string | undefined

};
