import React, { useEffect, useState } from 'react';
import { ICoreProps } from 'components/module/moduleDefine';
import Input from 'components/basic/Input';
import Space from 'components/basic/Space';
import Icon from 'components/basic/Icon';
import { AmendmentType, Individual, AmendmentModuleData } from 'graphql/generated/graphql';
import { getIdvIcon } from 'utils/individual';
import { notEmpty } from 'utils/type';
import Dropdown from 'components/basic/Dropdown';
import IndividualSelectModal from 'components/composed/IndividualSelectModal';
import classes from './style.module.css';

export default function MunativeAmendment({
  data,
  setEditData: editDispatcher,
  isEdit,
}: ICoreProps<AmendmentModuleData>) {
  // individual data area
  const [type, setType] = useState(AmendmentType.Add);
  const [content, setContent] = useState('');
  const [submitters, setSubmitters] = useState<Individual[]>([]);
  const [clauses, setClaus] = useState('');

  // modal controlling area
  const [modalShow, setModalShow] = useState(false);

  // Initialize/Update the module
  useEffect(() => {
    setContent(data.content);
    setClaus(data.clauses);

    // Inconsistency between codegen field and real type
    setType((data as any).amendmentType);
    setSubmitters((data.submitters).filter(notEmpty));
  }, [data]);

  // When the editing done
  useEffect(() => {
    if (isEdit) {
      editDispatcher({
        amendment: {
          content,
          clauses,
          type,
          submitters: submitters.map((s) => s._id),
        },
      });
    }
  }, [isEdit, content, clauses, type, submitters, editDispatcher]);

  return (
    <>
      {/* Default page */}
      <div
        className="defaultView"
        style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
      >
        <div className={classes.title}>
          <span className={classes.type}>{{ modify: 'Modify', add: 'Add', strike: 'Strike' }[type]}</span>
          {' Clause '}
          {clauses}
        </div>
        <Space v gap={10} />
        <div className={classes.submitter}>
          <span>Submitted By</span>
          <Space h gap={10} />
          {
            submitters.slice(0, 3).map((i) => (
              <span className={classes.individualIcon}>
                <img alt="flag" src={getIdvIcon(i)} width="30" />
              </span>
            ))
          }
          <Space h gap={20} />
          {submitters.length > 4 && `and ${submitters.length - 4}+`}
        </div>
        <Space v gap={20} />
        <textarea className={classes.input} readOnly value={content} style={{ boxShadow: 'none' }} />
      </div>

      {/* Edit page */}
      <div className={['editView', isEdit ? 'active' : ''].join(' ')}>
        <div className={classes.editTitle}>
          <Dropdown
            items={[
              { label: 'Add', value: AmendmentType.Add },
              { label: 'Modify', value: AmendmentType.Modify },
              { label: 'Strike', value: AmendmentType.Strike }]}
            width={100}
            value={type}
            setValue={setType}
          />
          <Space h gap={10} />
          Clause
          <Space h gap={10} />
          <Input className={classes.titleInput} value={clauses} onChange={(e) => setClaus(e.target.value)} />
        </div>

        <Space v gap={10} />
        <div className={classes.submitter}>
          <span>Submitted By</span>
          <Space h gap={10} />
          <span className={classes.touchable} onClick={() => setModalShow(true)}>
            {
              submitters.slice(0, 3).map((i) => (
                <span className={classes.individualIcon}>
                  <img alt="flag" src={getIdvIcon(i)} width="30" />
                </span>
              ))
            }
            <Space h gap={20} />
            {submitters.length > 4 && `and ${submitters.length - 4}+`}
            <Space h gap={10} />
            <Icon icon="drop_down" size={30} color={"var(--color-primary)"} />
          </span>
        </div>
        <Space v gap={10} />

        <textarea
          className={classes.input}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />

      </div>

      <IndividualSelectModal
        isShow={modalShow}
        setShow={setModalShow}
        value={submitters}
        onFinish={(selected: Individual[]) => { setSubmitters(selected); }}
        multiple
      />
    </>
  );
}
