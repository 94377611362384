/* eslint-disable no-param-reassign */
export const Formate = (ms: Date, fmt: string) => {
  const o: { [index: string]: number } = {
    'M+': ms.getMonth() + 1, // 月份
    'd+': ms.getDate(), // 日
    'h+': ms.getHours(), // 小時
    'm+': ms.getMinutes(), // 分
    's+': ms.getSeconds(), // 秒
    'q+': Math.floor((ms.getMonth() + 3) / 3), // 季度
    S: ms.getMilliseconds(), // 毫秒
  };

  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (`${ms.getFullYear()}`).substr(4 - RegExp.$1.length));
  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? o[k].toString()
          : (`00${o[k].toString()}`).substr((`${o[k].toString()}`).length),
      );
    }
  });
  return fmt;
};

export const fmtTime = (date: Date) => Formate(date, 'mm:ss');

export const fmtTimeHM = (date: Date) => Formate(date, 'hh:mm');

const decorate = (num: number) => (num >= 10 ? num.toString() : `0${num.toString()}`);
export const fmtTimeMS = (sec: number) => `${decorate(Math.floor(sec / 60))}:${decorate(Math.floor(sec % 60))}`;
