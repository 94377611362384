import React, {
  useState, memo, useRef, useContext,
} from 'react';
import Card from 'components/basic/Card/index';
import { createUseStyles } from 'react-jss';
import { DragAndDropContext } from 'contexts';
import { Theme } from 'theme';
import { ModuleInfo } from 'components/module/moduleInfo';
import Icon from 'components/basic/Icon';
import { IPosition, IDimension } from 'declarations';
import { ModuleStatus } from 'graphql/generated/graphql';

type ClassNames = 'root' | 'card' | 'dragHandler' | 'mainView';

type StyleProps = {
  pos: IPosition
  dim: IDimension
  inLegalArea: boolean
  isRemoveArea: boolean
  isArchiveArea: boolean
  placeDim: { w: number; h: number }
};

const useStyles = createUseStyles<ClassNames, StyleProps, Theme>({
  root: ({
    dim, pos, placeDim, inLegalArea, isRemoveArea,
  }) => ({
    position: 'absolute',
    zIndex: 5,
    textAlign: 'center',
    width: (dim.w * placeDim.w) / 4,
    height: (dim.h * placeDim.h) / 2,
    left: pos.x,
    top: pos.y,
    padding: 5,
    opacity: inLegalArea ? 1 : 0.8,
    filter: isRemoveArea ? null : `brightness(${inLegalArea ? 1 : 0.7})`,
    transition: 'opacity 0.3s, filter 0.3s',
  }),
  card: ({ isRemoveArea, isArchiveArea }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: isRemoveArea ? '#FFA7A7' : isArchiveArea ? '#A7CAFF' : 'white',
  }),
  dragHandler: ({ inLegalArea }) => ({
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%)',
    cursor: inLegalArea ? 'grabbing' : 'not-allowed',
  }),
  mainView: { marginTop: 10, width: '100%' },
});

export default memo(({
  isRemoveArea, isArchiveArea, inLegalArea, pos, module: origModule,
}: Props) => {
  const { dragStatus, placeDim } = useContext(DragAndDropContext);
  const { Target } = ModuleInfo[dragStatus.type!];

  const [isEdit, setEdit] = useState(false);

  const ref = useRef(null);

  let module = origModule;

  let dim: IDimension = { w: 0, h: 0 };
  if (dragStatus.action === 'create') {
    module = {
      _id: 'fake',
      status: ModuleStatus.InUse,
      ...ModuleInfo[dragStatus.type!].default,
      type: dragStatus.type!,
      pos,
    };
    dim.w = ModuleInfo[dragStatus.type!].default.dim.w;
    dim.h = ModuleInfo[dragStatus.type!].default.dim.h;
  } else if (dragStatus.action === 'unshelf') {
    module = { ...dragStatus.module!, pos };
    dim = dragStatus.dim!;
  } else if (module && module.dim) {
    dim.w = module.dim.w;
    dim.h = module.dim.h;
  }

  const classes = useStyles({
    pos,
    dim,
    inLegalArea,
    isRemoveArea,
    placeDim,
    isArchiveArea,
  });

  const editDispatcher = () => {
  };

  return (
    <div className={classes.root} ref={ref}>
      <Card className={classes.card}>
        <Icon className={classes.dragHandler} icon="handle" size={28} color="gray" />
        <div className={classes.mainView}>
          {dragStatus.action === 'move' || dragStatus.action === 'unshelf' ? (
            module
            && module.data && (
              <Target
                realDim={{ w: placeDim.w / 4, h: placeDim.w / 2 }}
                isEdit={isEdit}
                setEdit={setEdit}
                data={module.data}
                setEditData={editDispatcher}
                dispatchUpdateModule={() => { }}
                id={dragStatus.id!}
              />
            )
          ) : (
            <Target
              realDim={{ w: placeDim.w / 4, h: placeDim.w / 2 }}
              isEdit={isEdit}
              setEdit={setEdit}
              data={ModuleInfo[dragStatus.type!].default.data}
              setEditData={editDispatcher}
              dispatchUpdateModule={() => { }}
              id="none"
            />
          )}
        </div>
      </Card>
    </div>
  );
});

type Props = {
  pos: IPosition
  isRemoveArea: boolean
  inLegalArea: boolean
  isArchiveArea: boolean
  module: any
};
