import React, { useContext, useEffect, useRef } from 'react';
import Button from 'components/basic/Button/index';
import Space from 'components/basic/Space/index';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { RoomContext } from 'contexts';
import classes from './style.module.css';
import { Tooltip } from 'react-tooltip';
import { set } from 'lodash';
import Icon from 'components/basic/Icon';


(window as any).Clipboard = (function(window, document, navigator) {
  var textArea: HTMLInputElement,
      copy;

  function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text:string) {
      textArea = document.createElement('input');
      textArea.value = text;
      document.body.appendChild(textArea);
  }

  function selectText() {
      var range,
          selection;

      if (isOS()) {
          range = document.createRange();
          range.selectNodeContents(textArea);
          selection = window.getSelection();
          if(selection){  
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        }
      } else {
          textArea.select();
      }
  }

  function copyToClipboard() {        
      document.execCommand('copy');
      document.body.removeChild(textArea);
  }

  copy = function(text:string) {
      createTextArea(text);
      selectText();
      copyToClipboard();
  };

  return {
      copy: copy
  };
})(window, document, navigator);

export default function HeaderBar({ mode, roomName = '' }: Prop) {
  const { joinCode, _id } = useContext(RoomContext);
  const [isCopyNotifyOpen, setCopyNotifyOpen] = React.useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopyNotifyOpen(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isCopyNotifyOpen]);

  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
      <div className={classes.roomName}>
        {roomName}
      </div>
      <div className={classes.sharingDisplay}>
        Join at lite.munative.com with
        <span 
          className={classes.roomCode}
          onClick={() => {
            (window as any).Clipboard.copy(joinCode);
            setCopyNotifyOpen(true);
          }}
        >
          <span id="join-code" data-tooltip-id="copied-tooltip">{joinCode}</span>
          <Icon icon='copy' size={24} color={"var(--color-primary)"}/>
          <Tooltip
            id="copied-tooltip"
            content="Copied!"
            isOpen={isCopyNotifyOpen}
            variant="light" 
            float={false} 
            className={"shadow"}
            style={{zIndex: 20}}
          />
        </span>
        {mode === 'host' && (
          <>
            <Space gap={10} h />
            <a className={classes.feedbackLink} href="https://forms.gle/5exqkCExor3wAZKs8">Feedback</a>
            <Space gap={10} h />
            <a
              href={`/audience/${_id}`}
              target="_blank"
              rel="noreferrer"
              onClick={
                () => {
                  // ReactGA.event({
                  //   category: GACategory.DASHBOARD,
                  //   action: GAAction.CLICK,
                  //   label: 'Open Audience Display',
                  // });
                }
              }
            >
              <Button>Audience</Button>
            </a>
          </>
        )}
      </div>
    </div>
  );
}
export type Prop = {
  mode: 'host' | 'audience'
  roomName?: string
};
