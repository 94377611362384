import React from 'react';
import Space from 'components/basic/Space';
import classes from './style.module.css';



export default function Check(
  {
    color = "var(--color-primary)",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disabled = false,
    size = 25,
    checked,
    setCheck,
    label,
  }: Props,
) {
  return (
    <label className={classes.root} htmlFor="checkRoot" onClick={() => setCheck(!checked)}>
      <div 
        className={classes.checkRoot} 
        id="checkRoot"
        style={{
          width: size,
          height: size,
          border: `${color} 4px solid`,
        }}
      >
        {checked 
        && 
        <div 
          className={classes.check} 
          style={{
            width: size - 15,
            height: size - 15,
            backgroundColor: color,
          }}
        />}
      </div>
      <Space h gap={10} />
      {label}
    </label>
  );
}

type Props = {
  color?: string
  disabled?: boolean
  size?: number
  checked: boolean
  setCheck: Function
  label: string
};
