import gql from 'graphql-tag';
import {
  DISPLAY_FRAGMENT,
  HOST_FRAGMENT,
  INDIVIDUAL_FRAGMENT,
  ROLL_CALL_FRAGMENT,
  ROOM_FRAGMENT,
  TALLY_SHEET_ITEM_FRAGMENT,
  MODULE_FRAGMENT,
} from './fragments';

export const DISPLAY_CREATED_SUBSCRIPTION = gql`
  subscription displayCreated($RID: ID!) {
    displayCreated(RID: $RID){
      _id
      ...Display
    }
  }
  ${DISPLAY_FRAGMENT}
`;

export const DISPLAY_UPDATED_SUBSCRIPTION = gql`
  subscription displayUpdated($RID: ID!) {
    displayUpdated(RID: $RID){
      _id
      ...Display
    }
  }
  ${DISPLAY_FRAGMENT}
`;

export const DISPLAY_DELETED_SUBSCRIPTION = gql`
  subscription displayDeleted($RID: ID!) {
    displayDeleted(RID: $RID){
      _id
      ...Display
    }
  }
  ${DISPLAY_FRAGMENT}
`;

export const HOST_UPDATED_SUBSCRIPTION = gql`
  subscription HostUpdated($HID: ID!) {
    hostUpdated(HID: $HID){
      _id
      ...Host
    }
  }
  ${HOST_FRAGMENT}
`;

export const BATCH_INDIVIDUAL_CREATED_SUBSCRIPTION = gql`
  subscription BatchIndividualCreated($RID: ID!) {
    batchIndividualCreated(RID: $RID){
      _id
      ...Individual
    }
  }
  ${INDIVIDUAL_FRAGMENT}
`;

export const BATCH_INDIVIDUAL_UPDATED_SUBSCRIPTION = gql`
  subscription BatchIndividualUpdated($RID: ID!) {
    batchIndividualUpdated(RID: $RID){
      _id
      ...Individual
    }
  }
  ${INDIVIDUAL_FRAGMENT}
`;

export const BATCH_INDIVIDUAL_DELETED_SUBSCRIPTION = gql`
  subscription BatchIndividualDeleted($RID: ID!) {
    batchIndividualDeleted(RID: $RID){
      _id
      ...Individual
    }
  }
  ${INDIVIDUAL_FRAGMENT}
`;

export const MODULE_CREATED_SUBSCRIPTION = gql`
  subscription ModuleCreated($RID: ID!) {
    moduleCreated(RID: $RID){
      _id
      ...Module
    }
  }
  ${MODULE_FRAGMENT}
`;

export const MODULE_UPDATED_SUBSCRIPTION = gql`
  subscription ModuleUpdated($RID: ID!) {
    moduleUpdated(RID: $RID){
      _id
      ...Module
    }
  }
  ${MODULE_FRAGMENT}
`;

export const ROLL_CALL_CREATED_SUBSCRIPTION = gql`
  subscription RollCallCreated($RID: ID, $RCID: ID) {
    rollCallCreated(RID: $RID, RCID: $RCID){
      _id
      ...RollCall
    }
  }
  ${ROLL_CALL_FRAGMENT}
`;

export const ROLL_CALL_DELETED_SUBSCRIPTION = gql`
  subscription RollCallDeleted($RID: ID, $RCID: ID) {
    rollCallDeleted(RID: $RID, RCID: $RCID){
      _id
      ...RollCall
    }
  }
  ${ROLL_CALL_FRAGMENT}
`;

export const ROLL_CALL_UPDATED_SUBSCRIPTION = gql`
  subscription RollCallUpdated($RID: ID, $RCID: ID) {
    rollCallUpdated(RID: $RID, RCID: $RCID){
      _id
      ...RollCall
    }
  }
  ${ROLL_CALL_FRAGMENT}
`;

export const ROOM_UPDATED_SUBSCRIPTION = gql`
  subscription RoomUpdated($RID: ID!) {
    roomUpdated(RID: $RID){
      _id
      ...Room
    }
  }
  ${ROOM_FRAGMENT}
`;

export const TALLY_SHEET_ITEM_CREATED_SUBSCRIPTION = gql`
  subscription TallySheetItemCreated($RID: ID!) {
    tallySheetItemCreated(RID: $RID){
      _id
      ...TallySheetItem
    }
  }
  ${TALLY_SHEET_ITEM_FRAGMENT}
`;

export const TALLY_SHEET_ITEM_UPDATED_SUBSCRIPTION = gql`
  subscription TallySheetItemUpdated($RID: ID!) {
    tallySheetItemUpdated(RID: $RID){
      _id
      ...TallySheetItem
    }
  }
  ${TALLY_SHEET_ITEM_FRAGMENT}
`;

export const TALLY_SHEET_ITEM_DELETED_SUBSCRIPTION = gql`
  subscription TallySheetItemDeleted($RID: ID!) {
    tallySheetItemDeleted(RID: $RID){
      _id
      ...TallySheetItem
    }
  }
  ${TALLY_SHEET_ITEM_FRAGMENT}
`;
