import React, { useState } from 'react';
import Icon from 'components/basic/Icon';
import { useDetectClickOutside } from 'react-detect-click-outside';
import classes from './style.module.css';

/**
 * Basic Component － A normal texted button
 * @example <Button className={extendClass} onClick={()=>{...}}>Button</Button>
 */
export default function Dropdown({
  width, items, value, setValue,
}: Props) {
  const [isOpen, setOpen] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });

  return (
    <span className={classes.root} onClick={() => setOpen(!isOpen)} ref={ref} style={{width}}>
      {items.find((item) => item.value === value)?.label}
      <Icon icon="drop_down" size={30} color={"var(--color-primary)"} />
      <div 
        className={classes.list}
        style={{
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? 'auto' : 'none',
        }}
      >
        {
          items.map((item) => <div key={item.value} className={classes.item} onClick={() => setValue(item.value)}>{item.label}</div>)
        }
      </div>
    </span>
  );
}

type Props = {
  width: number
  items: { label: string, value: any }[]
  value: any
  setValue: Function
};
