import * as React from 'react';
import { createUseStyles } from 'react-jss';
import theme, { Theme } from 'theme';
import Icon from 'components/basic/Icon';

type ClassNames = 'root';

type StyleProps = { color: string, disabled?: boolean };

const useStyles = createUseStyles<ClassNames, StyleProps, Theme>({
  root: ({ color, disabled }) => ({
    flexShrink: 0,
    flexGrow: 0,
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 100,
    transition: '0.3s',
    pointerEvents: disabled ? 'none' : '',
    '& svg': {
      transition: '0.3s',
    },
    '&:hover': {
      background: color,
      '& svg': {
        fill: 'white',
      },
    },
  }),
});

/**
 * Basic Component － A button with icon in it
 * @example <IconButton className={extendClass} icon="add" color={theme.color.primary} onClick={()=>{...}}/>
 */
export default function IconButton({
  className, icon, color = theme.color.primary, onClick, disabled,
}: Props) {
  const classes = useStyles({ color, disabled });

  return (
    <span className={[classes.root, className].join(' ')} onClick={onClick}>
      <Icon icon={icon} size={24} color={disabled ? 'gray' : color} />
    </span>
  );
}

type Props = {
  className?: string
  icon: string
  color?: string
  onClick?: React.MouseEventHandler
  disabled?: boolean
};
