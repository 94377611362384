import { cast2, cast3, countries } from 'utils/countries/country-data';

export const getAllCountries = () => countries;

export const getCountryIndex = (iso: string): number | undefined => {
  if (iso.length === 2) return cast2[iso.toUpperCase()];
  return cast3[iso.toUpperCase()];
};

export const getCountry = (iso: string) => {
  const index = getCountryIndex(iso);

  return index !== undefined ? getAllCountries()[index] : undefined;
};
