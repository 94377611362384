import * as R from 'ramda';
import { createUseStyles, useTheme } from 'react-jss';
import React, { useContext, useEffect, useState } from 'react';
import { DragAndDropContext, IDragAndDropContext, RoomContext } from 'contexts';
import { Theme } from 'theme';
import { ModuleInfo } from 'components/module/moduleInfo';
import Icon from 'components/basic/Icon';
import { IPosition } from 'declarations';
import DropIndicator from 'components/section/DragLayer/DropIndicator';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  MODULE_CREATED_SUBSCRIPTION,
  MODULE_UPDATED_SUBSCRIPTION,
} from 'graphql/subscriptions';
import {
  useCreateModuleMutation,
  useModuleQuery,
  useMoveModuleMutation,
  useDisplayQuery,
  useUpdateModuleStatusMutation,
  ModuleStatus,
  ModuleCreatedSubscription,
  ModuleCreatedSubscriptionVariables,
  ModuleUpdatedSubscription,
  ModuleUpdatedSubscriptionVariables,
} from 'graphql/generated/graphql';
import DragDisplay from './DragDisplay';
import { IDragStatus } from './IDragStatus';
import { DIMENSION } from 'constant';

type ClassNames = 'root' | 'active' | 'archiveArea' | 'shelfArea';

type StyleProps = {
  dragStatus: IDragStatus;
  archiving: boolean;
  shelfing: boolean;
};

const useStyles = createUseStyles<ClassNames, StyleProps, Theme>({
  root: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: 1,
    pointerEvents: 'none',
    transition: '0.3s',
    overflow: 'hidden',
    zIndex: 50,
  },
  active: {
    opacity: 1,
    pointerEvents: 'auto',
  },
  archiveArea: ({ dragStatus, archiving }) => ({
    pointerEvents: 'auto',
    position: 'absolute',
    width: dragStatus.action === 'move' || dragStatus.action === 'unshelf' ? DIMENSION.ARCHIVE_RADIUS : 0,
    height: dragStatus.action === 'move' || dragStatus.action === 'unshelf' ? DIMENSION.ARCHIVE_RADIUS : 0,
    transform: archiving ? 'scale(130%)' : '',
    opacity: archiving ? 0.5 : 0.3,
    borderRadius: 1000,
    transition: '0.3s',
    zIndex: 20,
    '& .hint ': {
      color: 'white',
      position: 'absolute',
      top: '25%',
      left: '25%',
      textAlign: 'center',
      fontSize: 20,
      transition: '0.3s ease-out',
      zIndex: 8,
    },
    '&:hover': {
    },
    extend: 'cornerArea',
    bottom: dragStatus.action === 'move' || dragStatus.action === 'unshelf' ? DIMENSION.ARCHIVE_OFFSET : 0,
    right: dragStatus.action === 'move' || dragStatus.action === 'unshelf' ? DIMENSION.ARCHIVE_OFFSET : 0,
    backgroundColor: 'red',
  }),
  shelfArea: ({ dragStatus, theme, shelfing }) => ({
    pointerEvents: 'auto',
    position: 'absolute',
    width: dragStatus.action === 'move' ? DIMENSION.ARCHIVE_RADIUS : 0,
    height: dragStatus.action === 'move' ? DIMENSION.ARCHIVE_RADIUS : 0,
    transform: shelfing ? 'scale(130%)' : '',
    opacity: shelfing ? 0.5 : 0.3,
    borderRadius: 1000,
    transition: '0.3s',
    zIndex: 20,
    '& .hint ': {
      color: 'white',
      position: 'absolute',
      top: '25%',
      right: '25%',
      textAlign: 'center',
      fontSize: 20,
      transition: '0.3s ease-out',
      zIndex: 8,
    },
    extend: 'cornerArea',
    bottom: dragStatus.action === 'move' ? DIMENSION.SHELF_OFFSET : 0,
    left: dragStatus.action === 'move' ? DIMENSION.SHELF_OFFSET : 0,
    backgroundColor: theme.color.primary,
  }),
});

export default function DragLayer() {
  const {
    dragStatus, setDragStatus, placeDim, setReady,
  } = useContext<IDragAndDropContext>(DragAndDropContext);
  const theme = useTheme<Theme>();

  const [pos, setPos] = useState<IPosition>({ x: 0, y: 0 });
  const [gridPos, setGridPos] = useState<IPosition>({ x: 0, y: 0 });
  const [isArchiveArea, setArchiveArea] = useState(false);
  const [isShelfArea, setShelfArea] = useState(false);
  const [inLegalArea, setInLegalArea] = useState(true);

  const classes = useStyles({
    dragStatus, theme, shelfing: isShelfArea, archiving: isArchiveArea,
  });

  const slideClosingTimeout = React.useRef<any>(undefined);

  const detach = () => {
    setDragStatus({ action: 'null' });
  };

  const [moveModule] = useMoveModuleMutation();
  const [createModule] = useCreateModuleMutation();
  const [updateModuleStatus] = useUpdateModuleStatusMutation();
  const { selectedId, _id } = useContext(RoomContext);

  const { data, loading } = useModuleQuery({
    variables: {
      MID: dragStatus.id || '',
    },
    skip: dragStatus.action !== 'move' || !dragStatus.id || dragStatus.id.length === 0,
  });
  const {
    data: displayData,
    loading: displayLoading,
    subscribeToMore,
  } = useDisplayQuery({ variables: { DID: selectedId } });
  const { roomId } = useParams<{ roomId: string }>();

  useEffect(() => {
    if (displayLoading) setReady(false);
    else if (displayData) setReady(true);
  }, [displayData, displayLoading, setReady]);

  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubsModuleCreated = subscribeToMore<ModuleCreatedSubscription, ModuleCreatedSubscriptionVariables>({
      document: MODULE_CREATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const createdModule = subscriptionData.data.moduleCreated;
        if (createdModule) {
          return {
            ...prev,
            display: prev.display ? {
              ...prev.display,
              modules: R.append(createdModule, prev.display.modules),
            } : prev.display,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsModuleCreated);

    const unSubsModuleUpdated = subscribeToMore<ModuleUpdatedSubscription, ModuleUpdatedSubscriptionVariables>({
      document: MODULE_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedModule = subscriptionData.data.moduleUpdated;
        if (updatedModule && prev.display) {
          const existingIndex = R.findIndex(R.propEq('_id', updatedModule._id))(prev.display.modules);

          if (updatedModule.display && updatedModule.display._id === prev.display._id) {
            if (existingIndex !== -1) {
              // module in display
              return {
                ...prev,
                display: {
                  ...prev.display,
                  modules: R.update(existingIndex, updatedModule, prev.display.modules),
                },
              };
            }

            return {
              ...prev,
              display: {
                ...prev.display,
                modules: R.append(updatedModule, prev.display.modules),
              },
            };
          }

          if (existingIndex !== -1) {
            // not in display but previously in display
            return {
              ...prev,
              display: {
                ...prev.display,
                modules: R.remove(existingIndex, 1, prev.display.modules),
              },
            };
          }

          return prev;
        }
        return prev;
      },
    });

    unSubs.push(unSubsModuleUpdated);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  const dispatchArchive = () => {
    if (dragStatus.action === 'move') {
      // ReactGA.event({
      //   category: GACategory.DASHBOARD,
      //   action: GAAction.ARCHIVE,
      //   label: `${dragStatus.type} Module`,
      // });
      updateModuleStatus({ variables: { MID: data!.module!._id, status: ModuleStatus.Archive } });
      // .catch((e) => console.error(e));
    }
    // else {
    //   const nm: IModuleInstance = _.cloneDeep(dragStatus.module!)
    //   nm.pos = gridPos
    //   const tem = _.cloneDeep<{ [id: string]: IShelfItem }>(shelfModuleVar())
    //   delete tem[dragStatus.id!]
    //   shelfModuleVar(tem)
    //   localStorage.setItem("storgeShelf", JSON.stringify(tem))
    // }
    detach();
    setArchiveArea(false);
  };

  const dispatchShelf = () => {
    updateModuleStatus({ variables: { MID: data!.module!._id, status: ModuleStatus.Shelf } });
    // .catch((e) => console.error(e));

    detach();
  };

  const dispatchMoving = () => {
    
    
    if (dragStatus.action === 'move') {
      if (loading || (data!.module!.pos?.x === gridPos.x && data!.module!.pos?.y === gridPos.y) || isArchiveArea){ 
        detach();
        return;
      }
      // ReactGA.event({
      //   category: GACategory.DASHBOARD,
      //   action: GAAction.DRAG,
      //   label: `${dragStatus.type} Module`,
      // });
      moveModule({ variables: { MID: data!.module!._id, pos: gridPos } });
    } else if (dragStatus.action === 'unshelf' || isArchiveArea) {
      // ReactGA.event({
      //   category: GACategory.DASHBOARD,
      //   action: GAAction.UNSHELF,
      //   label: `${dragStatus.type} Module`,
      // });
      moveModule({
        variables: {
          MID: dragStatus!.module!._id, pos: gridPos, displayId: selectedId,
        },
      });
    } else {
      // ReactGA.event({
      //   category: GACategory.DASHBOARD,
      //   action: GAAction.CREATE,
      //   label: `${dragStatus.type} Module`,
      // });
      createModule({
        variables: {
          RID: _id,
          DID: selectedId,
          type: dragStatus.type!,
          data: ModuleInfo[dragStatus.type!].input,
          pos: gridPos,
        },
      });
    }
    detach();
  };

  function switchSlide(state:boolean){
    const slides = document.querySelectorAll('.module-root');
    slides.forEach((slide) => {
      if(state){
        slide.classList.add('slide');
      } else {
        slide.classList.remove('slide');
      }
    })
  }

  function onDrop(){
    switchSlide(true);
    if(isArchiveArea){
      dispatchArchive();
    } else if(isShelfArea){
      dispatchShelf();
    } else if(inLegalArea){
      dispatchMoving();
    } else {
      detach();
    }
    if(slideClosingTimeout.current)
      clearTimeout(slideClosingTimeout.current);
    slideClosingTimeout.current = setTimeout(()=>{
      switchSlide(false);
    }, 500)
  }

  function posExamine(x: number, y: number) {
    const dim = (
      dragStatus.action !== 'null'
        ? dragStatus.action === 'move'
          ? dragStatus.dim
          : ModuleInfo[dragStatus.type!].default.dim
        : { w: 0, h: 0 });
    const occupying = [
      [false, false, false, false],
      [false, false, false, false],
    ];

    displayData?.display?.modules.forEach((module) => {
      if (module._id === dragStatus.id) return;
      for (let i = 1; i <= (module.dim?.h || 0); i++) {
        for (let j = 1; j <= (module.dim?.w || 0); j++) {
          occupying[(module.pos?.y || 0) + i - 1][(module.pos?.x || 0) + j - 1] = true;
        }
      }
    });

    for (let i = 1; i <= dim!.h; i++) {
      for (let j = 1; j <= dim!.w; j++) {
        if (occupying[y + i - 1][x + j - 1]) {
          return false;
        }
      }
    }

    return true;
  }

  const getPos = (e: React.MouseEvent) => {
    const dim = (
      dragStatus.action !== 'null'
        ? dragStatus.action === 'move'
          ? dragStatus.dim
          : ModuleInfo[dragStatus.type!].default.dim
        : { w: 0, h: 0 });
    if(isMobile) return;
    const relX = e.clientX;
    const relY = e.clientY - 20;
    const off = document.getElementById('module-place')?.getBoundingClientRect() || { left: 0, top: 0 };
    const gridX = Math.round((relX - off.left)
      / (placeDim.w / 4) - 0.5 - (dim!.w - 1) * 0.5);
    const gridY = Math.round((relY - off.top + 20) / (placeDim.h / 2) - ((2 - dim!.h) * 0.5) - (dim!.h - 1) * 0.5);
    
    if (
      gridX >= 0
      && gridX + dim!.w <= 4
      && gridY >= 0
      && gridY + dim!.h <= 2
      && posExamine(gridX, gridY)
    ) setInLegalArea(true);
    else setInLegalArea(false);
    
    setPos({ x: relX - ((dim!.w / 2) * placeDim.w) / 4, y: relY });
    setGridPos({ x: gridX, y: gridY });
  };

  const getTouchPos = (e: TouchEvent) => {
    const dim = (
      dragStatus.action !== 'null'
        ? dragStatus.action === 'move'
          ? dragStatus.dim
          : ModuleInfo[dragStatus.type!].default.dim
        : (window as any).dim || { w: 0, h: 0 });
    const shelfLoc = document.getElementById('shelf-area')?.getBoundingClientRect();
    const archiveLoc = document.getElementById('archive-area')?.getBoundingClientRect();

    const relX = e.touches[0].clientX;
    const relY = e.touches[0].clientY - 20;
    const off = document.getElementById('module-place')?.getBoundingClientRect() || { left: 0, top: 0 };
    const gridX = Math.round((relX - off.left)
      / (placeDim.w / 4) - 0.5 - (dim!.w - 1) * 0.5);
    const gridY = Math.round((relY - off.top + 20) / (placeDim.h / 2) - ((2 - dim!.h) * 0.5) - (dim!.h - 1) * 0.5);
    if(gridX>3 || gridY>1) setInLegalArea(false);
    if (
      gridX >= 0
      && gridX + dim!.w <= 4
      && gridY >= 0
      && gridY + dim!.h <= 2
      && posExamine(gridX, gridY)
    ) setInLegalArea(true);
    else setInLegalArea(false);

    setPos({ x: relX - ((dim!.w / 2) * placeDim.w) / 4, y: relY });
    setGridPos({ x: gridX, y: gridY });

    // check in archive area
    if(archiveLoc){
      if(relX > archiveLoc.left && relX < archiveLoc.right && relY > archiveLoc.top && relY < archiveLoc.bottom){
        setArchiveArea(true);
      } else {
        setArchiveArea(false);
      }
    } 
    // check in shelf area
    if(shelfLoc){
      if(relX > shelfLoc.left && relX < shelfLoc.right && relY > shelfLoc.top && relY < shelfLoc.bottom){
        setShelfArea(true);
      } else {
        setShelfArea(false);
      }
    }
  };

  useEffect(() => {
    function onTouchEnd(e: TouchEvent) {
      if(dragStatus.action === 'null') return;
      onDrop()
    }
    document.addEventListener('touchmove', getTouchPos);
    document.addEventListener('touchstart', getTouchPos);
    document.addEventListener('touchend', onTouchEnd);
    return () => {
      document.removeEventListener('touchmove', getTouchPos);
      document.removeEventListener('touchstart', getTouchPos);
      document.removeEventListener('touchend', onTouchEnd);
    };

  }), [
    getTouchPos,
    dragStatus,
  ];

  const dim = (
    dragStatus.action !== 'null'
      ? dragStatus.action === 'move'
        ? dragStatus.dim
        : ModuleInfo[dragStatus.type!].default.dim
      : { w: 0, h: 0 });


  return (
    <div
      ref={null}
      className={["debug", classes.root, dragStatus.action !== 'null' ? classes.active : ''].join(' ')}
      onMouseMove={getPos}
      onMouseEnter={getPos}
      onMouseUp={isMobile ? undefined: onDrop}
      onMouseLeave={isMobile ? undefined: detach}
    >
      {dragStatus.action !== 'null' && !loading ? (
        <>
          <DropIndicator pos={gridPos} dim={dim!} enable={inLegalArea && !isArchiveArea && !isShelfArea} />
          <DragDisplay
            pos={pos}
            inLegalArea={inLegalArea}
            isRemoveArea={isArchiveArea}
            isArchiveArea={isShelfArea}
            module={data?.module}
          />
        </>
      ) : null}
      <div
        className={classes.archiveArea}
        id="archive-area"
        onMouseEnter={() => setArchiveArea(true)}
        onMouseLeave={() => setArchiveArea(false)}
      >
        <div className="hint">
          <Icon icon="archive" size={50} color="white" />
          <div>Archive</div>
        </div>
      </div>
      <div
        className={classes.shelfArea}
        id="shelf-area"
        onMouseEnter={() => setShelfArea(true)}
        onMouseLeave={() => setShelfArea(false)}
      >
        <div className="hint">
          <Icon icon="shelf" size={50} color="white" />
          <div>Shelf</div>
        </div>
      </div>
    </div>
  );
}
