import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import theme, { Theme } from 'theme';
import Input from 'components/basic/Input';
import IconButton from 'components/basic/IconButton/index';
import { TallySheetItem, useDeleteTallySheetItemMutation, useUpdateTallySheetItemMutation } from 'graphql/generated/graphql';
import { useDebouncedCallback } from 'use-debounce';
import classes from './style.module.css';


function HeaderItems({ column, isEdit }: ItemProps) {
  const [name, setName] = useState('');

  const [removeItem] = useDeleteTallySheetItemMutation({ variables: { TID: column._id } });
  const [updateName] = useUpdateTallySheetItemMutation();

  function onRemove() {
    removeItem();
  }

  useEffect(() => {
    setName(column.name);
  }, [column.name]);

  const updateItemName = useDebouncedCallback((newName: string) => {
    updateName({ variables: { TID: column._id, name: newName } });
  }, 300);

  return (
    <span className={classes.headerItem}>
      {isEdit ? (
        <div style={{ textAlign: 'center' }}>
          <IconButton icon="clear" color="red" onClick={() => onRemove()} />
          <br />
          <Input
            className={classes.input}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              updateItemName(e.target.value);
            }}
          />
        </div>
      )
        : column.name}
    </span>
  );
}

type ItemProps = {
  isEdit: boolean,
  column: TallySheetItem,
};

export default function TallySheetHeader({ isEdit, columns, onCreate }: Props) {
  return (
    <div className={classes.header}>
      {columns.map((c) => (
        <HeaderItems key={c._id} column={c} isEdit={isEdit} />
      ))}
      {isEdit
        && <IconButton icon="add" color={theme.color.primary} onClick={() => onCreate()} />}
    </div>
  );
}

type Props = {
  isEdit: boolean,
  columns: TallySheetItem[],
  onCreate: Function
};
