export const FEATURES = {
  SLIDING_MODULES: true,
};

export const DIMENSION = {
  PLACE_W: 120,
  PLACE_H: 80,
  MODULE_W: 120/4,
  MODULE_H: 80/2,
  A_PLACE_W: 129.6,
  A_PLACE_H: 84.24,
  A_MODULE_W: 129.6/4,
  A_MODULE_H: 84.24/2,
  ARCHIVE_RADIUS: "50vh",
  ARCHIVE_OFFSET: "-20vh",
  SHELF_RADIUS: "50vh",
  SHELF_OFFSET: "-20vh",
  FIT_TEXT_MAX_EPECHO: 50,
  FIT_TEXT_SLIGHT_STEP: 2,
};

export enum GACategory {
  LOGIN = 'Login',
  STATS = 'Stats',
  AUDIENCE_DISPLAY = 'Audience Display',
  DASHBOARD = 'Dashboard',
  ROOM_SETTINGS = 'Room Settings',
}

export enum GAAction {
  CLICK = 'Click',
  NAVIGATE = 'Navigate',
  EDIT = 'Edit',
  DRAG = 'Drag',
  UNSHELF = 'Unshelf',
  ARCHIVE = 'Archive',
  SEARCH = 'Search',
  CREATE = 'Create',
  DELETE = 'Delete',
}

export const UNSC = [
  'CN',
  'FR',
  'RU',
  'GB',
  'US',
  'EE',
  'IN',
  'IE',
  'KE',
  'MX',
  'NE',
  'NO',
  'VC',
  'TN',
  'VN',
];

export const ECOSOC = [
  'AR',
  'AM',
  'AO',
  'AU',
  'AT',
  'BD',
  'BJ',
  'BO',
  'BW',
  'BR',
  'BG',
  'CA',
  'CN',
  'CO',
  'CG',
  'EG',
  'ET',
  'FI',
  'FR',
  'GA',
  'DE',
  'GT',
  'ID',
  'IR',
  'JM',
  'JP',
  'KE',
  'LV',
  'LR',
  'LY',
  'LU',
  'MG',
  'ML',
  'MX',
  'CS',
  'NL',
  'NI',
  'NG',
  'NO',
  'PK',
  'PA',
  'PY',
  'PT',
  'KR',
  'RU',
  'SA',
  'SB',
  'CH',
  'TH',
  'TM',
  'UA',
  'GB',
  'US',
  'ZW',
];

export const UNHRC = [
  'BG',
  'AR',
  'BF',
  'BH',
  'CZ',
  'BS',
  'AT',
  'CM',
  'BD',
  'DK',
  'FJ',
  'IT',
  'ER',
  'IN',
  'UY',
  'SO',
  'PH',
  'TG',
  'BO',
  'CN',
  'CI',
  'CU',
  'FR',
  'GA',
  'MW',
  'MX',
  'NP',
  'PK',
  'RU',
  'SN',
  'UA',
  'GB',
  'UZ',
];

export const ARF = [
  'BN',
  'KH',
  'ID',
  'LA',
  'MY',
  'MM',
  'PH',
  'SG',
  'TH',
  'VN',
  'AU',
  'CA',
  'CN',
  'EU',
  'IN',
  'JP',
  'NZ',
  'KR',
  'MN',
  'PK',
  'LK',
  'TL',
  'PG',
];

export const OPEC = [
  'IR',
  'IQ',
  'KW',
  'SA',
  'VE',
  'LY',
  'AE',
  'DZ',
  'NG',
  'GA',
  'AO',
  'GQ',
  'CG',
];

export const EU = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

export const OSCE = [
  'AL',
  'AD',
  'AM',
  'AT',
  'AZ',
  'BY',
  'BE',
  'BA',
  'BG',
  'CA',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'GE',
  'DE',
  'GR',
  'VA',
  'HU',
  'IS',
  'IE',
  'IT',
  'KZ',
  'KG',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MD',
  'MC',
  'MN',
  'CS',
  'NL',
  'MK',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'TJ',
  'TR',
  'TM',
  'UA',
  'GB',
  'US',
  'UZ',
];
