import React from 'react';
import Circular from 'components/basic/Circular';
import classes from './style.module.css';

export default function RatioGraph({
  datas = [
    { key: 'a', value: 1000, color: 'gray' },
    { key: 'b', value: 1000, color: 'gray' },
  ],
  children,
  withText = true,
  width = 180,
  thick = 35,
}: Props) {
  let total = 0;
  datas.forEach((d) => { total += d.value; });

  function draw() {
    let nowDeg = 0;
    const result = datas
      .filter((d) => d.value > 0)
      .map((d, i) => {
        if (d.value === 0) return null;
        const arc = (
          <Circular
            key={d.key}
            width={width}
            height={width}
            thicken={thick}
            text={withText ? `${Math.round((d.value / total) * 100)}%` : ''}
            startDeg={i === 0 ? 0 : nowDeg}
            deg={(d.value / total) * 360}
            color={d.color}
            className={classes.circular}
            fontSize={12}
          />
        );
        nowDeg += (d.value / total) * 360;
        return arc;
      });
    return result;
  }

  return (
    <div className={classes.root} style={{width, height: width}}>
      {draw()}
      {children}
    </div>
  );
}

interface IRatioEntity {
  key: string;
  value: number;
  color: string;
}

type Props = {
  datas: Array<IRatioEntity>;
  children?: React.ReactNode;
  withText?: boolean;
  width?: number;
  thick?: number;
};
