import React, { useContext, useEffect } from 'react';
import * as R from 'ramda';
import ModulePlace from 'components/section/ModulePlace/index';
import DisplayTabBar from 'components/section/DisplayTabBar/index';
import {
  useRoomDisplayInfoQuery,
  DisplayCreatedSubscriptionVariables,
  DisplayCreatedSubscription,
  DisplayUpdatedSubscriptionVariables,
  DisplayUpdatedSubscription,
  DisplayDeletedSubscriptionVariables,
  DisplayDeletedSubscription,
  RoomUpdatedSubscription,
  RoomUpdatedSubscriptionVariables,
  ModuleUpdatedSubscriptionVariables,
  ModuleStatus,
  ModuleUpdatedSubscription,
} from 'graphql/generated/graphql';
import { RoomContext } from 'contexts';
import { useParams } from 'react-router-dom';
import {
  DISPLAY_CREATED_SUBSCRIPTION,
  DISPLAY_UPDATED_SUBSCRIPTION,
  DISPLAY_DELETED_SUBSCRIPTION,
  ROOM_UPDATED_SUBSCRIPTION,
  MODULE_UPDATED_SUBSCRIPTION,
} from 'graphql/subscriptions';
import { useSubscription } from '@apollo/client';
import { MODULE_FRAGMENT } from 'graphql/fragments';
import classes from './style.module.css';

export default function Displays() {

  const { setSelectedId, selectedId } = useContext(RoomContext);

  const { roomId } = useParams<{ roomId: string }>();

  const { data, subscribeToMore } = useRoomDisplayInfoQuery({ variables: { RID: roomId } });

  useSubscription<ModuleUpdatedSubscription, ModuleUpdatedSubscriptionVariables>(MODULE_UPDATED_SUBSCRIPTION, {
    variables: {
      RID: roomId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      /* eslint-disable no-underscore-dangle */
      if (subscriptionData.data?.moduleUpdated) {
        const updatedModule = subscriptionData.data.moduleUpdated;
        const { cache } = client;

        cache.writeFragment({
          id: cache.identify(updatedModule),
          fragment: MODULE_FRAGMENT,
          fragmentName: 'Module',
          data: updatedModule,
        });

        if (updatedModule.status === ModuleStatus.Shelf) {
          cache.modify({
            id: cache.identify({ __typename: 'Room', _id: roomId }),
            fields: {
              shelfList(cachedList) {
                const index = cachedList.findIndex((v: {__ref:string}) => v.__ref.split(':')[1] === updatedModule._id);
                if (index === -1) {
                  return R.append({ __ref: `${updatedModule.__typename}:${updatedModule._id}` }, cachedList);
                }

                return cachedList;
              },
            },
          });
        } else {
          cache.modify({
            id: cache.identify({ __typename: 'Room', _id: roomId }),
            fields: {
              shelfList(cachedList) {
                const index = cachedList.findIndex((v: {__ref:string}) => v.__ref.split(':')[1] === updatedModule._id);
                if (index !== -1) {
                  return R.remove(index, 1, cachedList);
                }

                return cachedList;
              },
            },
          });
        }
      }
      /* eslint-enable no-underscore-dangle */
    },
  });

  // subscribe to displays
  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubsDisplayCreated = subscribeToMore<DisplayCreatedSubscription, DisplayCreatedSubscriptionVariables>({
      document: DISPLAY_CREATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const createdDisplay = subscriptionData.data.displayCreated;
        if (createdDisplay) {
          return {
            ...prev,
            room: prev.room ? {
              ...prev.room,
              displays: R.append(createdDisplay, prev.room.displays),
            } : prev.room,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsDisplayCreated);

    const unSubsDisplayUpdated = subscribeToMore<DisplayUpdatedSubscription, DisplayUpdatedSubscriptionVariables>({
      document: DISPLAY_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedDisplay = subscriptionData.data.displayUpdated;
        if (updatedDisplay) {
          const existingIndex = R.findIndex(R.propEq('_id', updatedDisplay._id))(prev.room?.displays || []);
          if (existingIndex === -1) return prev;

          return {
            ...prev,
            room: prev.room ? {
              ...prev.room,
              displays: R.update(existingIndex, updatedDisplay, prev.room.displays),
            } : prev.room,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsDisplayUpdated);

    const unSubsDisplayDeleted = subscribeToMore<DisplayDeletedSubscription, DisplayDeletedSubscriptionVariables>({
      document: DISPLAY_DELETED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const deletedDisplay = subscriptionData.data.displayDeleted;
        if (deletedDisplay) {
          return {
            ...prev,
            room: prev.room ? {
              ...prev.room,
              displays: R.without([deletedDisplay], prev.room.displays),
            } : prev.room,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsDisplayDeleted);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  // subscribe to room
  useEffect(() => {
    const unSubs: { (): void; }[] = [];
    const unSubsRoomUpdated = subscribeToMore<RoomUpdatedSubscription, RoomUpdatedSubscriptionVariables>({
      document: ROOM_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedRoom = subscriptionData.data.roomUpdated;
        if (updatedRoom) {
          return {
            ...prev,
            room: updatedRoom,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsRoomUpdated);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  useEffect(() => {
    // only update selectedId when current selectedId not exist in displays
    if (data?.room?.displays && data.room.displays.findIndex((v) => v._id === selectedId) === -1) {
      setSelectedId(data.room.currentDisplay?._id);
    }
  }, [data?.room, selectedId, setSelectedId]);

  return (
    <div className={classes.expand}>
      {data && data.room && data.room.currentDisplay
        && (
          <>
            <DisplayTabBar
              displays={data.room.displays}
              current={data.room.currentDisplay._id}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
            />
           
            {selectedId !== '' && data.room.displays.findIndex((d) => d._id === selectedId) > -1
              ? <ModulePlace selectedId={selectedId} />
              :  <div className={classes.col}>'This display is removed!'</div>}
            
          </>
        )}
    </div>
  );
}
