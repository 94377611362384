import React from 'react';
import Eclipse from 'components/basic/Eclipse/index';
import Icon from 'components/basic/Icon';
import Space from 'components/basic/Space/index';
import classes from './style.module.css';

const colors = ["var(--color-primary)", "var(--color-secondary)", "var(--color-dark)"];

const tabs = [
  <Eclipse color={colors[0]} active>
    <Icon className="content" icon="table" size={50} />
  </Eclipse>,
  <Eclipse color={colors[1]} active>
    <Icon className="content" icon="rollcall" size={50} />
  </Eclipse>,
  <Eclipse color={colors[2]} active>
    <Icon className="content" icon="history" size={50} />
  </Eclipse>,
];

const titles = ['Tally Sheet', 'Roll Call', 'History'];

export default function StatusDropDown({ index, setIndex }: Props) {
  return (
    <div className={classes.root}>
      <Icon className="content" icon="expand" size={50} color={colors[index]} />
      {tabs[index]}
      <Space gap={20} h />
      <span className={classes.title} style={{color: colors[index]}}>{titles[index]}</span>
      <div className={classes.menu}>
        <div className={[classes.list, 'list'].join(' ')}>
          <div className={classes.item}>
            <Eclipse color={colors[0]} active={index === 0} onClick={() => setIndex(0)}>
              <Icon className="content" icon="table" size={50} />
            </Eclipse>
            <span style={{ color: colors[0] }}>Tally Sheet</span>
          </div>
          <div className={classes.item}>
            <Eclipse color={colors[1]} active={index === 1} onClick={() => setIndex(1)}>
              <Icon className="content" icon="rollcall" color="blue" size={50} />
            </Eclipse>
            <span style={{ color: colors[1] }}>Roll Call</span>
          </div>

          {/* History */}
          {/* <div className={classes.item}>
            <Eclipse color={theme.color.dark} active={index === 2} onClick={() => setIndex(2)}>
              <Icon className="content" icon="history" size={50} />
            </Eclipse>
            <span style={{ color: theme.color.dark }}>History</span>
          </div> */}
          {/* History */}

        </div>
      </div>
    </div>
  );
}

type Props = { index: number; setIndex: Function };
