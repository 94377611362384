import React, { useContext, useRef, useState } from 'react';
import Space from 'components/basic/Space';
import Input from 'components/basic/Input';
import { ModuleInfo } from 'components/module/moduleInfo';
import Icon from 'components/basic/Icon';
import { IModuleInfo } from 'components/module/moduleDefine';
import { DragAndDropContext } from 'contexts';
import Shelf from 'components/section/Shelf';
import { ModuleType } from 'graphql/generated/graphql';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import useWindowSize from 'hooks/useWindowSize';
import classes from './style.module.css';
import 'react-tooltip/dist/react-tooltip.css'


function ModuleItem({ module, mid, hideList }: ItemProps) {
  const { setDragStatus } = useContext(DragAndDropContext);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  function touchstart() {
    timer.current = setTimeout(()=>{
      hideList(true)
      setDragStatus({ action: 'create', type: mid, dim: module.default.dim })
    }, 400); 
  }

  function touchend() {
      hideList(false)
      if (timer.current)
          clearTimeout(timer.current); // clearTimeout, not cleartimeout..
  }

  function touchMove(){
    if (timer.current)
      clearTimeout(timer.current); // clearTimeout, not cleartimeout..
  }

  return (
    <div
      className={classes.item}
      onMouseDown={() => setDragStatus({ action: 'create', type: mid, dim: module.default.dim })}
      onTouchStart={touchstart}
      onTouchEnd={touchend}
      onTouchMove={touchMove}
    >
      <div>
        <Icon icon={module.icon} size={25} />
        <Space h gap={10} />
        {module.name}
      </div>
      <span
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onTouchStart={(e) => {
            e.stopPropagation();
        }}
      >
        <a data-tooltip-id="info-tooltip" data-tooltip-content={module.description}>
          <Icon className={classes.info} icon="info" size={20} />
        </a>
      </span>
    </div>
  );
}

interface ItemProps {
  mid: ModuleType
  module: IModuleInfo<any>
  hideList: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ModuleMenu() {
  const { ready } = useContext(DragAndDropContext);
  const [queryString, setQueryString] = useState('');
  const { width } = useWindowSize();
  const [hide, setHide] = useState(false);

  return <>
  <ReactTooltip 
    id={"info-tooltip"} 
    place="right" 
    variant="light" 
    float={false} 
    className={"shadow"}
    style={{zIndex: 20}}
  />
  {width < window.innerHeight * 1.2 + 400
    ? (
      <div className={classes.shrunk} style={{
        pointerEvents: !hide ? 'auto' : 'none',
        opacity: !hide ? 1 : 0.5,
      }}>
        <div className={classes.shrunkRoot}>
          <>
            <Input
              icon="search"
              value={queryString}
              onChange={(e) => setQueryString(e.target.value)}
              onFocus={() => {
                // ReactGA.event({
                //   category: GACategory.DASHBOARD,
                //   action: GAAction.SEARCH,
                //   label: 'Modules',
                // });
              }}
            />
            <Space gap={10} v />
            <div className={classes.tutorialLink}>
              Check out
              <Space gap={5} h />
              <a
                target="_blank"
                href="https://docs.google.com/presentation/d/1jr2GZlwED-mSjl4ZXU42XcCwB37-CgP2HiKJm3EWXxw/edit?usp=sharing"
                rel="noreferrer"
              >
                Munative Lite Tutorial
              </a>
            </div>

            <Space gap={10} v />
            <div className={classes.list}>
              {Object.keys(ModuleInfo)
                .filter(
                  (key) => ModuleInfo[key].name.toLowerCase().indexOf(queryString.toLowerCase()) > -1,
                )
                .map((key) => (
                  <ModuleItem key={key} mid={key as ModuleType} module={ModuleInfo[key]} hideList={setHide}/>
                ))}
            </div>
          </>
        </div>
        <div className={classes.shrunkLabel}>
          <Icon icon="toolbox" size={20} color="white" />
          <Space v gap={5} />
          Toolbox
        </div>
      </div>
    )
    : (
      <div 
        className={classes.root} 
        style={{
          display: width && (width < 1000) ? 'none' : 'flex',
          pointerEvents: ready ? 'auto' : 'none',
          opacity: ready ? 1 : 0.5,
        }}
      >
        <>
          <Input
            icon="search"
            value={queryString}
            onChange={(e) => setQueryString(e.target.value)}
            onFocus={() => {
              // ReactGA.event({
              //   category: GACategory.DASHBOARD,
              //   action: GAAction.SEARCH,
              //   label: 'Modules',
              // });
            }}
          />
          <Space gap={10} v />
          <div className={classes.tutorialLink}>
            Need help? Check out
            <Space gap={5} h />
            <a
              target="_blank"
              href="https://docs.google.com/presentation/d/1jr2GZlwED-mSjl4ZXU42XcCwB37-CgP2HiKJm3EWXxw/edit?usp=sharing"
              rel="noreferrer"
            >
              Munative Lite Tutorial
            </a>
          </div>

          <Space gap={10} v />
          <div className={classes.list}>
            {Object.keys(ModuleInfo)
              .filter(
                (key) => ModuleInfo[key].name.toLowerCase().indexOf(queryString.toLowerCase()) > -1,
              )
              .map((key) => (
                <ModuleItem key={key} mid={key as ModuleType} module={ModuleInfo[key]} hideList={setHide}/>
              ))}
          </div>
        </>
      </div>
    )}
    <Shelf />
    </>
}
