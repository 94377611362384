import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'theme';

type ClassNames = 'root' | 'active';

type StyleProps = { color: string };

const useStyles = createUseStyles<ClassNames, StyleProps, Theme>({
  root: ({ color }) => ({
    display: 'inline-block',
    position: 'relative',
    width: 70,
    height: 70,
    border: [[5, 'solid', color]],
    borderRadius: 100,
    overflow: 'hidden',
    color,
    background: 'white',
    transition: '0.4s',
    '& .overlay': {
      borderRadius: 100,
      position: 'absolute',
      bottom: -100,
      left: -100,
      width: 200,
      height: 200,
      transform: 'scale(0)',
      transition: '0.3s',
      backgroundColor: color,
      zIndex: 10,
    },
    '& .content': {
      color,
      fill: color,
      zIndex: 40,
      transition: '0.3s',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },
    '&:hover': {
      backgroundColor: color,
      color: 'white',
      '& .overlay': {
        transform: 'scale(1)',
      },
      '& .content': {
        color: 'white',
        fill: 'white',
      },
    },
  }),
  active: ({ color }) => ({
    display: 'inline-block',
    position: 'relative',
    width: 70,
    height: 70,
    border: [[5, 'solid', color]],
    borderRadius: 100,
    overflow: 'hidden',
    background: color,
    '& .content': {
      color: 'white',
      fill: 'white',
      zIndex: 20,
      transition: '0.3s',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },
  }),
});

/**
 * Eclipse Component － A eclipse effect button
 * @example <Eclipse className={extendClass} onClick={()=>{...}}>Eclipse</Eclipse>
 */
export default function Eclipse({
  className, children, color, onClick, active = false,
}: Props) {
  const theme = useTheme<Theme>();
  const classes = useStyles({ color: color || theme.color.primary });
  return active ? (
    <span className={[classes.active, className].join(' ')} onClick={onClick}>
      {children}
    </span>
  ) : (
    <span className={[classes.root, className].join(' ')} onClick={onClick}>
      <span className="overlay" />
      {children}
    </span>
  );
}

type Props = {
  color?: string
  className?: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
  active?: boolean
};
