import React, { useState, useEffect } from 'react';
import { getCountry } from 'utils/countries/index';
import Space from 'components/basic/Space/index';
import Modal from 'components/basic/Modal/index';
import Input from 'components/basic/Input';
import Card from 'components/basic/Card';
import Icon from 'components/basic/Icon';
import Button from 'components/basic/Button';
import { IndividualType, useRoomIndividualsQuery, Individual } from 'graphql/generated/graphql';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { getIdvIcon, getIdvName } from 'utils/individual';
import classes from './style.module.css';

function IndividualItem({ individual, selected, onClick }: ItemProps) {

  return (
    <Card className={classes.item} onClick={() => onClick(individual)}>
      {selected
        ? (
          <>
            <Icon icon="check" color={"var(--color-primary)"} size={30} />
            <Space h gap={10} />
          </>
        )
        : <Space h gap={40} />}
      <span className={classes.head}>
        {individual.type !== IndividualType.Custom
          && <img src={getIdvIcon(individual)} alt="Flag" width="30" />}
        <Space h gap={10} />
        <div className={classes.title}>{getIdvName(individual, false)}</div>
      </span>
      <span />
    </Card>
  );
}

type ItemProps = {
  individual: Individual
  selected: boolean
  onClick: Function
};

export default function IndividualSelectModal({
  isShow, setShow, multiple, value, onFinish,
}: Props) {
  const [queryString, setQueryString] = useState('');

  const { roomId } = useParams<{ roomId: string }>();

  const [selected, setSelected] = useState<Individual[]>([]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const { data, loading } = useRoomIndividualsQuery({ variables: { RID: roomId } });

  function handleClick(id: Individual) {
    if (multiple) {
      const index = selected.findIndex((s) => s._id === id._id);
      if (index === -1) {
        const tem = _.cloneDeep(selected);
        tem.push(id);
        setSelected(tem);
      } else setSelected(selected.filter((s) => s._id !== id._id));
    } else setSelected([id]);
  }

  function candidateMatch(idv: Individual) {
    if (idv.type === IndividualType.Country) {
      const c = getCountry(idv.individualCode!);
      return c && (c.name.toLowerCase().indexOf(queryString.toLowerCase()) > -1
        || c.alpha2.toLowerCase().indexOf(queryString.toLowerCase()) > -1
        || c.alpha3.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
    }
    if (idv.type === IndividualType.Org) {
      const c = idv.individualCode || '';
      return (c.indexOf(queryString.toLowerCase()) > -1
        || c.toLowerCase().indexOf(queryString.toLowerCase()) > -1
        || c.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
    }

    return (idv.name!.toLowerCase().indexOf(queryString.toLowerCase()) > -1) || false;
  }

  return (
    <Modal isShow={isShow} setShow={setShow} width={450}>
      <Input icon="search" value={queryString} onChange={(e) => setQueryString(e.target.value)} />
      <Space v gap={10} />
      <div className={classes.list}>
        {!loading && data?.room?.individuals.filter(candidateMatch).slice()
          .sort((a, b) => {
            if (getIdvName(a, true) < getIdvName(b, true)) { return -1; }
            if (getIdvName(a, true) > getIdvName(b, true)) { return 1; }
            return 0;
          }).map((individual) => (
            <IndividualItem
              key={individual._id}
              individual={individual}
              selected={selected.findIndex((i) => i._id === individual._id) > -1}
              onClick={(idv: Individual) => handleClick(idv)}
            />
          ))}
      </div>
      <Space v gap={20} />

      <div className={classes.actionBar}>
        <Button onClick={() => {
          onFinish(selected);
          setShow(false);
        }}
        >
          Done
        </Button>
      </div>
    </Modal>
  );
}

type Props = {
  isShow: boolean
  setShow: Function
  multiple?: boolean
  value: Individual[]
  onFinish: Function
};
