import React, { useRef, useEffect, useContext, useMemo } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import BaseFrame from 'components/module/BaseFrame';
import { DragAndDropContext } from 'contexts';
import { useDisplayQuery } from 'graphql/generated/graphql';
import { DIMENSION } from 'constant';
import classes from './style.module.css';
import { is } from 'ramda';
import { isMobile } from 'react-device-detect';
import { defaultRequestInstrumentationOptions } from '@sentry/tracing';

export default function ModulePlace({ selectedId }: Props) {
  const placeRef = useRef<HTMLInputElement>(null);

  const { data, loading } = useDisplayQuery({ variables: { DID: selectedId } });

  const {
    placeDim, setPlaceDim,
  } = useContext(DragAndDropContext);

  const { height: winH } = useWindowSize();

  useEffect(() => {
    if (placeRef.current) {
      setPlaceDim({ w: placeRef.current.offsetWidth, h: placeRef.current.offsetHeight });
      // setOffset({ x: placeRef.current.getBoundingClientRect().left, y: 105 });
    }
  }, [placeRef, winH, setPlaceDim]);

  function determineSize() {
    
  }



  function placeDimension(ratio: number) {
    if (isMobile) {
      return {
        w: winH * 0.01 * ratio * DIMENSION.PLACE_W + 'px',
        h: winH * 0.01 * ratio * DIMENSION.PLACE_H + 'px',
      }
    }
    return {
      w: DIMENSION.PLACE_W*ratio + 'vh',
      h: DIMENSION.PLACE_H*ratio + 'vh',
    }
  };

  return useMemo(
    () => (
      <>
        <div 
          className={classes.root} 
          ref={placeRef} 
          id="module-place"
          style={{
            width: placeDimension(1).w,
            height: placeDimension(1).h,
          }}
        >
          <svg width="100%" height="100%">
            <line
              x1="0"
              y1={placeDimension(0.5).h}
              x2={placeDimension(1).w}
              y2={placeDimension(0.5).h}
              stroke="black"
              strokeDasharray="2"
            />
            <line
              x1={placeDimension(0.25).w}
              y1="0"
              x2={placeDimension(0.25).w}
              y2={placeDimension(1).h}
              stroke="black"
              strokeDasharray="2"
            />
            <line
              x1={placeDimension(0.5).w}
              y1="0"
              x2={placeDimension(0.5).w}
              y2={placeDimension(1).h}
              stroke="black"
              strokeDasharray="2"
            />
            <line
              x1={placeDimension(3/4).w}
              y1="0"
              x2={placeDimension(3/4).w}
              y2={placeDimension(1).h}
              stroke="black"
              strokeDasharray="2"
            />
          </svg>

          {!loading &&
            data &&
            data!.display!.modules.map((m) => (
              <BaseFrame
                key={m._id}
                id={m._id}
                highlight={data.display?.highlightModule?._id}
                DID={selectedId}
              />
            ))}
          {/* result:{640 < winH && winH * 0.9 - placeDim.w < 1 ? "ok" : "under-size"} */}
        </div>
        {/* <div className={classes.debugInfo}>
        [Debug Info] <br />
        Window Size:{winW},{winH} <br />
        Place Size: {placeDim.w},{placeDim.h}
        <br />
        Per-Module Size: {placeDim.w / 3},{placeDim.h / 2}
        <br />
        Place offset: {offset.x},{offset.y}
        <br />
        [Size Check]
        <br />
        Width Error:{winH * 0.9 - placeDim.w}
        <br />
        w-result:{winH * 0.9 - placeDim.w < 1 ? "ok" : "under-size"}
        <br />
        h-result:{640 < winH ? "ok" : "under-size"}
        <br />
      </div> */}
      </>
    ),
    [data, placeDim.w, placeDim.h]
  );
}

type Props = {
  selectedId: string
};
