import React from 'react';
import Card from 'components/basic/Card/index';
import Space from 'components/basic/Space/index';
import OutlineButton from 'components/basic/OutlineButton/index';
import { Individual, RollCallState } from 'graphql/generated/graphql';
import { getIdvIcon, getIdvName } from 'utils/individual';
import classes from './style.module.css';

export default function RollCallListItem({
  individual, state, updateState, audience,
}: Props) {

  return (

    <Card className={classes.item}>
      <span className={classes.head}>
        <img alt="flag" src={getIdvIcon(individual)} width="35" />
        <Space h gap={10} />
        <div className={classes.nameText}>{getIdvName(individual, true)}</div>
      </span>
      <span>
        {audience ? state && {
          absent: <OutlineButton
            title="Absent"
            color="red"
            active={state === RollCallState.Absent}
          />,
          presentAndVoting: <OutlineButton
            title="Present And Voting"
            color={"var(--color-secondary)"}
            active={state === RollCallState.PresentAndVoting}
          />,
          present: <OutlineButton
            title="Present"
            color={"var(--color-primary)"}
            active={state === RollCallState.Present}
          />,
        }[state] : (
          <>
            <OutlineButton
              title="A"
              color="red"
              active={state === RollCallState.Absent}
              onClick={() => updateState(individual, RollCallState.Absent)}
            />
            <OutlineButton
              title="P & V"
              color={"var(--color-secondary)"}
              active={state === RollCallState.PresentAndVoting}
              onClick={() => updateState(individual, RollCallState.PresentAndVoting)}
            />
            <OutlineButton
              title="P"
              color={"var(--color-primary)"}
              active={state === RollCallState.Present}
              onClick={() => updateState(individual, RollCallState.Present)}
            />
          </>
        )}
      </span>
    </Card>
  );
}

type Props = {
  individual: Individual
  state: RollCallState | null
  updateState: Function
  audience?: boolean
};
