import React from 'react';
import { createPortal } from 'react-dom';
import useWindowSize from 'hooks/useWindowSize';
import { isMobile } from 'react-device-detect';
import Icon from '../Icon';
import classes from './style.module.css';


export default function FullScreenWarning({
  trigger, title, content, priority = 10,
}: Props) {
  const { width, height } = useWindowSize();

  if (
    (trigger === 'device' && isMobile)
    || (trigger === 'size' && (width < 980 || height < 560))
  ) {
    return createPortal(
      <div className={classes.root} style={{zIndex: 50 + priority,}}>
        <Icon icon="warning" size={60} color={"var(--color-primary)"} />
        <h1 style={{ color: "var(--color-primary)" }}>{title}</h1>
        <h2 style={{ color: "var(--color-primary)", textAlign: 'center', fontWeight: 'normal' }}>
          {content}
        </h2>
      </div>,
      document.getElementById('root')!,
    );
  }

  return null;
}

type Props = {
  trigger: 'device' | 'size';
  title: string;
  content: React.ReactNode;
  priority: number;
};
