import React, { useState, useContext } from 'react';
import ModuleMenu from 'components/section/ModuleMenu/index';
import DragLayer from 'components/section/DragLayer';
import { DragAndDropContext, RoomContext } from 'contexts';
import { IDragStatus } from 'components/section/DragLayer/IDragStatus';
import Displays from './Displays';
import classes from './style.module.css';

export default function DisplayPanel() {

  const [dragStatus, setDragStatus] = useState<IDragStatus>({ action: 'null' });
  const [placeDim, setPlaceDim] = useState({ w: 0, h: 0 });

  const [ready, setReady] = useState(false);

  const { selectedId } = useContext(RoomContext);

  return (
    <DragAndDropContext.Provider
      value={{
        dragStatus,
        setDragStatus,
        placeDim,
        setPlaceDim,
        ready,
        setReady,
      }}
    >
      <div className={classes.root}>
        <ModuleMenu />
        <Displays />
      </div>
      {selectedId.length > 0 && <DragLayer />}
    </DragAndDropContext.Provider>
  );
}
