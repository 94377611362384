import React, { ComponentClass, useEffect } from 'react';
import {
  Switch, Route, Redirect, Router,
} from 'react-router-dom';
import { History } from 'history';
import { ThemeProvider } from 'theming';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

import theme from 'theme';
import 'css/general.css';
import 'css/animations.css';

import HostDashboard from 'pages/HostDashboard/index';
import LandingPage from 'pages/LandingPage/index';
import FullScreenWarning from 'components/basic/FullScreenWarning';
import Audience from './pages/Audience/index';

const SentryRoute = Sentry.withSentryRouting(Route as ComponentClass<Record<string, any>, any>);

type AppParams = {
  history: History;
};

export function App({ history }: AppParams) {
  
  useEffect(() => {
    ReactGA.initialize('G-ZQ28594JQT');
    ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search, title: "Page View"});
  }, [])
 

  // const { width, height } = useWindowSize();

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <SentryRoute path="/room/:roomId" exact render={(p:any) => <Redirect to={`/room/${p.match.params.roomId}/display`} />} />

          <SentryRoute path="/room/:roomId/:tab" exact>
            <HostDashboard />
          </SentryRoute>

          <SentryRoute path="/audience/:roomId" exact>
            <Audience />
          </SentryRoute>

          <SentryRoute path="/" exact>
            <LandingPage />
          </SentryRoute>
          <SentryRoute path="/">Not Found</SentryRoute>

        </Switch>
      </Router>
      <FullScreenWarning
        trigger="size"
        priority={20}
        title="Larger device required."
        content={
          (
            <>
              Seems like this screen is too small.
              <br />
              To have a better experience, please use Munative Lite on your PC or laptop and make sure
              the window is maximized.
            </>
          )
        }
      />
    </ThemeProvider>

  );
}

export default Sentry.withProfiler(App);
