/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, MouseEventHandler, ReactNode } from 'react';
import _ from 'lodash';
import { ICoreProps } from 'components/module/moduleDefine';
import Space from 'components/basic/Space/index';
import CircularProcessing from 'components/composed/CircularProcessbar';
import Icon from 'components/basic/Icon';
import IconButton from 'components/basic/IconButton';
import {
  Individual, IndividualType, SpeakerYield, SpeakerYieldType, SpeakerStanceType, SpeakersListModuleDataFragment, SpeakerYieldInputType,
  useRoomIndividualsQuery,
} from 'graphql/generated/graphql';
import Modal from 'components/basic/Modal/index';
import Input from 'components/basic/Input/index';
import Card from 'components/basic/Card';
import { useParams } from 'react-router-dom';
import { getIdvIcon, getIdvName } from 'utils/individual';
import Button from 'components/basic/Button/index';
import { fmtTimeMS } from 'utils/time';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { notEmpty } from 'utils/type';
import { TimerStatus } from 'hooks/useTimer';
import SpeakerListItem from './SpeakerListItem';
import { useSpeakersListTimer } from './useSpeakersListTimer';
import classes from './list.module.css';

function IndividualItem({
  individual, selected, onClick, draggableIndex,
}: ItemProps) {
  if (draggableIndex === undefined) {
    return (
      <Card className={classes.item + " " +( selected ? classes.selected : "")} onClick={onClick}>
        <img
          alt="flag"
          src={getIdvIcon(individual)}
          width="35"
        />
        <Space h gap={10} />
        {getIdvName(individual, false)}
      </Card>
    );
  }
  return (

    <Draggable draggableId={individual._id} index={draggableIndex}>
      {(provided) => (
        <span
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.item + " " +( selected ? classes.selected : "")}
          onClick={onClick}
        >
          <img
            alt="flag"
            src={getIdvIcon(individual)}
            width="35"
          />
          <Space h gap={10} />
          {getIdvName(individual, false)}
        </span>
      )}
    </Draggable>

  );
}

type ItemProps = { individual: Individual, selected: boolean, onClick: MouseEventHandler, draggableIndex?: number };

export default function MunativeSpeakersList({
  data,
  setEditData,
  dispatchUpdateModule,
  realDim,
  audience,
}: ICoreProps<SpeakersListModuleDataFragment>) {
  const [isEditing, setEditing] = useState(false);
  const [isYielding, setYielding] = useState(false);
  const [queryString, setQueryString] = useState('');
  const { roomId } = useParams<{ roomId: string }>();
  const { data: roomData, loading } = useRoomIndividualsQuery({ variables: { RID: roomId } });

  function candidateMatch(c: Individual) {
    if (c.type === IndividualType.Custom) return c!.name!.toLowerCase().indexOf(queryString.toLowerCase()) > -1;

    return c!.individualCode!.toLowerCase().indexOf(queryString.toLowerCase()) > -1;
  }

  // individual data area
  const [list, setList] = useState<(Individual)[]>([]);
  const [current, setCurrent] = useState<Individual | null | undefined>(null);
  const [speakerYield, setYield] = useState<SpeakerYield | null | undefined>(null);
  const [stance, setStance] = useState<SpeakerStanceType>(SpeakerStanceType.Neutral);
  const [title, setTitle] = useState('Primary');
  const [perSpeakerTime, setPerSpeakerTime] = useState(0);

  // const [speakerSecond, setSpeakerSecond] = useState(10)
  const [totalMinute, setTotalMinute] = useState(0);
  const [totalSecond, setTotalSecond] = useState(10);

  const timer = useSpeakersListTimer(data.timerState, data.speakersListTotalElapsedTime, data.speakersListTotalTime, (newData) => {
    setEditData({
      speakersList: newData,
    });
  });

  function play() {
    if (perSpeakerTime === 0) return;
    timer.play();

    dispatchUpdateModule();
  }

  function pause() {
    timer.pause();

    dispatchUpdateModule();
  }

  function reset() {
    timer.reset();
    setEditData({
      speakersList: {
        stance: SpeakerStanceType.Neutral,
        yield: null,
      },
    });

    dispatchUpdateModule();
  }

  function nextSpeaker() {
    if (!current) {
      if (list.length > 0) {
        setEditData({
          speakersList: {
            currentSpeaker: list[0]._id,
          },
        });
        dispatchUpdateModule();
      }
      return;
    }

    const index = list.findIndex((i) => i._id === current._id);

    if (list.length < index + 1) return;

    if (list.length === index + 1) {
      const filtered = list.filter((item) => item._id !== current._id);
      setEditData({
        speakersList: {
          list: filtered.map((i) => i._id),
          currentSpeaker: null,
        },
      });
      dispatchUpdateModule();
    } else {
      const filtered = list.filter((item) => item._id !== current._id);
      setEditData({
        speakersList: {
          list: filtered.map((i) => i._id),
          currentSpeaker: list[index + 1]._id,
        },
      });
      dispatchUpdateModule();
    }
    reset();
  }

  function assignCurrent(individual: Individual) {
    setEditData({
      speakersList: {
        currentSpeaker: individual._id,
      },
    });
    reset();

    dispatchUpdateModule();
  }

  function resetTotal() {
    setEditData({
      speakersList: {
        totalElapsedTime: 0,
      },
    });
    dispatchUpdateModule();
  }

  function assignStance(type: SpeakerStanceType) {
    setEditData({
      speakersList: {
        stance: type,
      },
    });

    dispatchUpdateModule();
  }

  function switchStance() {
    let nxtStance: SpeakerStanceType;
    if (stance === SpeakerStanceType.Neutral) nxtStance = SpeakerStanceType.For;
    else if (stance === SpeakerStanceType.For) nxtStance = SpeakerStanceType.Against;
    else nxtStance = SpeakerStanceType.Neutral;

    assignStance(nxtStance);
  }

  function assignYield(y: SpeakerYieldInputType | null) {
    setEditData({
      speakersList: {
        yield: y,
      },
    });

    dispatchUpdateModule();
  }

  function yieldTo(type: SpeakerYieldType | null, idv?: Individual) {
    if (!type) {
      assignYield(null);
    } else {
      if (!current) return;
      if (type === SpeakerYieldType.Individual) {
        if (!idv) return;
        // should not yield without individual
        assignYield({ type, individualId: idv._id });
      } else {
        assignYield({ type });
      }

      pause();
    }
    // close yield modal
    setYielding(false);
  }

  // Update module from data
  useEffect(() => {
    setTitle(data.title);
    setList(data.list.filter(notEmpty));
    setCurrent(data.currentSpeaker);

    setTotalSecond(data.speakersListTotalTime % 60);
    setTotalMinute(Math.floor(data.speakersListTotalTime / 60));

    setStance(data.stance || SpeakerStanceType.Neutral);
    setYield(data.yield);
    setPerSpeakerTime(data.timerState.totalTime);
  }, [data]);

  useEffect(() => {
    if (isEditing) {
      const newTimerState = _.pick(_.cloneDeep(data.timerState), ['totalTime', 'startTime', 'elapsedTime']);
      // if per speaker time changed, then reset timer
      if (perSpeakerTime !== newTimerState.totalTime) {
        newTimerState.totalTime = perSpeakerTime;
        newTimerState.startTime = null;
        newTimerState.elapsedTime = 0;
      }

      setEditData({
        speakersList: {
          title,
          list: list.filter(notEmpty).map((i) => i._id),
          totalTime: totalMinute * 60 + totalSecond,
          totalElapsedTime: (totalMinute * 60 + totalSecond) !== data.speakersListTotalTime ? 0 : undefined,
          timerState: newTimerState,
        },
      });
    }
  }, [data.timerState, setEditData, isEditing, list, perSpeakerTime, title, totalMinute, totalSecond, data.speakersListTotalTime]);

  function add(individual: Individual) {
    const tem = [...list];
    tem.push(individual);
    setList(tem);
  }

  function remove(individual: Individual) {
    setList([...list].filter((i) => i._id !== individual._id));
  }

  return (
    <>
      {/* Default page */}
      <div
        className="defaultView"
        style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
      >
        <div className={classes.title}>{title}</div>
        <Space v gap={60} />
        <div className={classes.controllers}>
          {(!audience || stance !== SpeakerStanceType.Neutral)
            && (
              <span className={classes.stance}>
                stance
                <br />
                <span
                  className={classes.button}
                  onClick={!audience ? switchStance : () => { }}
                  style={{
                    pointerEvents: current ? 'auto' : 'none',
                    filter: current ? 'brightness(1)' : 'brightness(.8)',
                    width: realDim.w * 0.18,
                    height: realDim.w * 0.18,
                  }}
                >
                  {{ for: 'For', against: 'Against', neutral: 'N/A' }[stance]}
                </span>
              </span>
            )}
          {!audience && (
            <span
              className={classes.button}
              onClick={() => setYielding(true)}
              style={{
                pointerEvents: current ? 'auto' : 'none',
                filter: current ? 'brightness(1)' : 'brightness(.8)',
                width: realDim.w * 0.18,
                height: realDim.w * 0.18,
              }}
            >
              <Icon icon="yield" color={"var(--color-primary)"} size={realDim.w * 0.08} />
            </span>
          )}
          <div  
            className={classes.current} style={{
            width: realDim.w * 0.28,
            height: realDim.w * 0.28,}}
          >
            <img alt="flag" src={getIdvIcon(current)} width={realDim.w * 0.23} />
            <CircularProcessing
              className={classes.process}
              width={realDim.w * 0.28}
              thicken={current ? realDim.w * 0.025 : 0}
              color={timer.status === TimerStatus.Running ? '#1989FF' : '#FFAE65'}
              max={perSpeakerTime === 0 ? 1 : perSpeakerTime}
              value={timer.speakerRemainTime}
            >
              {speakerYield && (
                speakerYield.type === SpeakerYieldType.Individual
                  ? <img className={classes.yielded} alt="flag" src={getIdvIcon(speakerYield.individual!)} width={realDim.w * 0.13} />
                  : (
                    <span 
                      className={classes.overlay}
                      style={{
                        width: realDim.w * 0.23,
                        height: realDim.w * 0.23,
                      }}
                    >
                      {speakerYield.type === SpeakerYieldType.Chair
                        ? <Icon icon="gavel" color="white" size={realDim.w * 0.15} />
                        : <Icon icon="question" color="white" size={realDim.w * 0.15} />}
                    </span>
                  ))}
            </CircularProcessing>
          </div>
          {!audience && (
            <span 
              className={classes.button} 
              onClick={nextSpeaker}
              style={{
                width: realDim.w * 0.18,
                height: realDim.w * 0.18,
              }}
            >
              <Icon icon="next" color={"var(--color-primary)"} size={realDim.w * 0.08} />
            </span>
          )}
        </div>
        <div className={classes.title}>{current ? getIdvName(current, true) : 'N/A'}</div>
        <div className={classes.speakerTime}>
          {fmtTimeMS(timer.speakerRemainTime)}
          /
          {fmtTimeMS(timer.speakerTotalTime)}
          {audience
            ? (
              <Icon
                icon={timer.status === TimerStatus.Running ? 'play' : 'pause'}
                color={timer.status === TimerStatus.Running ? '#1989FF' : '#FFAE65'}
                size={24}
              />
            ) : (
              <IconButton
                icon={timer.status === TimerStatus.Running ? 'pause' : 'play'}
                color={timer.status === TimerStatus.Running ? '#1989FF' : '#FFAE65'}
                onClick={timer.status === TimerStatus.Running ? pause : play}
                disabled={current === null}
              />
            )}

        </div>
        {timer.totalTime > 0 && (
          <div className={classes.totalTime}>
            <span>Total</span>
            <Space h gap={10} />
            {fmtTimeMS(timer.totalRemainTime)}
            /
            {fmtTimeMS(timer.totalTime)}
            {!audience
              && (
                <IconButton
                  icon="reset"
                  color="#1989FF"
                  onClick={() => resetTotal()}
                />
              )}
          </div>
        )}
        <Space v gap={5} />

        {!audience && (
          <div className={classes.editButton} onClick={() => setEditing(true)}>
            {'Edit Speakers\' List'}
          </div>
        )}

        <Space v gap={5} />
        <div className={classes.list}>
          {!loading && roomData!.room && list.map((i) => (
            <SpeakerListItem
              key={i._id}
              audience={audience}
              individual={i}
              mode={current?._id === i._id ? timer.status === TimerStatus.Running ? 'play' : 'pause' : null}
              onClick={audience ? () => { } : assignCurrent}
            />
          ))}
        </div>

      </div>
      {!audience
        && (
          <>
            <Modal isShow={isEditing} setShow={setEditing} width="80%">
              <div className={classes.modalTitle}>
                Edit Speakers’ List
                <Button onClick={() => {
                  setEditing(false);
                  dispatchUpdateModule();
                }}
                >
                  Done
                </Button>
              </div>
              <Space v gap={20} />
              <div className={classes.inputsArea}>
                <input className={classes.input} placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                <span>
                  <div className={classes.timeInput}>
                    <span className={classes.head}>Per Speaker</span>
                    <input type="number" value={perSpeakerTime} onChange={(e) => setPerSpeakerTime(parseInt(e.target.value, 10))} />
                    <span className={classes.label}>Sec</span>
                  </div>
                  <Space h gap={20} />
                  <div className={classes.timeInput}>
                    <span className={classes.head}>Total Time</span>
                    <input type="number" value={totalMinute} onChange={(e) => setTotalMinute(parseInt(e.target.value, 10))} />
                    <span className={classes.label}>Min</span>
                    <input type="number" value={totalSecond} onChange={(e) => setTotalSecond(parseInt(e.target.value, 10))} />
                    <span className={classes.label}>Sec</span>
                  </div>
                </span>
              </div>
              <div className={classes.selectorRoot}>
                <Card className={classes.card}>
                  <Input icon="search" value={queryString} onChange={(e) => setQueryString(e.target.value)} />
                  <Space v gap={20} />
                  <div className={classes.selectList}>
                    {!loading && roomData!.room && roomData!.room!.individuals
                      .filter(candidateMatch)
                      .filter((i: Individual) => list.findIndex((j: Individual | null) => j!._id === i._id) === -1)
                      .map((i: Individual) => (
                        <IndividualItem key={i._id} selected={false} individual={i} onClick={() => add(i!)} />
                      ))}

                  </div>
                </Card>
                <Space h gap={40} />
                <Card className={classes.card}>
                  <div className={classes.title}>Selected</div>
                  <DragDropContext onDragEnd={(result) => {
                    if (!result.destination) {
                      return;
                    }
                    const processed = Array.from(list);
                    const [removed] = processed.splice(result.source.index, 1);
                    processed.splice(result.destination.index, 0, removed);

                    setList(processed);
                  }}
                  >
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          className={classes.selectList}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {!loading && roomData!.room && list.map((item, index) => (
                            <IndividualItem
                              key={item!._id}
                              selected
                              individual={item!}
                              onClick={() => remove(item!)}
                              draggableIndex={index}
                            />
                          ))}
                          {provided.placeholder as ReactNode}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Card>
              </div>

            </Modal>
            <Modal isShow={isYielding} setShow={setYielding} width={450}>
              <div className={classes.modalTitle}>
                Yield to...
                <Button onClick={() => yieldTo(null)}>Clear Yield</Button>
              </div>
              <div className={classes.list} style={{ height: '70vh' }}>
                <Card className={classes.item + " "} onClick={() => yieldTo(SpeakerYieldType.Chair)}>
                  <Icon icon="gavel" color={"var(--color-primary)"} size={35} />
                  <Space h gap={10} />
                  Chair
                </Card>
                <Card className={classes.item + " "} onClick={() => yieldTo(SpeakerYieldType.Question)}>
                  <Icon icon="question" color={"var(--color-secondary)"} size={35} />
                  <Space h gap={10} />
                  Question
                </Card>
                {!loading && roomData!.room && roomData!.room!.individuals
                  .map((i: Individual) => (
                    <IndividualItem key={i._id} selected={false} individual={i} onClick={() => yieldTo(SpeakerYieldType.Individual, i)} />
                  ))}
              </div>
            </Modal>
          </>
        )}
    </>
  );
}
