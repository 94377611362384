import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'theme';

type ClassNames = 'root';

type StyleProps = { color: string, active: boolean };

const useStyles = createUseStyles<ClassNames, StyleProps, Theme>({
  root: ({ color, active }) => ({
    cursor: 'pointer',
    display: 'inline-block',
    alignItems: 'center',
    border: [2, 'solid', color || 'black'],
    color: active ? 'white' : color || 'black',
    backgroundColor: active ? color || 'black' : 'white',
    padding: [2, 10],
    margin: [0, 5],
    borderRadius: 100,
    fontSize: 14,
    transition: '0.3s',
    '&:hover': {
      color: 'white',
      backgroundColor: color || 'black',
    },
  }),
});

/**
 * Basic Component － A normal texted button
 * @example <OutlineButton className={extendClass} onClick={()=>{...}}>Button</OutlineButton>
 */

export default function OutlineButton({
  title, color, active = false, onClick,
}: Props) {
  const classes = useStyles({ color, active });

  return (
    <span className={classes.root} onClick={onClick}>
      {title}
    </span>
  );
}

type Props = {
  title: string
  color: string
  active?: boolean
  onClick?: React.MouseEventHandler
};
