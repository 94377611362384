import * as React from 'react';
import Card from 'components/basic/Card';
import ReactDOM from 'react-dom';
import classes from './style.module.css';

/**
 * Basic Component － A modal component with backdrop
 * @example <Modal className={extendClass}>Modal</Modal>
 */
export default function Modal({
  className, width = 'calc(100vw - 200px)', children, isShow, setShow,
}: Props) {

  return ReactDOM.createPortal(
    <div 
      className={classes.root}
      style={{
        opacity: isShow ? 1 : 0,
        pointerEvents: isShow ? 'auto' : 'none',
      }}
    >
      <Card 
        className={[classes.modal, className].join(' ')}
        style={{ width }}
      >
        {isShow && children}
      </Card>
      <div className={classes.backdrop} onClick={() => setShow(false)} />
    </div>,
    document.getElementById('root')!,
  );
}

type Props = {
  className?: string
  children?: React.ReactNode
  width?: string | number,
  isShow: boolean,
  setShow: Function
};
