import React from 'react';
import Circular from 'components/basic/Circular';
import classes from './style.module.css';

export default function CircularProcessbar({
  className,
  thicken = 2,
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  withText = true,
  value,
  color,
  max,
  width,
}: Props) {
  return (
    <div className={[classes.root, className].join(' ')} style={{height:width, width}}>
      <Circular
        width={width}
        height={width}
        thicken={thicken}
        text=""
        startDeg={0}
        deg={360}
        color={color}
        className={classes.backCircular}
        fontSize={12}
      />

      <Circular
        smooth
        width={width}
        height={width}
        thicken={thicken}
        text=""
        startDeg={0}
        deg={(value / max) * 360}
        color={color}
        className={classes.processCircular}
        fontSize={12}
      />
      {children}
    </div>
  );
}

type Props = {
  className?: string,
  thicken?: number
  children?: React.ReactNode
  withText?: boolean
  value: number
  max: number
  color?: string
  width: number
};
