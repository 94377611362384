import React from 'react';
import { Individual, IndividualType } from 'graphql/generated/graphql';
import Card from 'components/basic/Card';
import Space from 'components/basic/Space/index';
import { getIdvIcon, getIdvName } from 'utils/individual';
import Icon from 'components/basic/Icon';
import classes from './item.module.css';

export default function SpeakerListItem({
  individual, mode, onClick, audience,
}: ItemProps) {
  const name = getIdvName(individual, true);

  return (
    <Card className={[classes.root, audience ? classes.audience : " "].join(" ")} onClick={() => onClick && onClick(individual)}>
      <span className={classes.head}>
        {individual.type !== IndividualType.Custom
          && (
            <img
              alt="flag"
              src={getIdvIcon(individual)}
              width="35"
            />
          )}
        <Space h gap={10} />
        <div className={classes.title}>{name}</div>
      </span>
      {mode && (
        (mode === 'play' ? (
          <span className={['lds-facebook', classes.state].join(' ')}>
            <div />
            <div />
            <div />
            <div />
          </span>
        )
          : <Icon icon="pause" color={"var(--color-secondary)"} size={35} />)
      )}
    </Card>
  );
}

type ItemProps = {
  individual: Individual
  mode: 'play' | 'pause' | null
  onClick?: Function
  audience?: boolean
};
