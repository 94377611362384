export interface Theme {
  color: { primary: string; secondary: string; dark: string }
}

const theme: Theme = {
  color: {
    primary: '#1989FF',
    secondary: '#FFAE65',
    dark: '#1c2f3f',
  },
};

export default theme;
