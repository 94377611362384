import React, { useRef, useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Card from 'components/basic/Card';
import { ModuleInfo } from 'components/module/moduleInfo';
import { Theme } from 'theme';
import { DragAndDropContext } from 'contexts';
import { DIMENSION } from 'constant';

type ClassNames =
  | 'moduleRoot'
  | 'card'
  | 'mainView';

type StyleProps = {
  x: number
  y: number
  w: number
  h: number
  placeDim: { w: number; h: number }
  fade: boolean
};

const useStyles = createUseStyles<ClassNames, StyleProps, Theme>({
  moduleRoot: {
    position: 'absolute',
    padding: 5,
    'opacity': ({ fade }) => (fade ? '0.2' : '1'),
    '-webkit-transition': '0.3s',
    transition: ['0.3s opacity, 0.3s left, 0.3s top'],
    textAlign: 'center',
  },
  card: () => ({
    width: '100%',
    height: '100%',
  }),
  mainView: {
    marginTop: 0,
    width: '100%',
  },
});

export default function AudienceBaseFrame({ module, highlight }: Props) {
  const { placeDim } = useContext(DragAndDropContext);

  const ref = useRef(null);

  const theme = useTheme<Theme>();

  const { Target } = ModuleInfo[module.type];
  const classes = useStyles({
    x: module.pos?.x,
    y: module.pos?.y,
    w: module.dim?.w,
    h: module.dim?.h,
    placeDim,
    theme,
    fade:
      highlight !== undefined && highlight !== module._id,
  });

  const editDispatcher = () => { };

  return (
    <div 
      className={classes.moduleRoot} 
      ref={ref}
      style={highlight === module._id ? {  
        width:  module.dim.w * DIMENSION.A_MODULE_W +'vh',
        height: module.dim.h * DIMENSION.A_MODULE_H + 'vh',
        transform: module.dim.h === 1 ? "translate(-50%, -50%) scale(1.5)" : "translate(-50%, -50%)",
        left:   "50%",
        top: "50%",
        zIndex: 1000
      } : {  
        width:  module.dim.w * DIMENSION.A_MODULE_W +'vh',
        height: module.dim.h * DIMENSION.A_MODULE_H + 'vh',
        left:  module.pos.x  * DIMENSION.A_MODULE_W + 'vh',
        top: module.pos?.y * DIMENSION.A_MODULE_H +'vh'
      }}
    >
      <Card className={classes.card}>
        <div className={classes.mainView}>
          <Target
            realDim={{ w: (placeDim.w / 4) * module.dim.w, h: (placeDim.h / 2) * module.dim.h }}
            isEdit={false}
            setEdit={() => { }}
            data={module.data}
            setEditData={editDispatcher}
            dispatchUpdateModule={() => { }}
            id={module._id}
            audience
          />
        </div>
      </Card>
    </div>
  );
}

type Props = {
  module: any
  highlight: string | undefined
};
