import React, { useContext } from 'react';
import Icon from 'components/basic/Icon';
import { DragAndDropContext } from 'contexts';
import { ModuleInfo } from 'components/module/moduleInfo';
import Space from 'components/basic/Space';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useRoomShelfQuery, useModuleQuery } from 'graphql/generated/graphql';
import classes from './style.module.css';
import { on } from 'events';

function ShelfItem({ id, module, hideList }: ItemProps) {
  const { data, loading } = useModuleQuery({ variables: { MID: id } });
  const { setDragStatus } = useContext(DragAndDropContext);

  return (
    <div
      className={classes.item}
      onMouseDown={() => {
        if (loading || !data) return;
        setDragStatus({
          action: 'unshelf',
          type: module.type,
          module,
          id,
          dim: module.dim,
        });
      }}
      onTouchStart={() => {
        if (loading || !data) return;
        hideList(true);
        setDragStatus({
          action: 'unshelf',
          type: module.type,
          module,
          id,
          dim: module.dim,
        });
      }}
    onTouchEnd={() => {
      hideList(false);
    }}
    >
      {!loading && data && (
        <>
          <span className={classes.leftPart}>
            <Icon icon={ModuleInfo[data!.module!.type].icon} size={30} color={"var(--color-primary)"} />
            <Space h gap={10} />
            {ModuleInfo[data!.module!.type].name}
          </span>
          <span className={classes.rightPart}>{moment(data!.module!.lastUpdateTime).fromNow()}</span>
        </>
      )}
    </div>
  );
}

type ItemProps = { id: string, module: any, hideList: React.Dispatch<React.SetStateAction<boolean>> };

export default function Shelf() {
  const { dragStatus } = useContext(DragAndDropContext);

  const { roomId } = useParams<{ roomId: string }>();

  const { loading, data } = useRoomShelfQuery({ variables: { RID: roomId } });
  const [hide, setHide] = React.useState(false);

  return (
    <div className={classes.root}>
      <div 
        className={classes.button}
        style={{
          transform: dragStatus.action === 'null' ? '' : 'translate(-200px)',
        }}
      >
        <div className={classes.touchArea}>
          {!loading && data && data!.room!.shelfList!.length > 0
            && (
              <div 
                className={classes.list}
                style={{
                  opacity: hide ? '0' : '1',
                }}
              >
                <div className={classes.listTitle}>Shelf</div>
                {data.room?.shelfList.map((s) => <ShelfItem id={s._id} key={s._id} module={s} hideList={setHide}/>)}
              </div>
            )}
        </div>
        <span className={classes.count}>
          {loading ? 0 : data?.room?.shelfList.length}
        </span>
        <Icon className={classes.shelfIcon} icon="shelf" size={60} />
      </div>
    </div>
  );
}
