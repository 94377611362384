import { Individual, IndividualType } from 'graphql/generated/graphql';
import { getCountry } from 'utils/countries/index';
import icon from 'assets/logo.svg';

export function getIdvName(individual: Individual, shortAsPossible: boolean) {
  if (individual.type === IndividualType.Custom) return individual.name!;
  if (individual.type === IndividualType.Org) return individual.individualCode!;
  if (individual.individualCode) {
    const country = getCountry(individual.individualCode);
    if (country) {
      try {
        return shortAsPossible ? country.abbr[0] ? country.abbr[0] : country.name : country.name;
      } catch (err) {
        // console.log('ERR', individual, country);
        return individual.individualCode;
      }
    }
  }
  return '';
}

export function getIdvIcon(individual: Individual | null | undefined) {
  if (!individual) return icon;
  if (individual.type === IndividualType.Custom) return icon;
  if (individual.type === IndividualType.Org && individual.individualCode) return `/flag/${individual.individualCode}.svg`;
  if (individual.individualCode) {
    const country = getCountry(individual.individualCode);
    if (country && country.altIcon) return country.altIcon!;
    return `https://hatscripts.github.io/circle-flags/flags/${individual.individualCode.toLowerCase()}.svg`;
  }
  return icon;
}

export function individualCmp(a: Individual, b: Individual) {
  if (getIdvName(a, true) < getIdvName(b, true)) { return -1; }
  if (getIdvName(a, true) > getIdvName(b, true)) { return 1; }
  return 0;
}

const org = ['EU'];
export function isOrg(code: string) {
  if (code && org.includes(code)) return true;
  return false;
}
