import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classes from './style.module.css';

/**
 * Basic Component － Floated Tooltip
 * @example <Tooltip className={extendClass} pos="top">...</Tooltip>
 */
export default function Tooltip({ className, children, pos = 'top' }: Props) {

  return (
    <span className={[classes.root, className, classes[pos], 'tooltip'].join(' ')}>
      <span className={classes.container}>
        {children}
      </span>
      <svg viewBox="0 0 10 10" width="15px" fill="#FFFFFF">
        <path d="M0 0l5 5 5-5H0z" />
      </svg>
    </span>
  );
}

type Props = {
  className?: string
  children?: React.ReactNode
  pos?: 'left' | 'right' | 'top' | 'bottom'
};
