import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import OutlineButton from 'components/basic/OutlineButton/index';
import Space from 'components/basic/Space/index';
import {
  BatchIndividualCreatedSubscription,
  BatchIndividualCreatedSubscriptionVariables,
  BatchIndividualDeletedSubscription,
  BatchIndividualDeletedSubscriptionVariables,
  BatchIndividualUpdatedSubscription,
  BatchIndividualUpdatedSubscriptionVariables,
  Individual, RollCallIndividual, RollCallState,
  useRollCallUpdatedSubscription, useRoomIndividualsQuery, useSubmitRollCallMutation, useSubmitRollCallOfAllMutation,
} from 'graphql/generated/graphql';
import { useParams } from 'react-router-dom';
import { individualCmp } from 'utils/individual';
import {
  BATCH_INDIVIDUAL_CREATED_SUBSCRIPTION, BATCH_INDIVIDUAL_DELETED_SUBSCRIPTION, BATCH_INDIVIDUAL_UPDATED_SUBSCRIPTION,
} from 'graphql/subscriptions';
import RollCallListItem from './Item';
import classes from './style.module.css';

export default function RollCallList({
  id, record, audience, height = 'calc(100vh - 260px)',
}: Props) {
  const { roomId } = useParams<{ roomId: string }>();

  useRollCallUpdatedSubscription({
    variables: {
      RCID: id,
    },
    skip: id === '',
  });

  const { data, subscribeToMore } = useRoomIndividualsQuery({ variables: { RID: roomId } });

  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubIndividualCreated = subscribeToMore<BatchIndividualCreatedSubscription, BatchIndividualCreatedSubscriptionVariables>({
      document: BATCH_INDIVIDUAL_CREATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const createdIndividuals = subscriptionData.data.batchIndividualCreated;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            individuals: R.concat(prev.room.individuals, createdIndividuals),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubIndividualCreated);

    const unSubIndividualUpdated = subscribeToMore<BatchIndividualUpdatedSubscription, BatchIndividualUpdatedSubscriptionVariables>({
      document: BATCH_INDIVIDUAL_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedIndividuals = subscriptionData.data.batchIndividualUpdated;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            individuals: R.reduce((acc, elem) => {
              const existingIndex = R.findIndex(R.propEq('_id', elem._id))(acc);

              if (existingIndex === -1) return acc;
              return R.update(existingIndex, elem, acc);
            }, prev.room.individuals, updatedIndividuals),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubIndividualUpdated);

    const unSubIndividualDeleted = subscribeToMore<BatchIndividualDeletedSubscription, BatchIndividualDeletedSubscriptionVariables>({
      document: BATCH_INDIVIDUAL_DELETED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const deletedIndividuals = subscriptionData.data.batchIndividualDeleted;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            individuals: R.without(deletedIndividuals, prev.room.individuals),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubIndividualDeleted);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  const [submitRollCall] = useSubmitRollCallMutation();
  const [submitRollCallAll] = useSubmitRollCallOfAllMutation();

  function updateState(idv: Individual, state: RollCallState) {
    submitRollCall({ variables: { IID: idv._id, RCID: id, state } });
  }

  const individuals = useMemo(() => {
    if (data?.room?.individuals) {
      return [...data.room.individuals].sort(individualCmp);
    }
    return null;
  }, [data?.room?.individuals]);

  function getItem(i: Individual) {
    const result = record.find((r) => r.individual?._id === i._id);
    return result
      ? (
        <RollCallListItem
          key={i._id}
          individual={result.individual!}
          state={result.state}
          updateState={(idv: Individual, state: RollCallState) => updateState(idv, state)}
          audience={audience}
        />
      )
      : (
        <RollCallListItem
          key={i._id}
          individual={i}
          state={null}
          updateState={(idv: Individual, state: RollCallState) => updateState(idv, state)}
          audience={audience}
        />
      );
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Roll Call
        <Space h gap={10} />
        {!audience && (
          <OutlineButton
            title="All Present"
            color={"var(--color-primary)"}
            onClick={() => submitRollCallAll({ variables: { RCID: id, state: RollCallState.Present } })}
          />
        )}
      </div>
      <div className={classes.list} style={{height}}>
        {individuals?.map((i) => getItem(i))}
      </div>
    </div>
  );
}

type Props = {
  record: RollCallIndividual[],
  id: string,
  audience?: boolean,
  height?: string | number
};
