import React, { useEffect, useRef, useState } from 'react';
import { Alignment } from 'declarations';
import { DIMENSION } from 'constant';
import classes from './style.module.css';

export default function FitText({
  width, height, className, alignment, children,
}: Props) {
  const min = 0;
  const max = 100;
  const ref = useRef<HTMLDivElement>(null);
  const fontsize = useRef<number>(0);

  // useEffect(() => {
  //   setFontSize(10);
  //   setCalculating(true);
  // }, [ref]);

  // useEffect(() => {
  //   setFontSize((max + min) / 2);
  //   setUpperBound(max);
  //   setLowerBound(min);
  //   setCalculating(true);
  // }, [width, height, children]);

  // useEffect(() => {
  //   if (!isCalculating) return;
  //   if (ref.current !== null) {
  //     const exceedW = ref.current.offsetWidth - width;
  //     const exceedH = ref.current.offsetHeight - height;

  //     if (exceedW > 0 || exceedH > 0) {
  //       setUpperBound(fontSize);
  //       setFontSize((fontSize + lowerBound) / 2);
  //     } else if (exceedW < -20 && exceedH < -20) {
  //       setLowerBound(fontSize);
  //       setFontSize((fontSize + upperBound) / 2);
  //     } else {
  //       setCalculating(false);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fontSize, isCalculating]);

  useEffect(()=>{
    if (ref.current !== null) {
      let upperBound = max
      let lowerBound = min
      if(fontsize.current === 0){ // initial
        fontsize.current = (max + min)/2
        for (let index = 0; index < DIMENSION.FIT_TEXT_MAX_EPECHO; index++) {
          ref.current.style.fontSize = `${fontsize.current}px`;
          const exceedW = ref.current.offsetWidth - width;
          const exceedH = ref.current.offsetHeight - height;
          if (exceedW > 0 || exceedH > 0) {
            upperBound = (fontsize.current);
            fontsize.current = ((fontsize.current + lowerBound) / 2);
          } else if (exceedW < -20 && exceedH < -20) {
            lowerBound = (fontsize.current);
            fontsize.current = ((fontsize.current + upperBound) / 2);
          } else {
            break
            // setCalculating(false);
          };
        }
      } else {
        for (let index = 0; index < DIMENSION.FIT_TEXT_MAX_EPECHO; index++) {
          ref.current.style.fontSize = `${fontsize.current}px`;
          const exceedW = ref.current.offsetWidth - width;
          const exceedH = ref.current.offsetHeight - height;
          if (exceedW > 0 || exceedH > 0) {
            upperBound = (fontsize.current);
            fontsize.current = fontsize.current - DIMENSION.FIT_TEXT_SLIGHT_STEP;
          } else if (exceedW < -20 && exceedH < -20) {
            lowerBound = (fontsize.current);
            fontsize.current = fontsize.current + DIMENSION.FIT_TEXT_SLIGHT_STEP;
          } else {
            break
            // setCalculating(false);
          };
        }
      }
      
    }
  },[children, width, height])

  // useEffect(() => {
  //   if (null !== ref && null !== ref.current) {
  //     const errorW = ref.current.offsetWidth - width
  //     const errorH = ref.current.offsetHeight - height
  //     if (errorW < 30 && errorH < 30 && (errorH > -30 || errorW > -30)) return

  //     if (errorW > 10 || errorH > 10) setFontSize((fontSize + min) / 2)
  //     else if (errorW < -30 && errorH < -30)
  //       setFontSize(fontSize + Math.min(10 / children.length, 2))
  //   }
  // }, [fontSize])

  return (
    <>
      <div ref={ref} className={[classes.root, className].join(' ')}>
        {children.split('\n').map((c: React.ReactNode, i: number) => (
          // eslint-disable-next-line
          <React.Fragment key={i}>
            {i !== 0 && <br />}
            {c}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

type Props = {
  className?: string
  alignment?: Alignment
  children: string
  width: number
  height: number
};
