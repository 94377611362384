import * as React from 'react';
import classes from './style.module.css';

/**
 * Basic Component － A card view
 * @example <Card className={extendClass}}>Card</Card>
 */
export default function Card({ className, children, style, onClick }: Props) {
  return <span className={[classes.root, className].join(' ')} style={style} onClick={onClick}>{children}</span>;
}

type Props = {
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler
};
