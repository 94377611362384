/* esline disable import/no-extraneous-dependencies */
import gql from 'graphql-tag';

export const TALLY_SHEET_ITEM_FRAGMENT = gql`
  fragment TallySheetItem on TallySheetItem {
    name
  }
`;

export const HOST_FRAGMENT = gql`
    fragment Host on Host {
      email
      token
    }
`;

export const INDIVIDUAL_FRAGMENT = gql`
    fragment Individual on Individual {
      type
      name
      iconURL
      individualCode
      tallySheet {
        item {
          _id
          ...TallySheetItem
        }
        value
      }
    }
    ${TALLY_SHEET_ITEM_FRAGMENT}
`;

export const ROLL_CALL_RECORDS_FRAGMENT = gql`
  fragment RollCallRecords on RollCallIndividual {
    state
    individual {
      _id
      ...Individual
    }
  }
  ${INDIVIDUAL_FRAGMENT}
`;

export const ROLL_CALL_FRAGMENT = gql`
    fragment RollCall on RollCall {
      _id
      time
      records {
        ...RollCallRecords
      }
      finished
    }
    ${ROLL_CALL_RECORDS_FRAGMENT}
`;

export const TIMER_STATE_FRAGMENT = gql`
  fragment TimerState on TimerState {
    totalTime
    elapsedTime
    startTime
  }
`;

export const VOTES_FRAGMENT = gql`
  fragment Votes on Votes {
    for
    against
    abstain
  }
`;

export const SPEAKER_YIELD_FRAGMENT = gql`
  fragment SpeakerYield on SpeakerYield {
    type
    individual{
      _id
      ...Individual
    }
  }
  ${INDIVIDUAL_FRAGMENT}
`;

export const MOTION_TOTAL_TIME_FRAGMENT = gql`
  fragment MotionTotalTime on MotionTotalTime {
    sec
    isDisplay
  }
`;

export const MOTION_SPEAKER_TIME_FRAGMENT = gql`
  fragment MotionSpeakerTime on MotionSpeakerTime {
    sec
    isDisplay
  }
`;

export const AMENDMENT_MODULE_DATA_FRAGMENT = gql`
    fragment AmendmentModuleData on AmendmentModuleData {
      amendmentType: type
      clauses
      content
      submitters{
        _id
        type
        name
        iconURL
        individualCode
      }
    }
`;

export const MOTION_MODULE_DATA_FRAGMENT = gql`
    fragment MotionModuleData on MotionModuleData {
      type
      customMotionType
      topic
      voteStatus
      totalTime {
        ...MotionTotalTime
      }
      speakerTime {
        ...MotionSpeakerTime
      }
      submitter{
        _id
        type
        name
        iconURL
        individualCode
      }
    }
    ${MOTION_TOTAL_TIME_FRAGMENT}
    ${MOTION_SPEAKER_TIME_FRAGMENT}
`;

export const TIMER_MODULE_DATA_FRAGMENT = gql`
    fragment TimerModuleData on TimerModuleData {
      title
      state {
        ...TimerState
      }
      shouldAlert
    }
    ${TIMER_STATE_FRAGMENT}
`;

export const SPEAKERS_LIST_MODULE_DATA_FRAGMENT = gql`
    fragment SpeakersListModuleData on SpeakersListModuleData {
      title
      timerState {
        ...TimerState
      }
      speakersListTotalTime: totalTime
      speakersListSpeakerTime: speakerTime
      speakersListTotalElapsedTime: totalElapsedTime
      stance
      yield {
        ...SpeakerYield
      }
      currentSpeaker{
        _id
        ...Individual
      }
      list{
        _id
        ...Individual
      }
    }
    ${TIMER_STATE_FRAGMENT}
    ${SPEAKER_YIELD_FRAGMENT}
    ${INDIVIDUAL_FRAGMENT}
`;

export const VOTE_MODULE_DATA_FRAGMENT = gql`
    fragment VoteModuleData on VoteModuleData {
      title
      votes {
        ...Votes
      }
      voteStatus
      isVoteShown
    }
    ${VOTES_FRAGMENT}
`;

export const LINK_MODULE_DATA_FRAGMENT = gql`
    fragment LinkModuleData on LinkModuleData {
      title
      link
    }
`;

export const ROLL_CALL_MODULE_DATA_FRAGMENT = gql`
    fragment RollCallModuleData on RollCallModuleData {
      rollCall {
        ...RollCall
      }
    }
    ${ROLL_CALL_FRAGMENT}
`;

export const TEXT_MODULE_DATA_FRAGMENT = gql`
    fragment TextModuleData on TextModuleData {
      content
    }
`;

export const MODULE_FRAGMENT = gql`
    fragment Module on Module {
      type
      status
      pos {
        x
        y
      }
      dim {
        w
        h
      }
      room {
        _id
      }
      display {
        _id
      }
      lastUpdateTime
      ...on AmendmentModule {
        data {
          ...AmendmentModuleData
        }
      }
      ... on MotionModule {
        data {
          ...MotionModuleData
        }
      }
      ...on TimerModule {
        data {
          ...TimerModuleData
        }
      }
      ...on SpeakersListModule {
        data {
          ...SpeakersListModuleData
        }
      }
      ...on VoteModule {
        data {
          ...VoteModuleData
        }
      }
      ...on LinkModule {
        data {
          ...LinkModuleData
        }
      }
      ...on RollCallModule {
        data {
          ...RollCallModuleData
        }
      }
      ...on TextModule {
        data {
          ...TextModuleData
        }
      }
    }
    ${AMENDMENT_MODULE_DATA_FRAGMENT}
    ${MOTION_MODULE_DATA_FRAGMENT}
    ${TIMER_MODULE_DATA_FRAGMENT}
    ${SPEAKERS_LIST_MODULE_DATA_FRAGMENT}
    ${VOTE_MODULE_DATA_FRAGMENT}
    ${LINK_MODULE_DATA_FRAGMENT}
    ${ROLL_CALL_MODULE_DATA_FRAGMENT}
    ${TEXT_MODULE_DATA_FRAGMENT}
`;

export const DISPLAY_FRAGMENT = gql`
    fragment Display on Display {
      name
      highlightModule{
        _id
        ...Module
      }
      modules {
        _id
        ...Module
      }
    }
    ${MODULE_FRAGMENT}
`;

export const ROOM_INFO_FRAGMENT = gql`
  fragment RoomInfo on Room {
    name
    joinCode
    expireTime
    timeExpired
    currentDisplay{
      _id
    }
  }
`;

export const ROOM_DISPLAY_INFO_FRAGMENT = gql`
  fragment RoomDisplayInfo on Room {
    currentDisplay {
        _id
        ...Display
      }
      displays {
        _id
        ...Display
      }
  }
  ${DISPLAY_FRAGMENT}
`;

export const ROOM_INDIVIDUALS_FRAGMENT = gql`
  fragment RoomIndividuals on Room {
    individuals {
      _id
      ...Individual
    }
  }
  ${INDIVIDUAL_FRAGMENT}
`;

export const ROOM_TALLY_SHEET_FRAGMENT = gql`
  fragment RoomTallySheet on Room {
    tallySheetItems {
      _id
      ...TallySheetItem
    }
  }
  ${TALLY_SHEET_ITEM_FRAGMENT}
`;

export const ROOM_SHELF_FRAGMENT = gql`
  fragment RoomShelf on Room {
    shelfList {
      _id
      ...Module
    }
  }
  ${MODULE_FRAGMENT}
`;

export const ROOM_ARCHIVE_FRAGMENT = gql`
  fragment RoomArchive on Room {
    archiveList {
      _id
      ...Module
    }
  }
  ${MODULE_FRAGMENT}
`;

export const ROOM_ROLL_CALL_FRAGMENT = gql`
  fragment RoomRollCall on Room {
    rollCalls {
      _id
      ...RollCall
    }
  }
  ${ROLL_CALL_FRAGMENT}
`;

export const ROOM_FRAGMENT = gql`
    fragment Room on Room {
      ...RoomInfo
      ...RoomDisplayInfo
      ...RoomIndividuals
      ...RoomRollCall
      ...RoomArchive
      ...RoomShelf
    }
    ${ROOM_INFO_FRAGMENT}
    ${ROOM_DISPLAY_INFO_FRAGMENT}
    ${ROOM_INDIVIDUALS_FRAGMENT}
    ${ROOM_ROLL_CALL_FRAGMENT}
    ${ROOM_ARCHIVE_FRAGMENT}
    ${ROOM_SHELF_FRAGMENT}
`;
