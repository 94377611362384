import { TimerState, UpdateSpeakersListModuleInputType } from 'graphql/generated/graphql';
import { useTimer } from 'hooks/useTimer';

export function useSpeakersListTimer(
  timerState: TimerState,
  totalElapsedTime: number,
  totalTime: number,
  setSpeakersListData: (newDate: UpdateSpeakersListModuleInputType) => void,
) {
  const timer = useTimer(timerState, (newState) => {
    setSpeakersListData({
      timerState: {
        startTime: timerState.startTime,
        elapsedTime: timerState.elapsedTime,
        totalTime: timerState.totalTime,
        ...newState,
      },
    });
  });

  const play = () => {
    timer.play();
  };

  const pause = () => {
    timer.pause();
  };

  const timerElapsed = (timer.totalTime - timer.remainTime);

  const reset = () => {
    timer.reset();
    setSpeakersListData({
      totalElapsedTime: totalElapsedTime + Math.round(timerElapsed),
    });
  };

  const totalRemainTime = totalTime - totalElapsedTime - timerElapsed;

  return {
    speakerRemainTime: timer.remainTime,
    speakerTotalTime: timer.totalTime,
    status: timer.status,
    totalRemainTime,
    totalTime,
    play,
    pause,
    reset,
  };
}
