import React, { MouseEventHandler } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'theme';

import { ReactComponent as Search } from 'assets/icon/search.svg';
import { ReactComponent as Display } from 'assets/icon/display.svg';
import { ReactComponent as Committee } from 'assets/icon/committee.svg';
import { ReactComponent as Text } from 'assets/icon/text.svg';
import { ReactComponent as Vote } from 'assets/icon/vote.svg';
import { ReactComponent as Timer } from 'assets/icon/timer.svg';
import { ReactComponent as Info } from 'assets/icon/info.svg';
import { ReactComponent as Handle } from 'assets/icon/handle.svg';
import { ReactComponent as Edit } from 'assets/icon/edit.svg';
import { ReactComponent as Done } from 'assets/icon/done.svg';
import { ReactComponent as Clear } from 'assets/icon/clear.svg';
import { ReactComponent as Add } from 'assets/icon/add.svg';
import { ReactComponent as Play } from 'assets/icon/play.svg';
import { ReactComponent as Pause } from 'assets/icon/pause.svg';
import { ReactComponent as Stop } from 'assets/icon/stop.svg';
import { ReactComponent as MoreTime } from 'assets/icon/more_time.svg';
import { ReactComponent as Archive } from 'assets/icon/archive.svg';
import { ReactComponent as PinCode } from 'assets/icon/pin_code.svg';
import { ReactComponent as Reset } from 'assets/icon/reset.svg';
import { ReactComponent as Shelf } from 'assets/icon/shelf.svg';
import { ReactComponent as Status } from 'assets/icon/status.svg';
import { ReactComponent as Focus } from 'assets/icon/focus.svg';
import { ReactComponent as Table } from 'assets/icon/table.svg';
import { ReactComponent as RollCall } from 'assets/icon/rollcall.svg';
import { ReactComponent as History } from 'assets/icon/history.svg';
import { ReactComponent as Expand } from 'assets/icon/expand.svg';
import { ReactComponent as Bracket } from 'assets/icon/bracket.svg';
import { ReactComponent as Minus } from 'assets/icon/minus.svg';
import { ReactComponent as Link } from 'assets/icon/link.svg';
import { ReactComponent as DropDown } from 'assets/icon/drop_down.svg';
import { ReactComponent as Check } from 'assets/icon/check.svg';
import { ReactComponent as Hand } from 'assets/icon/hand.svg';
import { ReactComponent as Amendment } from 'assets/icon/amendment.svg';
import { ReactComponent as Motion } from 'assets/icon/motion.svg';
import { ReactComponent as Eye } from 'assets/icon/eye.svg';
import { ReactComponent as Gavel } from 'assets/icon/gavel.svg';
import { ReactComponent as Question } from 'assets/icon/question.svg';
import { ReactComponent as Yield } from 'assets/icon/yield.svg';
import { ReactComponent as Next } from 'assets/icon/next.svg';
import { ReactComponent as Email } from 'assets/icon/email.svg';
import { ReactComponent as List } from 'assets/icon/list.svg';
import { ReactComponent as Toolbox } from 'assets/icon/toolbox.svg';
import { ReactComponent as Warning } from 'assets/icon/warning.svg';
import { ReactComponent as Copy } from 'assets/icon/copy.svg';

type ClassNames = 'root';

type StyleProps = {
  color: string
  size: number
};

const useStyles = createUseStyles<ClassNames, StyleProps, Theme>({
  root: {
    display: 'inline-block',
    fill: ({ color }:{color:string}) => color,
    width: ({ size }) => size,
    height: ({ size }) => size,
  },
});

/**
 * Basic Component － Icon
 * @example <Icon icon="display" color="black", size={12}  />
 */
export default function Icon({
  className, icon, color = 'black', size = 12, onClick,
}: Props) {
  const classes = useStyles({ color, size });
  switch (icon) {
    case 'search':
      return <Search onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'display':
      return <Display onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'committee':
      return <Committee onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'text':
      return <Text onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'vote':
      return <Vote onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'timer':
      return <Timer onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'info':
      return <Info onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'handle':
      return <Handle onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'edit':
      return <Edit onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'done':
      return <Done onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'clear':
      return <Clear onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'add':
      return <Add onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'play':
      return <Play onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'pause':
      return <Pause onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'stop':
      return <Stop onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'more_time':
      return <MoreTime onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'archive':
      return <Archive onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'pin_code':
      return <PinCode onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'reset':
      return <Reset onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'shelf':
      return <Shelf onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'status':
      return <Status onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'focus':
      return <Focus onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'table':
      return <Table onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'rollcall':
      return <RollCall onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'history':
      return <History onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'expand':
      return <Expand onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'bracket':
      return <Bracket onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'minus':
      return <Minus onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'link':
      return <Link onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'drop_down':
      return <DropDown onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'check':
      return <Check onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'hand':
      return <Hand onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'amendment':
      return <Amendment onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'motion':
      return <Motion onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'eye':
      return <Eye onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'gavel':
      return <Gavel onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'question':
      return <Question onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'next':
      return <Next onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'yield':
      return <Yield onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'email':
      return <Email onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'list':
      return <List onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'toolbox':
      return <Toolbox onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'warning':
      return <Warning onClick={onClick} className={[classes.root, className].join(' ')} />;
    case 'copy':
      return <Copy onClick={onClick} className={[classes.root, className].join(' ')} />;
    default:
      return <></>;
  }
}

type Props = {
  className?: string
  icon: string
  color?: string
  size?: number,
  onClick?: MouseEventHandler
};
