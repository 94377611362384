import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'theme';
import { ICoreProps } from 'components/module/moduleDefine';
import FitText from 'components/basic/FitText';
import { TextModuleData } from 'graphql/generated/graphql';
import { DragAndDropContext } from 'contexts';
import classes from './style.module.css';

export default function MunativeText({
  data,
  setEditData: editDispatcher,
  isEdit,
  realDim,
}: ICoreProps<TextModuleData>) {
  // individual data area
  const [content, setContent] = useState('val1');

  const {placeDim} = useContext(DragAndDropContext);

  // Initialize/Update the module
  useEffect(() => {
    setContent(data.content);
  }, [data]);

  // When the editing done
  useEffect(() => {
    if (isEdit) {
      editDispatcher({
        text: {
          content,
        },
      });
    }
  }, [isEdit, content, editDispatcher]);

  return (
    <>
      {/* Default page */}
      <div
        className="defaultView"
        style={{ height: placeDim.h/2 - 30, position: 'relative', overflow: 'hidden' }}
      >
        <FitText className={classes.content} width={realDim.w - 40} height={realDim.h - 40}>
          {content}
        </FitText>
      </div>

      {/* Edit page */}
      <div className={['editView', isEdit ? 'active' : ''].join(' ')}>
        <textarea
          className={classes.input}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
    </>
  );
}
