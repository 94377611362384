import React, {
  useState, useEffect, useRef, useMemo, useCallback,
} from 'react';
import * as R from 'ramda';
import Card from 'components/basic/Card';
import Space from 'components/basic/Space';
import Input from 'components/basic/Input';
import { Country } from 'utils/countries/country-data';
import { getAllCountries } from 'utils/countries/index';
import {
  Individual, IndividualType,
  useClearAllIndividualMutation, useCreateCustomIndividualMutation,
  useCreateIndividualMutation, useDeleteIndividualMutation, useRoomIndividualsQuery,
  BatchIndividualCreatedSubscription,
  BatchIndividualCreatedSubscriptionVariables,
  BatchIndividualUpdatedSubscription,
  BatchIndividualUpdatedSubscriptionVariables,
  BatchIndividualDeletedSubscription,
  BatchIndividualDeletedSubscriptionVariables,
} from 'graphql/generated/graphql';
import { useParams } from 'react-router-dom';
import {
  BATCH_INDIVIDUAL_CREATED_SUBSCRIPTION,
  BATCH_INDIVIDUAL_UPDATED_SUBSCRIPTION,
  BATCH_INDIVIDUAL_DELETED_SUBSCRIPTION,
} from 'graphql/subscriptions';
import { getIdvIcon, getIdvName } from 'utils/individual';
import Button from 'components/basic/Button';
import classes from './style.module.css';

function IndividualItem({ individual, selected }: ItemProps) {
  const { roomId } = useParams<{ roomId: string }>();

  const [createCustomIndividual, { loading: createCustomLoading }] = useCreateCustomIndividualMutation();
  const [createIndividual, { loading: createLoading }] = useCreateIndividualMutation();
  const [deleteIndividual, { loading: deleteLoading }] = useDeleteIndividualMutation();

  const [disabled, setDisabled] = useState(false);

  const action = useRef(false);

  // Todo: prevent Infi Loop
  useEffect(() => {
    if (!createCustomLoading && !createLoading && !deleteLoading && action.current) {
      action.current = false;
    }
  }, [createCustomLoading, createLoading, deleteLoading]);

  function handleIndividualClick() {
    action.current = true;
    if (selected) {
      deleteIndividual({ variables: { IID: individual._id } });
    } else if (individual.type === IndividualType.Custom) {
      createCustomIndividual({ variables: { RID: roomId, name: individual.name!, iconURL: 'none' } });
    } else {
      createIndividual({ variables: { RID: roomId, type: individual.type, individualCode: individual.individualCode! } });
    }
    setDisabled(true);
  }

  useEffect(() => {
    if (!createCustomLoading) setDisabled(false);
  }, [createCustomLoading]);

  if (individual.type === IndividualType.Country || individual.type === IndividualType.Org) {
    return (
      <Card 
        className={classes.item} 
        style={{
          filter: disabled ? 'brightness(.8)' : 'brightness(1)',
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        onClick={() => handleIndividualClick()}
      >
        <img alt="flag" src={getIdvIcon(individual)} style={{height:35, width:35, backgroundColor: "lightgray", borderRadius: "100%"}} />
        <Space h gap={10} />
        {getIdvName(individual, true)}
      </Card>
    );
  }
  return (
    <Card className={classes.item} onClick={() => handleIndividualClick()}>
      {selected ? individual.name : `Add "${individual.name}"`}
    </Card>
  );
}

type ItemProps = { individual: Individual, selected: boolean };

function IndividualSelector({ roomId }: SelectorProps) {
  const [queryString, setQueryString] = useState('');

  const { data, subscribeToMore } = useRoomIndividualsQuery({ variables: { RID: roomId } });

  const [clearAllIndividual] = useClearAllIndividualMutation({ variables: { RID: roomId } });

  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubIndividualCreated = subscribeToMore<BatchIndividualCreatedSubscription, BatchIndividualCreatedSubscriptionVariables>({
      document: BATCH_INDIVIDUAL_CREATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const createdIndividuals = subscriptionData.data.batchIndividualCreated;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            individuals: R.concat(prev.room.individuals, createdIndividuals),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubIndividualCreated);

    const unSubIndividualUpdated = subscribeToMore<BatchIndividualUpdatedSubscription, BatchIndividualUpdatedSubscriptionVariables>({
      document: BATCH_INDIVIDUAL_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedIndividuals = subscriptionData.data.batchIndividualUpdated;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            individuals: R.reduce((acc, elem) => {
              const existingIndex = R.findIndex(R.propEq('_id', elem._id))(acc);

              if (existingIndex === -1) return acc;
              return R.update(existingIndex, elem, acc);
            }, prev.room.individuals, updatedIndividuals),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubIndividualUpdated);

    const unSubIndividualDeleted = subscribeToMore<BatchIndividualDeletedSubscription, BatchIndividualDeletedSubscriptionVariables>({
      document: BATCH_INDIVIDUAL_DELETED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const deletedIndividuals = subscriptionData.data.batchIndividualDeleted;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            individuals: R.without(deletedIndividuals, prev.room.individuals),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubIndividualDeleted);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  const candidateMatch = useCallback((c: Country) => (
    c.name.toLowerCase().indexOf(queryString.toLowerCase()) > -1
    || c.alpha2.toLowerCase().indexOf(queryString.toLowerCase()) > -1
    || c.alpha3.toLowerCase().indexOf(queryString.toLowerCase()) > -1
  ), [queryString]);

  const unselectedIndividuals = useMemo(() => getAllCountries()
    .filter(candidateMatch)
    .filter(
      (a) => data?.room?.individuals.findIndex((i) => i.individualCode === a.alpha2) === -1,
    ), [candidateMatch, data?.room?.individuals]);

  const selectedIndividuals = useMemo(() => data?.room?.individuals
    .slice()
    .sort((a, b) => {
      if (getIdvName(a, true) < getIdvName(b, true)) { return -1; }
      if (getIdvName(a, true) > getIdvName(b, true)) { return 1; }
      return 0;
    }) || [], [data?.room?.individuals]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Input icon="search" value={queryString} onChange={(e) => setQueryString(e.target.value)} />
        <Space v gap={20} />
        <div className={classes.list}>
          {unselectedIndividuals.map((c) => (
            <IndividualItem
              key={c.alpha3}
              selected={false}
              individual={{
                _id: '_', type: IndividualType.Country, individualCode: c.alpha2, tallySheet: [],
              }}
            />
          ))}
          {queryString !== '' && (
            <IndividualItem
              selected={false}
              individual={{
                _id: '_', type: IndividualType.Custom, name: queryString, tallySheet: [],
              }}
            />
          )}

        </div>
      </Card>
      <Card className={classes.card}>
        <div className={classes.selectedTitle}>
          Selected
          <Button onClick={() => clearAllIndividual()}>Clear</Button>
        </div>
        <div className={classes.list}>
          {selectedIndividuals.map((i) => <IndividualItem key={i._id} selected individual={i} />)}
        </div>
      </Card>
    </div>
  );
}

type SelectorProps = { roomId: string };

export default React.memo(
  IndividualSelector,
  (prevProps: SelectorProps, nextProps: SelectorProps) => prevProps.roomId === nextProps.roomId,
);
