import React from 'react';
import classes from './style.module.css';

export default function TimeInput(
  {
    className,
    min,
    setMin,
    sec,
    setSec,
    disabled = false,
  }: Props,
) {
  return (
    <div className={[classes.root, className].join(' ')}>
      <input
        type="number"
        disabled={disabled}
        className={classes.input}
        value={min}
        onChange={(e) => setMin(parseInt(e.target.value, 10))}
      />
      :
      <input
        type="number"
        disabled={disabled}
        className={classes.input}
        value={sec}
        onChange={(e) => setSec(parseInt(e.target.value, 10))}
      />
    </div>
  );
}

type Props = {
  className?: string
  min: number
  setMin: Function
  sec: number
  setSec: Function
  disabled?: boolean
};
