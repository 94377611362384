import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
/**
 * Basic Component － To Control tab components
 * @example <TabController setValue={theme.color.primary}><Tab color="#ffffff">Tab1</Tab></TabController>
 *
 */
export default function TabController({ children }: Props) {
  const history = useHistory();
  const { tab, roomId } = useParams<{ tab: string, roomId: string }>();

  const tabs = ['display', 'setting', 'status'];
  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        key: tabs[i],
        onClick: () => history.replace(`/room/${roomId}/${tabs[i]}`),
        isActive: tab === tabs[i],
      });
    }
    throw new Error('Only Tabs are allowed to be passed into TabController!');
  });

  return <span style={{width: 60, height: '100%'}}>{childrenWithProps}</span>;
}

type Props = {
  children?: Array<React.ReactElement>
};
