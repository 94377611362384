import React, { useState, useEffect } from 'react';
import Space from 'components/basic/Space/index';
import Input from 'components/basic/Input';
import IconButton from 'components/basic/IconButton/index';
import { Individual, useUpdateTallySheetMutation } from 'graphql/generated/graphql';
import { getIdvIcon, getIdvName } from 'utils/individual';
import { useDebouncedCallback } from 'use-debounce';
import classes from './style.module.css';

function ValueItem({ item, iid }: ItemProps) {
  const [value, setValue] = useState(0);
  const [updateValue] = useUpdateTallySheetMutation();

  useEffect(() => {
    setValue(item.value);
  }, [item.value]);

  const handleUpdateValue = useDebouncedCallback((val: number) => {
    updateValue({ variables: { IID: iid, TID: item.item._id, value: val } });
  }, 300);

  return (
    <span className={classes.rowItem}>
      <IconButton icon="minus" color={"var(--color-secondary)"} onClick={() => handleUpdateValue(value - 1)} />
      <Input
        type="number"
        className={classes.rowInput}
        value={value}
        onChange={(e) => {
          const val = parseInt(e.target.value.trim(), 10) || 0;
          handleUpdateValue(val);
          setValue(val);
        }}
      />
      <IconButton icon="add" color={"var(--color-primary)"} onClick={() => handleUpdateValue(value + 1)} />
    </span>
  );
}

type ItemProps = {
  item: {
    item: {
      _id: string
    },
    value: number
  },
  iid: string
};

export default function TallySheetRow({ individual, values, isEdit }: Props) {

  return (
    <div className={classes.row}>
      <span className={classes.rowTitle}>
        <img
          alt="flag"
          src={getIdvIcon(individual)}
          width="50"
        />
        <Space h gap={10} />
        {getIdvName(individual, true)}
      </span>
      {values.map((v) => <ValueItem key={v.item._id} item={v} iid={individual._id} />)}
      {isEdit && <Space gap={40} h />}
    </div>
  );
}

type Props = {
  values: {
    item: {
      _id: string
    },
    value: number
  }[]
  individual: Individual,
  isEdit: boolean
};
