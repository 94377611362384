import React from 'react';
import Icon from 'components/basic/Icon';
import Space from 'components/basic/Space';
import classes from './style.module.css';


/**
 * Basic Component － Tag to switch the panel, which should use as a child under TabController
 * @example <Tab color={theme.color.primary}>Tab1</Tab>
 *
 */
export default function Tab({
  icon, onClick, isActive, color = 'primary', children,
}: Props) {

  return (
    <span 
      className={classes.root} 
      style={{
        boxShadow: (isActive ? "2px 0px 2px 1px #BDBDBD" : undefined),
        backgroundColor:  color === 'primary' ? 'var(--color-primary)' : color,
        opacity: (isActive ? 1 : 0.4),
      }}
      onClick={onClick}
    >
      {icon && (
        <>
          <Icon icon={icon} size={20} color="white" />
          <Space v gap={10} />
        </>
      )}
      {children}
    </span>
  );
}

type Props = {
  icon?: string
  onClick?: React.MouseEventHandler
  isActive?: boolean
  color?: string
  children?: React.ReactNode
};
