import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import Icon from 'components/basic/Icon';
import Space from 'components/basic/Space/index';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { RoomContext } from 'contexts';
import {
  Display, useCreateDisplayMutation, useDeleteDisplayMutation, useUpdateDisplayMutation, useUpdateRoomMutation,
} from 'graphql/generated/graphql';
import classes from './style.module.css';

function Tab({
  display, selectedId, select, currentId, setCurrent
}: TabProps) {
  const [name, setName] = useState(display.name);
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [remove] = useDeleteDisplayMutation({ variables: { DID: display._id } });
  const [update] = useUpdateDisplayMutation();

  function handleOutSide() {
    if (isEditing) {
      setEditing(false);
      update({ variables: { DID: display._id, name } });
    }
  }

  const ref = useDetectClickOutside({
    onTriggered: handleOutSide,
  });

  useEffect(() => {
    setName(display.name);
  }, [display]);

  function handleEdit() {
    inputRef.current!.select();
    setEditing(true);
  }

  return (
    <span 
      className={classes.tab} 
      onClick={() => select(display._id)}
      ref={ref} 
      style={{ filter: display._id === selectedId ? '' : 'brightness(0.9)',}}
    >
      <span
        onClick={() => {
          setCurrent(display._id);
        }}
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <Icon icon="eye" color={currentId === display._id ? "var(--color-primary)" : 'gray'} size={18} />
      </span>
      <Space gap={5} h />
      {!isEditing
        && <span onDoubleClick={handleEdit}>{name}</span>}
      <input
        ref={inputRef}
        className={classes.editInput}
        style={{
          width: isEditing ? 120 : 0,
          opacity: isEditing ? 1 : 0,
          pointerEvents: isEditing ? 'auto' : 'none',
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleOutSide();
          }
        }}
        onChange={(e) => setName(e.target.value)}
        value={name}
      />

      <Space gap={10} h />
      <Icon
        icon="clear"
        color="gray"
        size={16}
        onClick={(e) => {
          remove();
          e.stopPropagation();
        }}
        className={display._id == currentId ? classes.disabled : classes.iconBtn}
      />
    </span>
  );
}

type TabProps = {
  selectedId: string
  currentId: string
  select: Function
  setCurrent: Function
  display: Omit<Display, 'modules'>
};

export default function DisplayTabBar({
  displays, selectedId, setSelectedId, current,
}: Props) {
  const [currentId, setCurrentId] = useState('');
  const [updateRoom] = useUpdateRoomMutation();

  const { _id } = useContext(RoomContext);

  useEffect(() => {
    setCurrentId(current);
  }, [current]);

  function handleSelect(id: string) {
    setSelectedId(id);
  }

  function handleSetCurrent(id: string) {
    updateRoom({ variables: { RID: _id, currentDisplayId: id } });
    setCurrentId(id);
  }

  const { _id: roomId } = useContext(RoomContext);

  const [createDisplay] = useCreateDisplayMutation();

  return (
    <div className={classes.root}>
      {displays.map((d) => (
        <Tab
          key={d._id}
          display={d}
          selectedId={selectedId}
          currentId={currentId}
          select={(id: string) => handleSelect(id)}
          setCurrent={(id: string) => handleSetCurrent(id)}
        />
      ))}
      <span className={classes.tab+" "+classes.addTab} onClick={() => createDisplay({ variables: { RID: roomId } })}>
        <Icon icon="add" color="gray" size={18} />
      </span>
    </div>
  );
}

type Props = {
  selectedId: string
  setSelectedId: Function
  displays: {
    _id: string
    name: string
  }[],
  current: string
};
