import React, { useState, useEffect } from 'react';

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function calc(
  x: number,
  y: number,
  radius: number,
  startDeg: number,
  deg: number,
) {
  const start = polarToCartesian(x, y, radius, ((deg - 1) % 360) + 1);
  const end = polarToCartesian(x, y, radius, startDeg);
  const mid = polarToCartesian(
    x,
    y,
    radius,
    (((deg - 1) % 360) + 1 + startDeg) / 2,
  );
  const largeArcFlag = ((deg - 1) % 360) + 1 - startDeg <= 180 ? '0' : '1';
  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ');

  return { d, mid };
}

export default function Circular({
  smooth = false,
  width = 100,
  height = 100,
  color = 'gray',
  startDeg = 0,
  deg = 360,
  thicken = 4,
  className,
  text,
  fontSize = 12,
  debugMode,
}: Props) {
  const [myDeg, setmyDeg] = useState(360);

  useEffect(() => {
    if (Math.abs(myDeg - deg) > 360) {
      setmyDeg(deg);
      return;
    }
    if (myDeg === deg) return;
    let con: NodeJS.Timeout | undefined;
    if (smooth) {
      if (Math.abs(myDeg - deg) < 1) setmyDeg(deg);
      con = setTimeout(
        () => setmyDeg(myDeg + (myDeg > deg ? -1 : 1)),
        100 / Math.abs(myDeg - deg),
      );
    } else {
      setmyDeg(deg);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (con) clearTimeout(con);
    };
  }, [deg, myDeg, smooth]);

  const arc = calc(
    width / 2,
    height / 2,
    width / 2 - thicken / 2,
    startDeg,
    startDeg + myDeg,
  );

  if (startDeg === 0 && myDeg === 360) {
    return (
      <svg width={width} height={height} className={className}>
        <circle
          cx={width / 2}
          cy={height / 2}
          r={width / 2 - thicken / 2}
          stroke={color}
          fill="none"
          strokeWidth={thicken}
          style={{ transition: '0.3s stroke' }}
        />
        {// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          myDeg > 20 && text ? (
            <text
              x={arc.mid.x - fontSize}
              y={arc.mid.y + fontSize / 2}
              fontSize={fontSize}
              fill="white"
              fontWeight="500"
            >
              {text}
            </text>
          ) : null
        }
      </svg>
    );
  }

  return (
    <svg width={width} height={height} className={className}>
      <path
        d={arc.d}
        stroke={color}
        fill="none"
        strokeWidth={thicken}
        style={{ transition: '0.3s stroke' }}
      />

      {debugMode && (
        <circle
          cx={arc.mid.x - 1}
          cy={arc.mid.y - 1}
          r="2"
          stroke="red"
          fill="transparent"
          strokeWidth="5"
        />
      )}

      {myDeg > 20 && text && (
        <text
          x={arc.mid.x - fontSize}
          y={arc.mid.y + fontSize / 2}
          fontSize={fontSize}
          fill="white"
        >
          {text}
        </text>
      )}
    </svg>
  );
}

type Props = {
  smooth?: boolean;
  width?: number;
  height?: number;
  color?: string;
  startDeg?: number;
  deg?: number;
  thicken?: number;
  className?: string;
  text?: string;
  fontSize?: number;
  debugMode?: boolean;
};
