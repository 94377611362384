import React from 'react';
import TallySheet from 'components/section/TallySheet/index';
import StatusDropDown from 'components/composed/StatusDropDown/index';
import RollCallList from 'components/section/RollCall';
import classes from './style.module.css';

export default function StatusPanel({ indexDropdown, setIndexDropdown }: Props) {
  const pages = [<TallySheet key="tallysheet" />, <RollCallList key="rollcall" />]; // <History />
  return (
    <div className={classes.root}>
      <StatusDropDown index={indexDropdown} setIndex={setIndexDropdown} />
      {pages[indexDropdown]}
    </div>
  );
}

type Props = {
  indexDropdown: number
  setIndexDropdown: Function
};
