import React, { useEffect, useRef, useState } from 'react';
import Input from 'components/basic/Input/index';
import { ICoreProps } from 'components/module/moduleDefine';
import Space from 'components/basic/Space/index';
import TimeInput from 'components/basic/TimeInput/index';
import Icon from 'components/basic/Icon/index';
import Dropdown from 'components/basic/Dropdown/index';
import {
  MotionModuleData, MotionType, Individual,
} from 'graphql/generated/graphql';
import { getIdvIcon, getIdvName } from 'utils/individual';
import IndividualSelectModal from 'components/composed/IndividualSelectModal/index';
import classes from './style.module.css';

export default function MunativeMotion({
  data,
  setEditData: editDispatcher,
  isEdit,
  realDim,
  audience,
}: ICoreProps<MotionModuleData>) {
  const [type, setType] = useState<MotionType>(MotionType.Mod);
  const [customType, setCustomType] = useState<string>('');
  const [topic, setTopic] = useState('');
  const [submitter, setSubmitter] = useState<Individual | undefined | null>();
  const [totalMin, setTotalMin] = useState(0);
  const [totalSec, setTotalSec] = useState(0);
  const [isTotalTimeDisplay, setTotalTimeDisplay] = useState(false);
  const [speakerMin, setSpeakerMin] = useState(0);
  const [speakerSec, setSpeakerSec] = useState(0);
  const [isSpeakerTimeDisplay, setSpeakerTimeDisplay] = useState(false);

  const titleRef = useRef(null);

  const [modalShow, setModalShow] = useState(false);

  function timeFormate(min: number, sec: number) {
    let s = min * 60 + sec;
    const m = Math.floor(s / 60);
    s %= 60;
    return `${m >= 10 ? m : `0${m}`}:${s >= 10 ? s : `0${s}`}`;
  }

  useEffect(() => {
    setType(data.type);
    setCustomType(data.customMotionType || '');

    setTotalMin(Math.floor(data.totalTime.sec / 60));
    setTotalSec(data.totalTime.sec % 60);
    setTotalTimeDisplay(data.totalTime.isDisplay);

    setSpeakerMin(Math.floor(data.speakerTime.sec / 60));
    setSpeakerSec(data.speakerTime.sec % 60);
    setSpeakerTimeDisplay(data.speakerTime.isDisplay);

    setTopic(data.topic);
    setSubmitter(data.submitter);
  }, [data]);

  useEffect(() => {
    if (isEdit) {
      editDispatcher({
        motion: {
          totalTime: { sec: totalSec + 60 * totalMin, isDisplay: isTotalTimeDisplay },
          speakerTime: { sec: speakerSec + 60 * speakerMin, isDisplay: isSpeakerTimeDisplay },
          type,
          customMotionType: customType,
          topic,
          submitter: submitter?._id,
        },
      });
    }
  }, [isEdit, editDispatcher,
    totalSec, totalMin, isTotalTimeDisplay,
    speakerSec, speakerMin, isSpeakerTimeDisplay,
    type, customType, topic, submitter,
  ]);

  if (!audience) {
    return (
      <>
        <div className="defaultView">

          <div className={classes.title} ref={titleRef}>
            {
              type === MotionType.Custom ? customType : { custom: 'Custom', mod: 'Moderated Caucus', unmod: 'Unmoderated Caucus' }[type]
            }
          </div>
          <Space v gap={10} />

          {topic.length > 0
            && (
              <div className={classes.topic} ref={titleRef}>
                {topic}
              </div>
            )}
          <Space v gap={10} />

          <div className={classes.submitter}>
            <span>Submitted By</span>
            <Space h gap={10} />

            {submitter && getIdvName(submitter, true)}
            <Space h gap={5} />
            {submitter ? <img alt="flag" src={getIdvIcon(submitter)} width="30" /> : 'None'}
          </div>
          <div className={classes.form}>
            {isTotalTimeDisplay && (
              <div className={classes.row}>
                <span>Total Time</span>
                <span>{timeFormate(totalMin, totalSec)}</span>
              </div>
            )}
            {isSpeakerTimeDisplay && (
              <div className={classes.row}>
                <span>Speaker Time</span>
                <span>{timeFormate(speakerMin, speakerSec)}</span>
              </div>
            )}
          </div>
        </div>

        <div className={['editView', isEdit ? 'active' : ''].join(' ')}>
          <div style={{ textAlign: 'left' }}>
            <Dropdown
              width={170}
              items={[
                { label: 'Select...', value: null },
                { label: 'Custom', value: MotionType.Custom },
                { label: 'Moderated Caucus', value: MotionType.Mod },
                { label: 'Unmoderated Caucus', value: MotionType.Unmod }]}
              value={type}
              setValue={setType}
            />
          </div>
          {type === MotionType.Custom && (
            <>
              <Space v gap={10} />
              <Input
                value={customType || ''}
                placeholder="Custom Type"
                onChange={(e) => setCustomType(e.target.value)}
                style={{ width: '100%' }}
                alignment="center"
              />
            </>
          )}

          <Space v gap={10} />
          <textarea className={classes.topicInput} value={topic} placeholder="Topic" onChange={(e) => setTopic(e.target.value)} />

          <Space v gap={10} />
          <div className={classes.submitter}>
            <span>Submitted By</span>
            <Space h gap={10} />
            <span className={classes.touchable} onClick={() => setModalShow(true)}>
              {submitter ? <img alt="flag" src={getIdvIcon(submitter)} width="30" /> : 'None'}
              <Space h gap={10} />
              <Icon icon="drop_down" size={30} color={"var(--color-primary)"} />
            </span>
          </div>
          <div className={classes.form}>

            <div className={classes.row}>
              <Icon
                icon="eye"
                className={classes.visibility}
                color={isTotalTimeDisplay ? "var(--color-primary)" : 'lightgray'}
                onClick={() => setTotalTimeDisplay(!isTotalTimeDisplay)}
                size={16}
              />
              <span>Total Time</span>
              <TimeInput sec={totalSec} min={totalMin} setMin={setTotalMin} setSec={setTotalSec} />
            </div>

            <div className={classes.row}>
              <Icon
                icon="eye"
                className={classes.visibility}
                color={isSpeakerTimeDisplay ? "var(--color-primary)" : 'lightgray'}
                onClick={() => setSpeakerTimeDisplay(!isSpeakerTimeDisplay)}
                size={16}
              />
              <span>Speaker Time</span>
              <TimeInput sec={speakerSec} min={speakerMin} setMin={setSpeakerMin} setSec={setSpeakerSec} />
            </div>
          </div>
        </div>

        <IndividualSelectModal
          isShow={modalShow}
          setShow={setModalShow}
          value={submitter ? [submitter] : []}
          onFinish={(selected: Individual[]) => { setSubmitter(selected[0]); }}
        />

      </>
    );
  }

  return (

    <div className="defaultView">

      <div className={classes.title} ref={titleRef}>
        {
          type === MotionType.Custom ? customType : { custom: 'Custom', mod: 'Moderated Caucus', unmod: 'Unmoderated Caucus' }[type]
        }
      </div>
      <Space v gap={10} />

      {topic.length > 0
        && (
          <div className={classes.topic} ref={titleRef}>
            {topic}
          </div>
        )}
      <Space v gap={10} />

      <div className={classes.submitter}>
        <span>Submitted By</span>
        <Space h gap={10} />
        {submitter && getIdvName(submitter, true)}
        <Space h gap={5} />
        {submitter ? <img alt="flag" src={getIdvIcon(submitter)} width="30" /> : 'None'}
      </div>
      <div className={classes.form}>
        {isTotalTimeDisplay && (
          <div className={classes.row}>
            <span>Total Time</span>
            <span>{timeFormate(totalMin, totalSec)}</span>
          </div>
        )}
        {isSpeakerTimeDisplay && (
          <div className={classes.row}>
            <span>Speaker Time</span>
            <span>{timeFormate(speakerMin, speakerSec)}</span>
          </div>
        )}
      </div>
    </div>
  );
}
