interface Casting {
  [iso: string]: number
}

export interface Country {
  name: string,
  aka: string,
  abbr: string[],
  alpha2: string,
  alpha3: string,
  uncode: number,
  altIcon?: string
}

export const cast2: Casting = {
  // eslint-disable-next-line max-len
  AF: 0, AL: 1, DZ: 2, AD: 3, AO: 4, AG: 5, AR: 6, AM: 7, AU: 8, AT: 9, AZ: 10, BS: 11, BH: 12, BD: 13, BB: 14, BY: 15, BE: 16, BZ: 17, BJ: 18, BT: 19, BO: 20, BA: 21, BW: 22, BR: 23, BN: 24, BG: 25, BF: 26, BI: 27, CV: 28, KH: 29, CM: 30, CA: 31, CF: 32, TD: 33, CL: 34, CN: 35, CO: 36, KM: 37, CG: 38, CR: 39, CI: 40, HR: 41, CU: 42, CY: 43, CZ: 44, CS: 45, KP: 46, CD: 47, DK: 48, DJ: 49, DM: 50, DO: 51, EC: 52, EG: 53, SV: 54, GQ: 55, ER: 56, EE: 57, SZ: 58, ET: 59, FJ: 60, FI: 61, FR: 62, GA: 63, GM: 64, GE: 65, DE: 66, GH: 67, GR: 68, GD: 69, GT: 70, GN: 71, GW: 72, GY: 73, HT: 74, VA: 75, HN: 76, HU: 77, IS: 78, IN: 79, ID: 80, IR: 81, IQ: 82, IE: 83, IL: 84, IT: 85, JM: 86, JP: 87, JO: 88, KZ: 89, KE: 90, KI: 91, KW: 92, KG: 93, LA: 94, LV: 95, LB: 96, LS: 97, LR: 98, LY: 99, LI: 100, LT: 101, LU: 102, MG: 103, MW: 104, MY: 105, MV: 106, ML: 107, MT: 108, MH: 109, MR: 110, MU: 111, MX: 112, FM: 113, MC: 114, MN: 115, ME: 116, MA: 117, MZ: 118, MM: 119, NA: 120, NR: 121, NP: 122, NL: 123, NZ: 124, NI: 125, NE: 126, NG: 127, MK: 128, NO: 129, OM: 130, PK: 131, PW: 132, PA: 133, PG: 134, PY: 135, PE: 136, PH: 137, PL: 138, PT: 139, QA: 140, KR: 141, MD: 142, RO: 143, RU: 144, RW: 145, KN: 146, LC: 147, VC: 148, WS: 149, SM: 150, ST: 151, SA: 152, SN: 153, RS: 154, SC: 155, SL: 156, SG: 157, SK: 158, SI: 159, SB: 160, SO: 161, ZA: 162, SS: 163, ES: 164, LK: 165, SD: 166, SR: 167, SE: 168, CH: 169, SY: 170, TJ: 171, TH: 172, TL: 173, TG: 174, TO: 175, TT: 176, TN: 177, TR: 178, TM: 179, TV: 180, UG: 181, UA: 182, AE: 183, GB: 184, TZ: 185, US: 186, UY: 187, UZ: 188, VU: 189, VE: 190, VN: 191, YE: 192, ZM: 193, ZW: 194,
};
export const cast3: Casting = {
  // eslint-disable-next-line max-len
  AFG: 0, ALB: 1, DZA: 2, AND: 3, AGO: 4, ATG: 5, ARG: 6, ARM: 7, AUS: 8, AUT: 9, AZE: 10, BHS: 11, BHR: 12, BGD: 13, BRB: 14, BLR: 15, BEL: 16, BLZ: 17, BEN: 18, BTN: 19, BOL: 20, BIH: 21, BWA: 22, BRA: 23, BRN: 24, BGR: 25, BFA: 26, BDI: 27, CPV: 28, KHM: 29, CMR: 30, CAN: 31, CAF: 32, TCD: 33, CHL: 34, CHN: 35, COL: 36, COM: 37, COG: 38, CRI: 39, CIV: 40, HRV: 41, CUB: 42, CYP: 43, CZE: 44, CSK: 45, PRK: 46, COD: 47, DNK: 48, DJI: 49, DMA: 50, DOM: 51, ECU: 52, EGY: 53, SLV: 54, GNQ: 55, ERI: 56, EST: 57, SWZ: 58, ETH: 59, FJI: 60, FIN: 61, FRA: 62, GAB: 63, GMB: 64, GEO: 65, DEU: 66, GHA: 67, GRC: 68, GRD: 69, GTM: 70, GIN: 71, GNB: 72, GUY: 73, HTI: 74, VAT: 75, HND: 76, HUN: 77, ISL: 78, IND: 79, IDN: 80, IRN: 81, IRQ: 82, IRL: 83, ISR: 84, ITA: 85, JAM: 86, JPN: 87, JOR: 88, KAZ: 89, KEN: 90, KIR: 91, KWT: 92, KGZ: 93, LAO: 94, LVA: 95, LBN: 96, LSO: 97, LBR: 98, LBY: 99, LIE: 100, LTU: 101, LUX: 102, MDG: 103, MWI: 104, MYS: 105, MDV: 106, MLI: 107, MLT: 108, MHL: 109, MRT: 110, MUS: 111, MEX: 112, FSM: 113, MCO: 114, MNG: 115, MNE: 116, MAR: 117, MOZ: 118, MMR: 119, NAM: 120, NRU: 121, NPL: 122, NLD: 123, NZL: 124, NIC: 125, NER: 126, NGA: 127, MKD: 128, NOR: 129, OMN: 130, PAK: 131, PLW: 132, PAN: 133, PNG: 134, PRY: 135, PER: 136, PHL: 137, POL: 138, PRT: 139, QAT: 140, KOR: 141, MDA: 142, ROU: 143, RUS: 144, RWA: 145, KNA: 146, LCA: 147, VCT: 148, WSM: 149, SMR: 150, STP: 151, SAU: 152, SEN: 153, SRB: 154, SYC: 155, SLE: 156, SGP: 157, SVK: 158, SVN: 159, SLB: 160, SOM: 161, ZAF: 162, SSD: 163, ESP: 164, LKA: 165, SDN: 166, SUR: 167, SWE: 168, CHE: 169, SYR: 170, TJK: 171, THA: 172, TLS: 173, TGO: 174, TON: 175, TTO: 176, TUN: 177, TUR: 178, TKM: 179, TUV: 180, UGA: 181, UKR: 182, ARE: 183, GBR: 184, TZA: 185, USA: 186, URY: 187, UZB: 188, VUT: 189, VEN: 190, VNM: 191, YEM: 192, ZMB: 193, ZWE: 194,
};

export const countries = [{
  name: 'Afghanistan',
  aka: '',
  abbr: [],
  alpha2: 'AF',
  alpha3: 'AFG',
  uncode: 4,
}, {
  name: 'Albania',
  aka: '',
  abbr: [],
  alpha2: 'AL',
  alpha3: 'ALB',
  uncode: 8,
}, {
  name: 'Algeria',
  aka: '',
  abbr: [],
  alpha2: 'DZ',
  alpha3: 'DZA',
  uncode: 12,
}, {
  name: 'Andorra',
  aka: '',
  abbr: [],
  alpha2: 'AD',
  alpha3: 'AND',
  uncode: 20,
}, {
  name: 'Angola',
  aka: '',
  abbr: [],
  alpha2: 'AO',
  alpha3: 'AGO',
  uncode: 24,
}, {
  name: 'Antigua and Barbuda',
  aka: '',
  abbr: [],
  alpha2: 'AG',
  alpha3: 'ATG',
  uncode: 28,
}, {
  name: 'Argentina',
  aka: '',
  abbr: [],
  alpha2: 'AR',
  alpha3: 'ARG',
  uncode: 32,
}, {
  name: 'Armenia',
  aka: '',
  abbr: [],
  alpha2: 'AM',
  alpha3: 'ARM',
  uncode: 51,
}, {
  name: 'Australia',
  aka: '',
  abbr: [],
  alpha2: 'AU',
  alpha3: 'AUS',
  uncode: 36,
}, {
  name: 'Austria',
  aka: '',
  abbr: [],
  alpha2: 'AT',
  alpha3: 'AUT',
  uncode: 40,
}, {
  name: 'Azerbaijan',
  aka: '',
  abbr: [],
  alpha2: 'AZ',
  alpha3: 'AZE',
  uncode: 31,
}, {
  name: 'Bahamas',
  aka: '',
  abbr: [],
  alpha2: 'BS',
  alpha3: 'BHS',
  uncode: 44,
}, {
  name: 'Bahrain',
  aka: '',
  abbr: [],
  alpha2: 'BH',
  alpha3: 'BHR',
  uncode: 48,
}, {
  name: 'Bangladesh',
  aka: '',
  abbr: [],
  alpha2: 'BD',
  alpha3: 'BGD',
  uncode: 50,
}, {
  name: 'Barbados',
  aka: '',
  abbr: [],
  alpha2: 'BB',
  alpha3: 'BRB',
  uncode: 5,
}, {
  name: 'Belarus',
  aka: '',
  abbr: [],
  alpha2: 'BY',
  alpha3: 'BLR',
  uncode: 112,
}, {
  name: 'Belgium',
  aka: '',
  abbr: [],
  alpha2: 'BE',
  alpha3: 'BEL',
  uncode: 56,
}, {
  name: 'Belize',
  aka: '',
  abbr: [],
  alpha2: 'BZ',
  alpha3: 'BLZ',
  uncode: 84,
}, {
  name: 'Benin',
  aka: '',
  abbr: [],
  alpha2: 'BJ',
  alpha3: 'BEN',
  uncode: 204,
}, {
  name: 'Bhutan',
  aka: '',
  abbr: [],
  alpha2: 'BT',
  alpha3: 'BTN',
  uncode: 64,
}, {
  name: 'Bolivia',
  aka: '',
  abbr: [],
  alpha2: 'BO',
  alpha3: 'BOL',
  uncode: 68,
}, {
  name: 'Bosnia and Herzegovina',
  aka: '',
  abbr: [],
  alpha2: 'BA',
  alpha3: 'BIH',
  uncode: 70,
}, {
  name: 'Botswana',
  aka: '',
  abbr: [],
  alpha2: 'BW',
  alpha3: 'BWA',
  uncode: 72,
}, {
  name: 'Brazil',
  aka: '',
  abbr: [],
  alpha2: 'BR',
  alpha3: 'BRA',
  uncode: 76,
}, {
  name: 'Brunei Darussalem',
  aka: '',
  abbr: ['Brunei'],
  alpha2: 'BN',
  alpha3: 'BRN',
  uncode: 96,
}, {
  name: 'Bulgaria',
  aka: '',
  abbr: [],
  alpha2: 'BG',
  alpha3: 'BGR',
  uncode: 100,
}, {
  name: 'Burkina Faso',
  aka: '',
  abbr: [],
  alpha2: 'BF',
  alpha3: 'BFA',
  uncode: 854,
}, {
  name: 'Burundi',
  aka: '',
  abbr: [],
  alpha2: 'BI',
  alpha3: 'BDI',
  uncode: 108,
}, {
  name: 'Cabo Verde',
  aka: 'Cape Verde',
  abbr: [],
  alpha2: 'CV',
  alpha3: 'CPV',
  uncode: 132,
}, {
  name: 'Cambodia',
  aka: '',
  abbr: [],
  alpha2: 'KH',
  alpha3: 'KHM',
  uncode: 116,
}, {
  name: 'Cameroon',
  aka: '',
  abbr: [],
  alpha2: 'CM',
  alpha3: 'CMR',
  uncode: 120,
}, {
  name: 'Canada',
  aka: '',
  abbr: [],
  alpha2: 'CA',
  alpha3: 'CAN',
  uncode: 124,
}, {
  name: 'Central African Republic',
  aka: '',
  abbr: [],
  alpha2: 'CF',
  alpha3: 'CAF',
  uncode: 140,
}, {
  name: 'Chad',
  aka: '',
  abbr: [],
  alpha2: 'TD',
  alpha3: 'TCD',
  uncode: 148,
}, {
  name: 'Chile',
  aka: '',
  abbr: [],
  alpha2: 'CL',
  alpha3: 'CHL',
  uncode: 152,
}, {
  name: 'China',
  aka: '',
  abbr: [],
  alpha2: 'CN',
  alpha3: 'CHN',
  uncode: 156,
}, {
  name: 'Colombia',
  aka: '',
  abbr: [],
  alpha2: 'CO',
  alpha3: 'COL',
  uncode: 170,
}, {
  name: 'Comoros',
  aka: '',
  abbr: [],
  alpha2: 'KM',
  alpha3: 'COM',
  uncode: 174,
}, {
  name: 'Congo, Republic of the',
  aka: '',
  abbr: ['Congo'],
  alpha2: 'CG',
  alpha3: 'COG',
  uncode: 178,
}, {
  name: 'Costa Rica',
  aka: '',
  abbr: [],
  alpha2: 'CR',
  alpha3: 'CRI',
  uncode: 188,
}, {
  name: "Côte D'Ivoire",
  aka: '',
  abbr: [],
  alpha2: 'CI',
  alpha3: 'CIV',
  uncode: 384,
}, {
  name: 'Croatia',
  aka: '',
  abbr: [],
  alpha2: 'HR',
  alpha3: 'HRV',
  uncode: 191,
}, {
  name: 'Cuba',
  aka: '',
  abbr: [],
  alpha2: 'CU',
  alpha3: 'CUB',
  uncode: 192,
}, {
  name: 'Cyprus',
  aka: '',
  abbr: [],
  alpha2: 'CY',
  alpha3: 'CYP',
  uncode: 196,
}, {
  name: 'Czech Republic',
  aka: '',
  abbr: [],
  alpha2: 'CZ',
  alpha3: 'CZE',
  uncode: 203,
}, {
  name: 'Czechoslovakia',
  aka: '',
  abbr: [],
  alpha2: 'CS',
  alpha3: 'CSK',
  uncode: 203,
  altIcon: 'https://hatscripts.github.io/circle-flags/flags/cz.svg',
}, {
  name: "Democratic People's Republic of Korea",
  aka: 'North Korea',
  abbr: ['DPRK'],
  alpha2: 'KP',
  alpha3: 'PRK',
  uncode: 408,
}, {
  name: 'Democratic Republic of Congo',
  aka: '',
  abbr: ['DRC'],
  alpha2: 'CD',
  alpha3: 'COD',
  uncode: 180,
}, {
  name: 'Denmark',
  aka: '',
  abbr: [],
  alpha2: 'DK',
  alpha3: 'DNK',
  uncode: 208,
}, {
  name: 'Djibouti',
  aka: '',
  abbr: [],
  alpha2: 'DJ',
  alpha3: 'DJI',
  uncode: 262,
}, {
  name: 'Dominica',
  aka: '',
  abbr: [],
  alpha2: 'DM',
  alpha3: 'DMA',
  uncode: 212,
}, {
  name: 'Dominican Republic',
  aka: '',
  abbr: [],
  alpha2: 'DO',
  alpha3: 'DOM',
  uncode: 214,
}, {
  name: 'Ecuador',
  aka: '',
  abbr: [],
  alpha2: 'EC',
  alpha3: 'ECU',
  uncode: 218,
}, {
  name: 'Egypt',
  aka: '',
  abbr: [],
  alpha2: 'EG',
  alpha3: 'EGY',
  uncode: 818,
}, {
  name: 'El Salvador',
  aka: '',
  abbr: [],
  alpha2: 'SV',
  alpha3: 'SLV',
  uncode: 222,
}, {
  name: 'Equatorial Guinea',
  aka: '',
  abbr: [],
  alpha2: 'GQ',
  alpha3: 'GNQ',
  uncode: 226,
}, {
  name: 'Eritrea',
  aka: '',
  abbr: [],
  alpha2: 'ER',
  alpha3: 'ERI',
  uncode: 232,
}, {
  name: 'Estonia',
  aka: '',
  abbr: [],
  alpha2: 'EE',
  alpha3: 'EST',
  uncode: 233,
}, {
  name: 'Eswatini',
  aka: 'Swaziland',
  abbr: [],
  alpha2: 'SZ',
  alpha3: 'SWZ',
  uncode: 748,
}, {
  name: 'Ethiopia',
  aka: '',
  abbr: [],
  alpha2: 'ET',
  alpha3: 'ETH',
  uncode: 231,
}, {
  name: 'Fiji',
  aka: '',
  abbr: [],
  alpha2: 'FJ',
  alpha3: 'FJI',
  uncode: 242,
}, {
  name: 'Finland',
  aka: '',
  abbr: [],
  alpha2: 'FI',
  alpha3: 'FIN',
  uncode: 246,
}, {
  name: 'France',
  aka: '',
  abbr: [],
  alpha2: 'FR',
  alpha3: 'FRA',
  uncode: 250,
}, {
  name: 'Gabon',
  aka: '',
  abbr: [],
  alpha2: 'GA',
  alpha3: 'GAB',
  uncode: 266,
}, {
  name: 'Gambia',
  aka: '',
  abbr: [],
  alpha2: 'GM',
  alpha3: 'GMB',
  uncode: 270,
}, {
  name: 'Georgia',
  aka: '',
  abbr: [],
  alpha2: 'GE',
  alpha3: 'GEO',
  uncode: 268,
}, {
  name: 'Germany',
  aka: '',
  abbr: [],
  alpha2: 'DE',
  alpha3: 'DEU',
  uncode: 276,
}, {
  name: 'Ghana',
  aka: '',
  abbr: [],
  alpha2: 'GH',
  alpha3: 'GHA',
  uncode: 288,
}, {
  name: 'Greece',
  aka: '',
  abbr: [],
  alpha2: 'GR',
  alpha3: 'GRC',
  uncode: 300,
}, {
  name: 'Grenada',
  aka: '',
  abbr: [],
  alpha2: 'GD',
  alpha3: 'GRD',
  uncode: 308,
}, {
  name: 'Guatemala',
  aka: '',
  abbr: [],
  alpha2: 'GT',
  alpha3: 'GTM',
  uncode: 320,
}, {
  name: 'Guinea',
  aka: '',
  abbr: [],
  alpha2: 'GN',
  alpha3: 'GIN',
  uncode: 324,
}, {
  name: 'Guinea-Bissau',
  aka: '',
  abbr: [],
  alpha2: 'GW',
  alpha3: 'GNB',
  uncode: 624,
}, {
  name: 'Guyana',
  aka: '',
  abbr: [],
  alpha2: 'GY',
  alpha3: 'GUY',
  uncode: 328,
}, {
  name: 'Haiti',
  aka: '',
  abbr: [],
  alpha2: 'HT',
  alpha3: 'HTI',
  uncode: 332,
}, {
  name: 'Holy See',
  aka: '',
  abbr: [],
  alpha2: 'VA',
  alpha3: 'VAT',
  uncode: 336,
}, {
  name: 'Honduras',
  aka: '',
  abbr: [],
  alpha2: 'HN',
  alpha3: 'HND',
  uncode: 340,
}, {
  name: 'Hungary',
  aka: '',
  abbr: [],
  alpha2: 'HU',
  alpha3: 'HUN',
  uncode: 348,
}, {
  name: 'Iceland',
  aka: '',
  abbr: [],
  alpha2: 'IS',
  alpha3: 'ISL',
  uncode: 352,
}, {
  name: 'India',
  aka: '',
  abbr: [],
  alpha2: 'IN',
  alpha3: 'IND',
  uncode: 356,
}, {
  name: 'Indonesia',
  aka: '',
  abbr: [],
  alpha2: 'ID',
  alpha3: 'IDN',
  uncode: 360,
}, {
  name: 'Iran',
  aka: '',
  abbr: [],
  alpha2: 'IR',
  alpha3: 'IRN',
  uncode: 364,
}, {
  name: 'Iraq',
  aka: '',
  abbr: [],
  alpha2: 'IQ',
  alpha3: 'IRQ',
  uncode: 368,
}, {
  name: 'Ireland',
  aka: '',
  abbr: [],
  alpha2: 'IE',
  alpha3: 'IRL',
  uncode: 372,
}, {
  name: 'Israel',
  aka: '',
  abbr: [],
  alpha2: 'IL',
  alpha3: 'ISR',
  uncode: 376,
}, {
  name: 'Italy',
  aka: '',
  abbr: [],
  alpha2: 'IT',
  alpha3: 'ITA',
  uncode: 380,
}, {
  name: 'Jamaica',
  aka: '',
  abbr: [],
  alpha2: 'JM',
  alpha3: 'JAM',
  uncode: 388,
}, {
  name: 'Japan',
  aka: '',
  abbr: [],
  alpha2: 'JP',
  alpha3: 'JPN',
  uncode: 392,
}, {
  name: 'Jordan',
  aka: '',
  abbr: [],
  alpha2: 'JO',
  alpha3: 'JOR',
  uncode: 400,
}, {
  name: 'Kazakhstan',
  aka: '',
  abbr: [],
  alpha2: 'KZ',
  alpha3: 'KAZ',
  uncode: 398,
}, {
  name: 'Kenya',
  aka: '',
  abbr: [],
  alpha2: 'KE',
  alpha3: 'KEN',
  uncode: 404,
}, {
  name: 'Kiribati',
  aka: '',
  abbr: [],
  alpha2: 'KI',
  alpha3: 'KIR',
  uncode: 296,
}, {
  name: 'Kuwait',
  aka: '',
  abbr: [],
  alpha2: 'KW',
  alpha3: 'KWT',
  uncode: 414,
}, {
  name: 'Kyrgyzstan',
  aka: '',
  abbr: [],
  alpha2: 'KG',
  alpha3: 'KGZ',
  uncode: 417,
}, {
  name: "Lao People's Democratic Republic",
  aka: '',
  abbr: ['Laos'],
  alpha2: 'LA',
  alpha3: 'LAO',
  uncode: 418,
}, {
  name: 'Latvia',
  aka: '',
  abbr: [],
  alpha2: 'LV',
  alpha3: 'LVA',
  uncode: 428,
}, {
  name: 'Lebanon',
  aka: '',
  abbr: [],
  alpha2: 'LB',
  alpha3: 'LBN',
  uncode: 422,
}, {
  name: 'Lesotho',
  aka: '',
  abbr: [],
  alpha2: 'LS',
  alpha3: 'LSO',
  uncode: 426,
}, {
  name: 'Liberia',
  aka: '',
  abbr: [],
  alpha2: 'LR',
  alpha3: 'LBR',
  uncode: 430,
}, {
  name: 'Libya',
  aka: '',
  abbr: [],
  alpha2: 'LY',
  alpha3: 'LBY',
  uncode: 434,
}, {
  name: 'Liechtenstein',
  aka: '',
  abbr: [],
  alpha2: 'LI',
  alpha3: 'LIE',
  uncode: 438,
}, {
  name: 'Lithuania',
  aka: '',
  abbr: [],
  alpha2: 'LT',
  alpha3: 'LTU',
  uncode: 440,
}, {
  name: 'Luxembourg',
  aka: '',
  abbr: [],
  alpha2: 'LU',
  alpha3: 'LUX',
  uncode: 442,
}, {
  name: 'Madagascar',
  aka: '',
  abbr: [],
  alpha2: 'MG',
  alpha3: 'MDG',
  uncode: 450,
}, {
  name: 'Malawi',
  aka: '',
  abbr: [],
  alpha2: 'MW',
  alpha3: 'MWI',
  uncode: 454,
}, {
  name: 'Malaysia',
  aka: '',
  abbr: [],
  alpha2: 'MY',
  alpha3: 'MYS',
  uncode: 458,
}, {
  name: 'Maldives',
  aka: '',
  abbr: [],
  alpha2: 'MV',
  alpha3: 'MDV',
  uncode: 462,
}, {
  name: 'Mali',
  aka: '',
  abbr: [],
  alpha2: 'ML',
  alpha3: 'MLI',
  uncode: 466,
}, {
  name: 'Malta',
  aka: '',
  abbr: [],
  alpha2: 'MT',
  alpha3: 'MLT',
  uncode: 470,
}, {
  name: 'Marshall Islands',
  aka: '',
  abbr: [],
  alpha2: 'MH',
  alpha3: 'MHL',
  uncode: 584,
}, {
  name: 'Mauritania',
  aka: '',
  abbr: [],
  alpha2: 'MR',
  alpha3: 'MRT',
  uncode: 478,
}, {
  name: 'Mauritius',
  aka: '',
  abbr: [],
  alpha2: 'MU',
  alpha3: 'MUS',
  uncode: 480,
}, {
  name: 'Mexico',
  aka: '',
  abbr: [],
  alpha2: 'MX',
  alpha3: 'MEX',
  uncode: 484,
}, {
  name: 'Micronesia',
  aka: '',
  abbr: [],
  alpha2: 'FM',
  alpha3: 'FSM',
  uncode: 583,
}, {
  name: 'Monaco',
  aka: '',
  abbr: [],
  alpha2: 'MC',
  alpha3: 'MCO',
  uncode: 492,
}, {
  name: 'Mongolia',
  aka: '',
  abbr: [],
  alpha2: 'MN',
  alpha3: 'MNG',
  uncode: 496,
}, {
  name: 'Montenegro',
  aka: '',
  abbr: [],
  alpha2: 'ME',
  alpha3: 'MNE',
  uncode: 499,
}, {
  name: 'Morocco',
  aka: '',
  abbr: [],
  alpha2: 'MA',
  alpha3: 'MAR',
  uncode: 504,
}, {
  name: 'Mozambique',
  aka: '',
  abbr: [],
  alpha2: 'MZ',
  alpha3: 'MOZ',
  uncode: 508,
}, {
  name: 'Myanmar',
  aka: '',
  abbr: [],
  alpha2: 'MM',
  alpha3: 'MMR',
  uncode: 104,
}, {
  name: 'Namibia',
  aka: '',
  abbr: [],
  alpha2: 'NA',
  alpha3: 'NAM',
  uncode: 516,
}, {
  name: 'Nauru',
  aka: '',
  abbr: [],
  alpha2: 'NR',
  alpha3: 'NRU',
  uncode: 520,
}, {
  name: 'Nepal',
  aka: '',
  abbr: [],
  alpha2: 'NP',
  alpha3: 'NPL',
  uncode: 524,
}, {
  name: 'Netherlands',
  aka: '',
  abbr: [],
  alpha2: 'NL',
  alpha3: 'NLD',
  uncode: 528,
}, {
  name: 'New Zealand',
  aka: '',
  abbr: [],
  alpha2: 'NZ',
  alpha3: 'NZL',
  uncode: 554,
}, {
  name: 'Nicaragua',
  aka: '',
  abbr: [],
  alpha2: 'NI',
  alpha3: 'NIC',
  uncode: 558,
}, {
  name: 'Niger',
  aka: '',
  abbr: [],
  alpha2: 'NE',
  alpha3: 'NER',
  uncode: 562,
}, {
  name: 'Nigeria',
  aka: '',
  abbr: [],
  alpha2: 'NG',
  alpha3: 'NGA',
  uncode: 566,
}, {
  name: 'North Macedonia',
  aka: 'Macedonia',
  abbr: [],
  alpha2: 'MK',
  alpha3: 'MKD',
  uncode: 807,
}, {
  name: 'Norway',
  aka: '',
  abbr: [],
  alpha2: 'NO',
  alpha3: 'NOR',
  uncode: 578,
}, {
  name: 'Oman',
  aka: '',
  abbr: [],
  alpha2: 'OM',
  alpha3: 'OMN',
  uncode: 512,
}, {
  name: 'Pakistan',
  aka: '',
  abbr: [],
  alpha2: 'PK',
  alpha3: 'PAK',
  uncode: 586,
}, {
  name: 'Palau',
  aka: '',
  abbr: [],
  alpha2: 'PW',
  alpha3: 'PLW',
  uncode: 585,
}, {
  name: 'Panama',
  aka: '',
  abbr: [],
  alpha2: 'PA',
  alpha3: 'PAN',
  uncode: 591,
}, {
  name: 'Papua New Guinea',
  aka: '',
  abbr: [],
  alpha2: 'PG',
  alpha3: 'PNG',
  uncode: 598,
}, {
  name: 'Paraguay',
  aka: '',
  abbr: [],
  alpha2: 'PY',
  alpha3: 'PRY',
  uncode: 600,
}, {
  name: 'Peru',
  aka: '',
  abbr: [],
  alpha2: 'PE',
  alpha3: 'PER',
  uncode: 604,
}, {
  name: 'Philippines',
  aka: '',
  abbr: [],
  alpha2: 'PH',
  alpha3: 'PHL',
  uncode: 608,
}, {
  name: 'Poland',
  aka: '',
  abbr: [],
  alpha2: 'PL',
  alpha3: 'POL',
  uncode: 616,
}, {
  name: 'Portugal',
  aka: '',
  abbr: [],
  alpha2: 'PT',
  alpha3: 'PRT',
  uncode: 620,
}, {
  name: 'Qatar',
  aka: '',
  abbr: [],
  alpha2: 'QA',
  alpha3: 'QAT',
  uncode: 634,
}, {
  name: 'Republic of Korea',
  aka: '',
  abbr: ['South Korea', 'ROK'],
  alpha2: 'KR',
  alpha3: 'KOR',
  uncode: 410,
}, {
  name: 'Republic of Moldova',
  aka: '',
  abbr: ['Moldova'],
  alpha2: 'MD',
  alpha3: 'MDA',
  uncode: 498,
}, {
  name: 'Romania',
  aka: '',
  abbr: [],
  alpha2: 'RO',
  alpha3: 'ROU',
  uncode: 642,
}, {
  name: 'Russian Federation',
  aka: '',
  abbr: ['Russia'],
  alpha2: 'RU',
  alpha3: 'RUS',
  uncode: 643,
}, {
  name: 'Rwanda',
  aka: '',
  abbr: [],
  alpha2: 'RW',
  alpha3: 'RWA',
  uncode: 646,
}, {
  name: 'Saint Kitts and Nevis',
  aka: '',
  abbr: [],
  alpha2: 'KN',
  alpha3: 'KNA',
  uncode: 659,
}, {
  name: 'Saint Lucia',
  aka: '',
  abbr: [],
  alpha2: 'LC',
  alpha3: 'LCA',
  uncode: 662,
}, {
  name: 'Saint Vincent and the Grenadines',
  aka: '',
  abbr: [],
  alpha2: 'VC',
  alpha3: 'VCT',
  uncode: 670,
}, {
  name: 'Samoa',
  aka: '',
  abbr: [],
  alpha2: 'WS',
  alpha3: 'WSM',
  uncode: 882,
}, {
  name: 'San Marino',
  aka: '',
  abbr: [],
  alpha2: 'SM',
  alpha3: 'SMR',
  uncode: 674,
}, {
  name: 'Sao Tome and Principe',
  aka: '',
  abbr: [],
  alpha2: 'ST',
  alpha3: 'STP',
  uncode: 678,
}, {
  name: 'Saudi Arabia',
  aka: '',
  abbr: [],
  alpha2: 'SA',
  alpha3: 'SAU',
  uncode: 682,
}, {
  name: 'Senegal',
  aka: '',
  abbr: [],
  alpha2: 'SN',
  alpha3: 'SEN',
  uncode: 686,
}, {
  name: 'Serbia',
  aka: '',
  abbr: [],
  alpha2: 'RS',
  alpha3: 'SRB',
  uncode: 688,
}, {
  name: 'Seychelles',
  aka: '',
  abbr: [],
  alpha2: 'SC',
  alpha3: 'SYC',
  uncode: 690,
}, {
  name: 'Sierra Leone',
  aka: '',
  abbr: [],
  alpha2: 'SL',
  alpha3: 'SLE',
  uncode: 694,
}, {
  name: 'Singapore',
  aka: '',
  abbr: [],
  alpha2: 'SG',
  alpha3: 'SGP',
  uncode: 702,
}, {
  name: 'Slovakia',
  aka: '',
  abbr: [],
  alpha2: 'SK',
  alpha3: 'SVK',
  uncode: 703,
}, {
  name: 'Slovenia',
  aka: '',
  abbr: [],
  alpha2: 'SI',
  alpha3: 'SVN',
  uncode: 705,
}, {
  name: 'Solomon Islands',
  aka: '',
  abbr: [],
  alpha2: 'SB',
  alpha3: 'SLB',
  uncode: 90,
}, {
  name: 'Somalia',
  aka: '',
  abbr: [],
  alpha2: 'SO',
  alpha3: 'SOM',
  uncode: 706,
}, {
  name: 'South Africa',
  aka: '',
  abbr: [],
  alpha2: 'ZA',
  alpha3: 'ZAF',
  uncode: 710,
}, {
  name: 'South Sudan',
  aka: '',
  abbr: [],
  alpha2: 'SS',
  alpha3: 'SSD',
  uncode: 728,
}, {
  name: 'Spain',
  aka: '',
  abbr: [],
  alpha2: 'ES',
  alpha3: 'ESP',
  uncode: 724,
}, {
  name: 'Sri Lanka',
  aka: '',
  abbr: [],
  alpha2: 'LK',
  alpha3: 'LKA',
  uncode: 144,
}, {
  name: 'Sudan',
  aka: '',
  abbr: [],
  alpha2: 'SD',
  alpha3: 'SDN',
  uncode: 736,
}, {
  name: 'Suriname',
  aka: '',
  abbr: [],
  alpha2: 'SR',
  alpha3: 'SUR',
  uncode: 740,
}, {
  name: 'Sweden',
  aka: '',
  abbr: [],
  alpha2: 'SE',
  alpha3: 'SWE',
  uncode: 752,
}, {
  name: 'Switzerland',
  aka: '',
  abbr: [],
  alpha2: 'CH',
  alpha3: 'CHE',
  uncode: 756,
}, {
  name: 'Syrian Arab Republic',
  aka: '',
  abbr: ['Syria'],
  alpha2: 'SY',
  alpha3: 'SYR',
  uncode: 760,
}, {
  name: 'Tajikistan',
  aka: '',
  abbr: [],
  alpha2: 'TJ',
  alpha3: 'TJK',
  uncode: 762,
}, {
  name: 'Thailand',
  aka: '',
  abbr: [],
  alpha2: 'TH',
  alpha3: 'THA',
  uncode: 764,
}, {
  name: 'Timor-Leste',
  aka: '',
  abbr: [],
  alpha2: 'TL',
  alpha3: 'TLS',
  uncode: 626,
}, {
  name: 'Togo',
  aka: '',
  abbr: [],
  alpha2: 'TG',
  alpha3: 'TGO',
  uncode: 768,
}, {
  name: 'Tonga',
  aka: '',
  abbr: [],
  alpha2: 'TO',
  alpha3: 'TON',
  uncode: 776,
}, {
  name: 'Trinidad and Tobago',
  aka: '',
  abbr: [],
  alpha2: 'TT',
  alpha3: 'TTO',
  uncode: 780,
}, {
  name: 'Tunisia',
  aka: '',
  abbr: [],
  alpha2: 'TN',
  alpha3: 'TUN',
  uncode: 788,
}, {
  name: 'Turkey',
  aka: '',
  abbr: [],
  alpha2: 'TR',
  alpha3: 'TUR',
  uncode: 792,
}, {
  name: 'Turkmenistan',
  aka: '',
  abbr: [],
  alpha2: 'TM',
  alpha3: 'TKM',
  uncode: 795,
}, {
  name: 'Tuvalu',
  aka: '',
  abbr: [],
  alpha2: 'TV',
  alpha3: 'TUV',
  uncode: 798,
}, {
  name: 'Uganda',
  aka: '',
  abbr: [],
  alpha2: 'UG',
  alpha3: 'UGA',
  uncode: 800,
}, {
  name: 'Ukraine',
  aka: '',
  abbr: [],
  alpha2: 'UA',
  alpha3: 'UKR',
  uncode: 804,
}, {
  name: 'United Arab Emirates',
  aka: '',
  abbr: [],
  alpha2: 'AE',
  alpha3: 'ARE',
  uncode: 784,
}, {
  name: 'United Kingdom of Great Britain and Northern Ireland',
  aka: '',
  abbr: ['United Kingdom', 'UK'],
  alpha2: 'GB',
  alpha3: 'GBR',
  uncode: 826,
}, {
  name: 'United Republic of Tanzania',
  aka: '',
  abbr: ['Tanzania'],
  alpha2: 'TZ',
  alpha3: 'TZA',
  uncode: 834,
}, {
  name: 'United States of America',
  aka: '',
  abbr: ['United States', 'USA', 'America'],
  alpha2: 'US',
  alpha3: 'USA',
  uncode: 840,
}, {
  name: 'Uruguay',
  aka: '',
  abbr: [],
  alpha2: 'UY',
  alpha3: 'URY',
  uncode: 858,
}, {
  name: 'Uzbekistan',
  aka: '',
  abbr: [],
  alpha2: 'UZ',
  alpha3: 'UZB',
  uncode: 860,
}, {
  name: 'Vanuatu',
  aka: '',
  abbr: [],
  alpha2: 'VU',
  alpha3: 'VUT',
  uncode: 548,
}, {
  name: 'Venezuela',
  aka: '',
  abbr: [],
  alpha2: 'VE',
  alpha3: 'VEN',
  uncode: 862,
}, {
  name: 'Viet Nam',
  aka: '',
  abbr: [],
  alpha2: 'VN',
  alpha3: 'VNM',
  uncode: 704,
}, {
  name: 'Yemen',
  aka: '',
  abbr: [],
  alpha2: 'YE',
  alpha3: 'YEM',
  uncode: 887,
}, {
  name: 'Zambia',
  aka: '',
  abbr: [],
  alpha2: 'ZM',
  alpha3: 'ZMB',
  uncode: 894,
}, {
  name: 'Zimbabwe',
  aka: '',
  abbr: [],
  alpha2: 'ZW',
  alpha3: 'ZWE',
  uncode: 716,
}];
