import React, {
  useState, useRef, useContext, useEffect, useCallback,
} from 'react';
import Card from 'components/basic/Card';
import _ from 'lodash';
import Icon from 'components/basic/Icon';
import { DragAndDropContext } from 'contexts';
import {
  UpdateModuleDataInputType, useModuleQuery, useSetModuleHighlightMutation, useUpdateModuleMutation,
} from 'graphql/generated/graphql';
import { ModuleInfo } from '../moduleInfo';
import './style.css'
import { DIMENSION } from 'constant';
import classes from './style.module.css';
import { isMobile } from 'react-device-detect';

export default function BaseFrame({ DID, id, highlight }: Props) {
  const { dragStatus, setDragStatus, placeDim } = useContext(DragAndDropContext);

  const { data, loading: mLoading } = useModuleQuery({ variables: { MID: id } });

  const moduleInfo = data?.module?.type ? ModuleInfo[data.module.type] : undefined;
  const {
    editable, Target, resettable, reset,
  } = moduleInfo || {
    editable: false, Target: () => <></>, resettable: false, reset: {},
  };

  const [isEdit, setEdit] = useState(false);

  const [updateModule, { loading: updateModuleLoading }] = useUpdateModuleMutation();

  const ref = useRef(null);

  const [setModuleHighlight] = useSetModuleHighlightMutation();

  const [editModuleData, setEditModuleData] = useState<UpdateModuleDataInputType>({});

  const [shouldRunSubmit, setShouldRunSubmit] = useState(false);

  const handleEditSubmit = useCallback((updateData: UpdateModuleDataInputType) => {
    if (!data || !data.module || updateModuleLoading) return;

    updateModule({ variables: { MID: id, data: updateData } })
      .then(() => {
        setEditModuleData({});
      });
  }, [data, id, updateModule, updateModuleLoading]);

  useEffect(() => {
    if (shouldRunSubmit) {
      handleEditSubmit(editModuleData);
      setShouldRunSubmit(false);
    }
  }, [editModuleData, handleEditSubmit, shouldRunSubmit]);

  const handleReset = () => {
    updateModule({ variables: { MID: id, data: reset } });
  };

  const handleSpot = () => {
    if (highlight === id) {
      setModuleHighlight({ variables: { DID, MID: null } });
    } else {
      setModuleHighlight({ variables: { DID, MID: id } });
    }
  };

  function placeDimension(vh:number) {
    if (isMobile) {
      return vh * window.innerHeight * 0.01 + 'px'
    }
    return vh + 'vh'
  };

  return (
    <div className='module-root'
        style={{
          // ** performance refactor **
          // width:  data?.module?.dim?.w && data?.module?.dim?.w * (placeDim.w * 0.25),
          // height: data?.module?.dim?.h && data?.module?.dim?.h * (placeDim.h / 2),
          // left:  data?.module?.pos?.x && data?.module?.pos?.x  * (placeDim.w * 0.25),
          // top: data?.module?.pos?.y && data?.module?.pos?.y * (placeDim.h / 2),
          width:  data?.module?.dim?.w && placeDimension(data?.module?.dim.w * DIMENSION.MODULE_W),
          height: data?.module?.dim?.h && placeDimension(data?.module?.dim.h * DIMENSION.MODULE_H),
          left:  data?.module?.pos?.x && placeDimension(data?.module?.pos.x * DIMENSION.MODULE_W),
          top: data?.module?.pos?.y && placeDimension(data?.module?.pos.y * DIMENSION.MODULE_H),
          // transition: FEATURES.SLIDING_MODULES && '0.3s opacity, 0.3s left, 0.3s top' || undefined,
          opacity:  (dragStatus.id === id ? 0.5 : 1),
        }}
        ref={ref}
    >
      <Card className="module-card">
        {isEdit && (
          <span
            className={[classes.controlButton, classes.doneButton].join(' ')}
            onClick={() => {
              handleEditSubmit(editModuleData);
              setEdit(false);
            }}
          >
            <Icon icon="done" color="white" size={24} />
          </span>
        )}

        <div
          className={['top-bar', 'topBar'].join(' ')}
          onMouseDown={() => !isMobile && setDragStatus({
            action: 'move', id, type: data?.module?.type, dim: (data?.module?.dim || { w: 0, h: 0 }),
          })}
          onTouchStart={() => {
            (window  as any).dim = (data?.module?.dim || { w: 0, h: 0 })
            setDragStatus({
              action: 'move', id, type: data?.module?.type, dim: (data?.module?.dim || { w: 0, h: 0 }),
            })
          }}
        >
          <span
            className="drag-handler"
          >
            <Icon icon="handle" color="gray" size={28} />
          </span>
          <span className="toolbox" 
            onMouseDown={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <span
              className={[classes.resetButton, 'subordinate'].join(' ')}
              onClick={() => {
                handleSpot();
              }}
            >
              <Icon
                icon="focus"
                color={
                  highlight === id
                    ? "var(--color-secondary)"
                    : 'white'
                }
                size={28}
              />
            </span>
            {editable && (
              <span
                className={[classes.editButton, 'subordinate'].join(' ')}
                onClick={() => {
                  setEdit(true);
                }}
              >
                <Icon icon="edit" color="white" size={28} />
              </span>
            )}
            {resettable && (
              <span
                className={[classes.resetButton, 'subordinate'].join(' ')}
                onClick={() => {
                  handleReset();
                }}
              >
                <Icon icon="reset" color="white" size={28} />
              </span>
            )}
          </span>
        </div>
        <div className={classes.mainView}>
          {!mLoading && data?.module?.data && (
            <Target
              realDim={{ w: (placeDim.w / 4) * (data.module.dim?.w || 0), h: (placeDim.h / 2) * (data.module.dim?.h || 0) }}
              isEdit={isEdit}
              setEdit={setEdit}
              data={data.module.data}
              setEditData={(editData) => setEditModuleData((prev) => _.merge(prev, editData))}
              dispatchUpdateModule={() => {
                setShouldRunSubmit(true);
              }}
              id={id}
            />
          )}
        </div>
        <div className={classes.triangle} style={{opacity: highlight === id ? 1 : 0}} />
      </Card>
    </div>
  );
}

type Props = {
  DID: string
  id: string
  highlight: string | undefined
};
