import React, { useEffect, useState } from 'react';
import { ICoreProps } from 'components/module/moduleDefine';
import Input from 'components/basic/Input';
import Space from 'components/basic/Space';
import QRCode from 'react-qr-code';
import Icon from 'components/basic/Icon';
import { LinkModuleData } from 'graphql/generated/graphql';
import classes from './style.module.css';

export default function MunativeLink({
  data,
  setEditData: editDispatcher,
  isEdit,
  realDim,
}: ICoreProps<LinkModuleData>) {
  // individual data area
  const [title, setTitle] = useState('DR 1.1');
  const [link, setLink] = useState('https://munative.com/');

  // Initialize/Update the module
  useEffect(() => {
    setTitle(data.title);
    setLink(data.link || '');
  }, [data]);

  // When the editing done
  useEffect(() => {
    if (isEdit) {
      editDispatcher({
        link: {
          title,
          link,
        },
      });
    }
  }, [editDispatcher, isEdit, link, title]);

  return (
    <>
      {/* Default page */}
      <div
        className="defaultView"
        style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
      >
        <Space v gap={15} />
        <Icon icon="link" color={"var(--color-primary)"} size={25} />
        <div className={classes.title}>
          {title}
        </div>
        <Space v gap={10} />
        <QRCode value={link} fgColor={"var(--color-primary)"} size={realDim.w / 2} />
        <Space v gap={10} />
        <a href={link} target="_blank" className={classes.link} rel="noreferrer">
          {link}
        </a>
      </div>

      {/* Edit page */}
      <div className={['editView', isEdit ? 'active' : ''].join(' ')}>
        <Input className={classes.titleInput} value={title} onChange={(e) => setTitle(e.target.value)} />
        <Space v gap={20} />
        <Input icon="link" value={link} onChange={(e) => setLink(e.target.value)} />
      </div>

    </>
  );
}
