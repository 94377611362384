import React from 'react';
import classes from './style.module.css'; 

/**
 * Basic Component － A normal texted button
 * @example <Button className={extendClass} onClick={()=>{...}}>Button</Button>
 */
export default function Button({
  className, children, onClick, disabled = false,
}: Props) {
  return (
    <span className={[classes.root, className].join(' ')} onClick={disabled ? () => null : onClick}>
      {children}
    </span>
  );
}

type Props = {
  className?: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
  disabled?: boolean
};
