import { useState } from 'react';
import { TimerState } from 'graphql/generated/graphql';
import useInterval from './useInterval';

export enum TimerStatus {
  NotYetStarted,
  Running,
  Paused,
  TimesUp,
}

export function useTimer(timerState: TimerState, setTimerState: (newState: Partial<TimerState>) => void) {
  const [remainTime, setRemainTime] = useState<number>(0);
  const [status, setStatus] = useState<TimerStatus>(TimerStatus.NotYetStarted);

  useInterval(() => {
    if (timerState.startTime) {
      const passedSec = (Date.now() - (new Date(timerState.startTime)).getTime()) / 1000;
      // eslint-disable-next-line no-underscore-dangle
      const _remainTime = timerState.totalTime - timerState.elapsedTime - passedSec;

      if (_remainTime > 0) {
        setStatus(TimerStatus.Running);
        setRemainTime(_remainTime);
      } else {
        setStatus(TimerStatus.TimesUp);
        setRemainTime(0);
      }
    } else {
      setRemainTime(timerState.totalTime - timerState.elapsedTime);

      if (timerState.elapsedTime === 0) {
        setStatus(TimerStatus.NotYetStarted);
      } else {
        setStatus(TimerStatus.Paused);
      }
    }
  }, 50);

  function play() {
    const newState: Partial<TimerState> = {
      startTime: (new Date()).toISOString(),
    };

    setTimerState(newState);
    return newState;
  }

  // eslint-disable-next-line consistent-return
  function pause() {
    if (timerState.startTime) {
      const passed = Math.round((Date.now() - (new Date(timerState.startTime)).getTime()) / 1000);

      const newState: Partial<TimerState> = {
        startTime: null,
        elapsedTime: timerState.elapsedTime + passed,
      };
      setTimerState(newState);
      return newState;
    }
  }

  function reset() {
    const newState: Partial<TimerState> = {
      startTime: null,
      elapsedTime: 0,
    };

    setTimerState(newState);
    return newState;
  }

  function addTime(sec: number) {
    const newTotalTime = timerState.totalTime + sec;

    setTimerState({
      totalTime: newTotalTime,
    });
  }

  return {
    remainTime,
    status,
    totalTime: timerState.totalTime,
    play,
    pause,
    reset,
    addTime,
  };
}
