import React, { useState } from 'react';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import HeaderBar from 'components/section/HeaderBar/index';
import { Theme } from 'theme';
import TabController from 'components/basic/TabView/TabController/index';
import Tab from 'components/basic/TabView/Tab/index';
import FullScreenWarning from 'components/basic/FullScreenWarning';
import { useRoomInfoQuery } from 'graphql/generated/graphql';
import { RoomContext } from 'contexts';
import Modal from 'components/basic/Modal';
import moment from 'moment';
import Button from 'components/basic/Button';
import Space from 'components/basic/Space/index';
import ReactGA from 'react-ga';
import StatusPanel from './panel/StatusPanel/index';
import RoomPanel from './panel/RoomPanel/index';
import DisplayPanel from './panel/DisplayPanel/index';
import classes from './style.module.css';

function PanelSelector() {
  const { tab } = useParams<{ tab: string }>();
  const [indexDropdown, setIndexDropdown] = useState(0);

  switch (tab) {
    case 'display':
      return <DisplayPanel />;
    case 'setting':
      return <RoomPanel />;
    case 'status':
      return <StatusPanel indexDropdown={indexDropdown} setIndexDropdown={setIndexDropdown} />;
    default:
      throw new Error(`Unknown panel tab: ${tab}!`);
  }
}

export default function HostDashboard() {

  const history = useHistory();

  const { roomId } = useParams<{ roomId: string, tab: string }>();
  const { data, loading } = useRoomInfoQuery({ variables: { RID: roomId } });

  const [selectedId, setSelectedId] = useState('');

  const token = localStorage.getItem('host-token');

  if (!token) history.replace('/');

  function logout() {
    localStorage.removeItem('host-token');
    localStorage.removeItem('room-id');
    history.replace('/');
  }

  function mockPurchase() {
    ReactGA.event({
      category: 'Payment',
      action: 'Purchase More Hours',
      value: 1,
    });
    // eslint-disable-next-line no-alert
    alert("Thank you for supporting Munative. We haven't provided payment services. Please create a new room. Thank you.");
    localStorage.removeItem('host-token');
    localStorage.removeItem('room-id');
    history.replace('/');
  }

  if (loading) return <>Loading</>;
  if (!data?.room) {
    localStorage.removeItem('host-token');
    localStorage.removeItem('room-id');
    return (
      <>
        Not Found
        <Redirect to="/" />
      </>
    );
  } return (
    <RoomContext.Provider value={{
      _id: data.room._id,
      name: data.room.name,
      joinCode: data.room.joinCode,
      expireTime: new Date(data.room.expireTime),
      timeExpired: data.room.timeExpired,
      selectedId,
      setSelectedId,
    }}
    >
      <div className={classes.root}>
        <HeaderBar mode="host" />
        <div className={classes.dashboardArea}>
          <div className={classes.panelArea}>
            <PanelSelector />
          </div>
          <TabController>
            <Tab icon="display" color={"var(--color-primary)"}>
              Display
            </Tab>
            <Tab icon="committee" color={"var(--color-secondary)"}>
              Room
            </Tab>
            <Tab icon="status" color={"var(--color-dark)"}>
              Status
            </Tab>
          </TabController>
        </div>
      </div>
      <Modal isShow={data.room.timeExpired} setShow={() => { }} width="calc(50vw - 100px)">
        <div className={classes.expiredContent}>
          <h1>Times up!</h1>
          This room expired
          {' '}
          {moment(data.room.expireTime).fromNow()}
          .
          <br />
          Please create a new room or purchase more hours.
        </div>
        <br />
        <Button onClick={() => logout()}>Create A New Room</Button>
        <Space gap={10} h />
        <Button onClick={() => mockPurchase()}>Purchase More Hours</Button>
      </Modal>
      {/* <FullScreenWarning
        trigger={"device"}
        priority={20}
        title="Mobile devices are not supported."
        content={
          (
            <>
              Munative Lite is currently unavailable for mobile devices.
              <br />
              To have a better experience, please use Munative Lite on your PC or laptop and make
              sure the window is maximized.
            </>
          )
        }
      /> */}
    </RoomContext.Provider>
  );
}
