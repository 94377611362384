import React from 'react';
import { IDimension } from 'declarations';
import { ModuleType } from 'graphql/generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { IDragStatus } from 'components/section/DragLayer/IDragStatus';

export const DragAndDropContext = React.createContext<IDragAndDropContext>({
  dragStatus: { action: 'move', type: ModuleType.Text },
  setDragStatus: () => { },
  placeDim: { w: 0, h: 0 },
  setPlaceDim: () => { },
  ready: false,
  setReady: () => { },
});

export interface IDragAndDropContext {
  dragStatus: IDragStatus
  setDragStatus: (dragStatus: IDragStatus) => void
  placeDim: IDimension
  setPlaceDim: (offset: IDimension) => void
  ready: boolean
  setReady: (ready: boolean) => void
}

export const RoomContext = React.createContext<IRoomContext>({
  _id: '',
  name: '',
  joinCode: '',
  expireTime: new Date(),
  timeExpired: false,
  selectedId: '_id',
  setSelectedId: () => { },
});

export interface IRoomContext {
  _id: string
  name: string
  joinCode: Maybe<string> | undefined
  expireTime: Date
  timeExpired: boolean
  selectedId: string,
  setSelectedId: Function
}
