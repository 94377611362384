import React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'theme';

type ClassNames = 'hSpace' | 'vSpace';

type StyleProps = {
  gap: number
};

const useStyles = createUseStyles<ClassNames, StyleProps, Theme>({
  hSpace: { display: 'inline-block', width: ({ gap }) => gap, flexShrink: 0 },
  vSpace: { display: 'block', height: ({ gap }) => gap, flexShrink: 0 },
});

/**
 * Basic Component － Space area to separate two components
 * @example <Space gap={20} h />
 * @example <Space gap={20} v />
 */
export default function Space({ gap = 10, h, v }: Props) {
  const classes = useStyles({ gap });
  if (!(h || v)) throw new Error('There should be at least one orientation to space the area!');
  if (h) return <span className={classes.hSpace} />;
  return <span className={classes.vSpace} />;
}

type Props = {
  gap?: number
  h?: any
  v?: any
};
