import { IModuleInfo } from 'components/module/moduleDefine';
import MunativeText from 'components/module/core/Munative/Text';
import MunativeVote from 'components/module/core/Munative/Vote';
import MunativeTimer from 'components/module/core/Munative/Timer/index';
import MunativeRollCall from 'components/module/core/Munative/RollCall/index';
import { AmendmentType, MotionType, SpeakerStanceType } from 'graphql/generated/graphql';
import MunativeLink from './core/Munative/Link/index';
import MunativeAmendment from './core/Munative/Amendment/index';
import MunativeMotion from './core/Munative/Motion/index';
import MunativeSpeakersList from './core/Munative/SpeakersList/index';

export const ModuleInfo: {
  [index: string]: IModuleInfo<any>
} = {
  rollCall: {
    scaleMode: 0,
    editable: false,
    resettable: false,
    name: 'Roll call',
    icon: 'hand',
    description: "Create a roll call and display everyone's status.",
    Target: MunativeRollCall,
    default: {
      dim: { w: 2, h: 2 },
      data: {
        rollCall: {
          _id: '', title: '', time: (new Date()).toISOString(), records: [], finished: false,
        },
      },
    },
    input: {},
    reset: {},
  },
  speakersList: {
    scaleMode: 0,
    editable: false,
    resettable: true,
    name: "Speakers' List",
    icon: 'list',
    description: "Create a speakers' list to record speakers.",
    Target: MunativeSpeakersList,
    default: {
      dim: { w: 1, h: 2 },
      data: {
        title: "Speakers' List",
        timerState: {
          startTime: null,
          totalTime: 60,
          elapsedTime: 0,
        },
        yield: null,
        stance: SpeakerStanceType.Neutral,
        currentSpeaker: null,
        list: [],
        totalTime: 60,
        speakerTime: 0,
      },
    },
    input: {
      speakersList: {
        title: "Speakers' List",
        totalTime: 60,
        speakerTime: 60,
      },
    },
    reset: {
      speakersList: {
        title: "Speakers' List",
        timerState: {
          startTime: null,
          totalTime: 60,
          elapsedTime: 0,
        },
        yield: null,
        stance: SpeakerStanceType.Neutral,
        currentSpeaker: null,
        list: [],
        totalTime: 0,
        speakerTime: 0,
      },
    },
  },
  vote: {
    scaleMode: 0,
    editable: true,
    resettable: true,
    Target: MunativeVote,
    name: 'Vote',
    icon: 'vote',
    description: 'Record and display the vote count.',
    default: {
      dim: { w: 1, h: 1 },
      data: {
        title: 'Vote',
        voteStatus: null,
        votes: {
          for: 0,
          against: 0,
          abstain: 0,
        },
        isVoteShown: false,
      },
    },
    input: {
      vote: {
        title: 'Vote',
      },
    },
    reset: {
      vote: {
        title: 'Vote',
        votes: {
          for: 0,
          against: 0,
          abstain: 0,
        },
        voteStatus: null,
      },
    },
  },
  timer: {
    scaleMode: 0,
    editable: true,
    resettable: true,
    Target: MunativeTimer,
    name: 'Timer',
    icon: 'timer',
    description: 'A countdown timer.',
    default: {
      dim: { w: 1, h: 1 },
      data: {
        title: 'Timer',
        state: {
          totalTime: 10,
          startTime: null,
          elapsedTime: 0,
        },
        shouldAlert: false,
      },
    },
    input: {
      timer: {
        title: 'Timer',
        totalTime: 10,
      },
    },
    reset: {
      timer: {
        title: 'Timer',
        state: {
          totalTime: 10,
          startTime: null,
          elapsedTime: 0,
        },
        shouldAlert: false,
      },
    },
  },
  text: {
    scaleMode: 0,
    editable: true,
    resettable: true,
    name: 'Text',
    icon: 'text',
    description: 'Write and display any text information.',
    Target: MunativeText,
    default: {
      dim: { w: 2, h: 1 },
      data: {
        content: 'Text',
      },
    },
    input: {
      text: {
        content: 'Text',
      },
    },
    reset: {
      text: {
        content: 'Text',
      },
    },
  },
  link: {
    scaleMode: 0,
    editable: true,
    resettable: true,
    name: 'Link',
    icon: 'link',
    description: 'Display a QR code for your link.',
    Target: MunativeLink,
    default: {
      dim: { w: 1, h: 1 },
      data: {
        title: 'Link',
        link: 'https://munative.com/',
      },
    },
    input: {
      link: {
        title: 'Link',
        link: 'https://munative.com/',
      },
    },
    reset: {
      link: {
        title: 'Link',
        link: 'https://munative.com/',
      },
    },
  },
  amendment: {
    scaleMode: 0,
    editable: true,
    resettable: true,
    name: 'Amendment',
    icon: 'amendment',
    description: 'Display an amendment in text form and its submitters.',
    Target: MunativeAmendment,
    default: {
      dim: { w: 2, h: 1 },
      data: {
        type: AmendmentType.Add,
        clauses: '1.0.0',
        submitters: [],
        content: '',
      },
    },
    input: {
      amendment: {
        amendmentType: AmendmentType.Add,
        clauses: '',
        content: '',
      },
    },
    reset: {
      amendment: {
        type: AmendmentType.Add,
        submitters: [],
        clauses: '',
        content: '',
      },
    },
  },
  motion: {
    scaleMode: 0,
    editable: true,
    resettable: true,
    name: 'Motion',
    icon: 'motion',
    description: 'Create a motion that includes a total timer and speakers timer if needed.',
    Target: MunativeMotion,
    default: {
      dim: { w: 1, h: 1 },
      data: {
        type: MotionType.Mod,
        customMotionType: '',
        topic: 'Topic',
        totalTime: {
          sec: 0,
          isDisplay: true,
        },
        speakerTime: {
          sec: 0,
          isDisplay: true,
        },
      },
    },
    input: {
      motion: {
        motionType: MotionType.Mod,
        customMotionType: '',
        topic: 'Topic',
        totalTime: {
          sec: 0,
          isDisplay: true,
        },
        speakerTime: {
          sec: 0,
          isDisplay: true,
        },
      },
    },
    reset: {
      motion: {
        type: MotionType.Mod,
        customMotionType: '',
        submitter: null,
        topic: 'Topic',
        totalTime: {
          sec: 0,
          isDisplay: true,
        },
        speakerTime: {
          sec: 0,
          isDisplay: true,
        },
      },
    },
  },
};
