import React, {
  useState, useEffect, useRef,
} from 'react';
import * as R from 'ramda';
import TallySheetRow from 'components/section/TallySheet/TallySheetRow';
import {
  useRoomIndividualsQuery,
  useRoomTallySheetQuery,
  TallySheetItemCreatedSubscription,
  TallySheetItemCreatedSubscriptionVariables,
  TallySheetItemUpdatedSubscription,
  TallySheetItemUpdatedSubscriptionVariables,
  TallySheetItemDeletedSubscription,
  TallySheetItemDeletedSubscriptionVariables,
  BatchIndividualCreatedSubscription,
  BatchIndividualCreatedSubscriptionVariables,
  BatchIndividualUpdatedSubscription,
  BatchIndividualUpdatedSubscriptionVariables,
  BatchIndividualDeletedSubscription,
  BatchIndividualDeletedSubscriptionVariables,
  useCreateTallySheetItemMutation,
} from 'graphql/generated/graphql';
import { useParams } from 'react-router-dom';
import Icon from 'components/basic/Icon';
import Space from 'components/basic/Space';
import {
  TALLY_SHEET_ITEM_CREATED_SUBSCRIPTION,
  TALLY_SHEET_ITEM_UPDATED_SUBSCRIPTION,
  TALLY_SHEET_ITEM_DELETED_SUBSCRIPTION,
  BATCH_INDIVIDUAL_CREATED_SUBSCRIPTION,
  BATCH_INDIVIDUAL_UPDATED_SUBSCRIPTION,
  BATCH_INDIVIDUAL_DELETED_SUBSCRIPTION,
} from 'graphql/subscriptions';
import TallySheetHeader from './TallySheetHeader';
import classes from './style.module.css';

export default function TallySheet() {
  const { roomId } = useParams<{ roomId: string }>();

  const [isEdit, setEdit] = useState(false);

  const { data: TData, subscribeToMore: TSubscribeToMore } = useRoomTallySheetQuery({ variables: { RID: roomId } });
  const {
    data: IData, subscribeToMore: ISubscribeToMore, refetch,
  } = useRoomIndividualsQuery({ variables: { RID: roomId } });

  const [createItem, { loading: CLoading }] = useCreateTallySheetItemMutation({ variables: { RID: roomId } });

  // subscribe to tallysheet items
  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubsTallySheetItemCreated = TSubscribeToMore<TallySheetItemCreatedSubscription, TallySheetItemCreatedSubscriptionVariables>({
      document: TALLY_SHEET_ITEM_CREATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const createdTallySheetItem = subscriptionData.data.tallySheetItemCreated;
        if (createdTallySheetItem) {
          refetch();
          return {
            ...prev,
            room: prev.room ? {
              ...prev.room,
              tallySheetItems: R.append(createdTallySheetItem, prev.room.tallySheetItems),
            } : prev.room,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsTallySheetItemCreated);

    const unSubsTallySheetItemUpdated = TSubscribeToMore<TallySheetItemUpdatedSubscription, TallySheetItemUpdatedSubscriptionVariables>({
      document: TALLY_SHEET_ITEM_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedTallySheetItem = subscriptionData.data.tallySheetItemUpdated;
        const existingIndex = R.findIndex(R.propEq('_id', updatedTallySheetItem?._id))(prev.room?.tallySheetItems || []);

        if (updatedTallySheetItem) {
          refetch();
          return {
            ...prev,
            room: prev.room ? {
              ...prev.room,
              tallySheetItems: R.update(existingIndex, updatedTallySheetItem, prev.room.tallySheetItems),
            } : prev.room,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsTallySheetItemUpdated);

    const unSubsTallySheetItemDeleted = TSubscribeToMore<TallySheetItemDeletedSubscription, TallySheetItemDeletedSubscriptionVariables>({
      document: TALLY_SHEET_ITEM_DELETED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const deletedTallySheetItem = subscriptionData.data.tallySheetItemDeleted;
        if (deletedTallySheetItem) {
          refetch();
          return {
            ...prev,
            room: prev.room ? {
              ...prev.room,
              tallySheetItems: R.without([deletedTallySheetItem], prev.room.tallySheetItems),
            } : prev.room,
          };
        }
        return prev;
      },
    });

    unSubs.push(unSubsTallySheetItemDeleted);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [TSubscribeToMore, refetch, roomId]);

  // subscribe to individuals
  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubIndividualCreated = ISubscribeToMore<BatchIndividualCreatedSubscription, BatchIndividualCreatedSubscriptionVariables>({
      document: BATCH_INDIVIDUAL_CREATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const createdIndividuals = subscriptionData.data.batchIndividualCreated;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            individuals: R.concat(prev.room.individuals, createdIndividuals),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubIndividualCreated);

    const unSubIndividualUpdated = ISubscribeToMore<BatchIndividualUpdatedSubscription, BatchIndividualUpdatedSubscriptionVariables>({
      document: BATCH_INDIVIDUAL_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedIndividuals = subscriptionData.data.batchIndividualUpdated;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            individuals: R.reduce((acc, elem) => {
              const existingIndex = R.findIndex(R.propEq('_id', elem._id))(acc);

              if (existingIndex === -1) return acc;
              return R.update(existingIndex, elem, acc);
            }, prev.room.individuals, updatedIndividuals),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubIndividualUpdated);

    const unSubIndividualDeleted = ISubscribeToMore<BatchIndividualDeletedSubscription, BatchIndividualDeletedSubscriptionVariables>({
      document: BATCH_INDIVIDUAL_DELETED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const deletedIndividuals = subscriptionData.data.batchIndividualDeleted;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            individuals: R.without(deletedIndividuals, prev.room.individuals),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubIndividualDeleted);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [ISubscribeToMore, roomId]);

  const isActed = useRef(false);

  useEffect(() => {
    if (isActed.current && !CLoading) {
      isActed.current = false;
    }
  }, [CLoading]);

  function handleCreateItem() {
    createItem({ variables: { RID: roomId, name: 'Item' } });
    isActed.current = true;
  }

  const sum: number[] = TData?.room?.tallySheetItems ? TData.room.tallySheetItems.map(() => 0) : [];

  if (IData?.room?.individuals) {
    IData.room.individuals.forEach(
      (idv) => idv.tallySheet.forEach(
        (tally, i) => {
          sum[i] += tally.value;
        },
      ),
    );
  }

  return (
    <div className={classes.root}>
      <span className={isEdit ? classes.editButtonEditing : classes.editButton} onClick={() => setEdit(!isEdit)}>
        <Icon icon={isEdit ? 'done' : 'edit'} size={30} color="white" />
      </span>
      <TallySheetHeader isEdit={isEdit} columns={TData?.room?.tallySheetItems || []} onCreate={() => handleCreateItem()} />
      <div className={classes.sumRow}>
        <span className={classes.title}>
          <Space h gap={60} />
          Total
        </span>
        {sum.map((value) => <span className={classes.item}>{value}</span>)}
        {isEdit && <Space gap={40} h />}
      </div>
      <div className={classes.list} style={{height: isEdit ? 'calc(100% - 140px)' : 'calc(100% - 80px)',}}>
        {IData?.room?.individuals.map((i) => <TallySheetRow key={i._id} isEdit={isEdit} values={i.tallySheet} individual={i} />)}
      </div>
    </div>
  );
}
