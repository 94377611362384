import React from 'react';
import { RollCallModuleDataFragment } from 'graphql/generated/graphql';
import { ICoreProps } from 'components/module/moduleDefine';
import RollCallList from 'components/composed/RollCallList/index';
import { DIMENSION } from 'constant';

export default function MunativeRollCall({
  data,
  audience,
  realDim,
}: ICoreProps<RollCallModuleDataFragment>) {
  return (
    <div
      className="defaultView"
    >
      <RollCallList 
        record={data.rollCall.records} 
        id={data.rollCall._id} 
        audience={audience} 
        height={audience 
                ? `calc(${DIMENSION.A_MODULE_H * 2}vh - 100px)` 
                : `calc(${DIMENSION.MODULE_H * 2}vh - 100px)` 
              } 
      />
    </div>
  );
}
