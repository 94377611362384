import React, { useState, useEffect } from 'react';
import { ReactComponent as Logo } from 'assets/logo.svg';
import Space from 'components/basic/Space';
import Input from 'components/basic/Input';
import { useHistory } from 'react-router-dom';
import {
  useCreateRoomMutation, useRoomInfoLazyQuery,
} from 'graphql/generated/graphql';
import Modal from 'components/basic/Modal';
import Button from 'components/basic/Button';
import classes from './style.module.css';


export default function LandingPage() {
  const history = useHistory();
  const [name, setName] = useState('');
  const [codeIncorrectShow, setCodeIncorrectShow] = useState(false);

  const [roomCode, setRoomCode] = useState('');

  const token = localStorage.getItem('host-token');
  const roomId = localStorage.getItem('room-id');

  if (token && roomId) {
    history.replace(`/room/${roomId}`);
  }

  const [createRoom, { loading, data }] = useCreateRoomMutation({ variables: { name, email: '' } });
  const [getRoom, { loading: codeLoading, data: codeData }] = useRoomInfoLazyQuery();

  useEffect(() => {
    if (!loading && data) {
      localStorage.setItem('host-token', data.createRoom.token);
      localStorage.setItem('room-id', data.createRoom.room._id);
      history.replace(`/room/${data.createRoom.room._id}/setting`);
    }
  }, [data, history, loading]);

  function handleCreate() {
    if (name.length > 0) {
      // ReactGA.event({
      //   category: GACategory.LOGIN,
      //   action: GAAction.CREATE,
      //   label: 'Create Room',
      // });
      createRoom();
    }
  }

  function enterByCode() {
    // ReactGA.event({
    //   category: GACategory.LOGIN,
    //   action: GAAction.CLICK,
    //   label: 'Join Room',
    // });
    getRoom({ variables: { joinCode: roomCode } });
  }

  useEffect(() => {
    if (!codeLoading && codeData) {
      if (codeData.room?._id) history.push(`/audience/${codeData.room._id}`);
      else setCodeIncorrectShow(true);
    }
  }, [codeLoading, codeData, history]);

  return (
    <div className={classes.root}>
      <div className={classes.hostPart}>
        <Logo className={classes.logo} />
        <div className={classes.centerBlock}>
          <div style={{ color: 'white', fontSize: 60 }}>Chair</div>
          <div style={{ color: 'white', fontSize: 18 }}>Managing my committees</div>
          <Space v gap={40} />

          <Input
            icon="edit"
            value={name}
            style={{ padding: '5px 20px' }}
            onFocus={() => {
              // ReactGA.event({
              //   category: GACategory.LOGIN,
              //   action: GAAction.EDIT,
              //   label: 'Room Name',
              // });
            }}
            onChange={(e) => setName(e.target.value)}
            placeholder="Room Name*"
          />
          {/* <Space v gap={10} />
          <Input
            icon="email"
            value={email}
            style={{ padding: '10 20' }}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => {
              ReactGA.event({
                category: GACategory.LOGIN,
                action: GAAction.EDIT,
                label: 'Recovery Email',
              });
            }}
            placeholder="Recovery Email"
          />
          <p style={{ color: 'white', fontSize: 14 }}>
            We will send you an email with a recovery link.
            <br />
            You can return to the room at any time.
          </p> */}
          <Space v gap={30} />

          <div
            className={[classes.button, classes.start].join(' ')}
            style={{ pointerEvents: name.length === 0 || loading ? 'none' : 'auto' }}
            onClick={handleCreate}
          >
            {name.length === 0 ? 'Room Name Required' : loading
              ? (
                <div className={['lds-ellipsis', classes.primary].join(' ')}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )
              : 'Start'}
          </div>
        </div>
      </div>
      <div className={classes.audiencePart}>
        <div className={classes.centerBlock}>
          <div style={{ color: "var(--color-primary)", fontSize: 60 }}>Audience</div>
          <div style={{ color: "var(--color-primary)", fontSize: 18 }}>Participating a committee</div>
          <Space v gap={30} />
          <Input
            icon="pin_code"
            value={roomCode}
            style={{ padding: '5px 20px' }}
            onChange={(e) => setRoomCode(e.target.value)}
            onFocus={() => {
              // ReactGA.event({
              //   category: GACategory.LOGIN,
              //   action: GAAction.EDIT,
              //   label: 'Join Code',
              // });
            }}
            placeholder="Join Code"
          />
          <Space v gap={30} />
          <div className={[classes.button, classes.join].join(' ')} onClick={enterByCode}>Join</div>
        </div>
      </div>
      <Modal isShow={codeIncorrectShow} setShow={setCodeIncorrectShow} width="calc(50vw - 100px)">
        <div className={classes.incorrectContent}>
          <h1>Incorrect Join Code!</h1>
          Please try other codes or create a new room.
        </div>
        <br />
        <Button onClick={() => setCodeIncorrectShow(false)}>Got it</Button>
      </Modal>
    </div>
  );
}
