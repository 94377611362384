import * as R from 'ramda';
import React, {
  useState, useEffect, MouseEventHandler,
} from 'react';
import Icon from 'components/basic/Icon';
import RollCallList from 'components/composed/RollCallList';
import Space from 'components/basic/Space/index';
import { useParams } from 'react-router-dom';
import { Formate } from 'utils/time';
import moment from 'moment';
import {
  RollCallState,
  useRoomRollCallQuery,
  RollCallCreatedSubscription,
  RollCallCreatedSubscriptionVariables,
  RollCallUpdatedSubscription,
  RollCallUpdatedSubscriptionVariables,
  RollCallDeletedSubscription,
  RollCallDeletedSubscriptionVariables,
} from 'graphql/generated/graphql';
import {
  ROLL_CALL_UPDATED_SUBSCRIPTION,
  ROLL_CALL_CREATED_SUBSCRIPTION,
  ROLL_CALL_DELETED_SUBSCRIPTION,
} from '../../../graphql/subscriptions';
import classes from './style.module.css';

function RollCallButton({ active, value, onClick }: ItemProps) {
  return <span 
          className={classes.rollCallButton} 
          onClick={onClick}
          style={{
            backgroundColor: active ? "var(--color-primary)" : 'white',
            color: active ? 'white' : "var(--color-primary)",
          }}
        >
            {value}
        </span>;
}

type ItemProps = {
  active: boolean
  value: number
  onClick: MouseEventHandler
};

export default function RollCall() {
  const { roomId } = useParams<{ roomId: string }>();

  const { data, subscribeToMore } = useRoomRollCallQuery({ variables: { RID: roomId } });

  const [current, setCurrent] = useState(-1);

  useEffect(() => {
    const unSubs: { (): void; }[] = [];

    const unSubRollCallCreated = subscribeToMore<RollCallCreatedSubscription, RollCallCreatedSubscriptionVariables>({
      document: ROLL_CALL_CREATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const createdRollCall = subscriptionData.data.rollCallCreated;
        if (!createdRollCall) return prev;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            rollCalls: R.append(createdRollCall, prev.room.rollCalls),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubRollCallCreated);

    const unSubRollCallUpdated = subscribeToMore<RollCallUpdatedSubscription, RollCallUpdatedSubscriptionVariables>({
      document: ROLL_CALL_UPDATED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedRollCall = subscriptionData.data.rollCallUpdated;
        if (!updatedRollCall) return prev;

        const existingIndex = R.findIndex(R.propEq('_id', updatedRollCall._id))(prev.room?.rollCalls || []);

        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            rollCalls: R.update(existingIndex, updatedRollCall, prev.room.rollCalls),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubRollCallUpdated);

    const unSubRollCallDeleted = subscribeToMore<RollCallDeletedSubscription, RollCallDeletedSubscriptionVariables>({
      document: ROLL_CALL_DELETED_SUBSCRIPTION,
      variables: { RID: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        const deletedRollCall = subscriptionData.data.rollCallDeleted;
        if (!deletedRollCall) return prev;
        return {
          ...prev,
          room: prev.room ? {
            ...prev.room,
            rollCalls: R.without([deletedRollCall], prev.room.rollCalls),
          } : prev.room,
        };
      },
    });

    unSubs.push(unSubRollCallDeleted);

    return () => {
      unSubs.forEach((f) => {
        f();
      });
    };
  }, [roomId, subscribeToMore]);

  useEffect(() => {
    if (data?.room?.rollCalls && current === -1) {
      setCurrent(data.room.rollCalls.length - 1);
    }
  }, [current, data?.room?.rollCalls]);

  const aValue = data?.room?.rollCalls[current]?.records
    .filter((r) => r.state === RollCallState.Absent).length || 0;
  const pnvValue = data?.room?.rollCalls[current]?.records
    .filter((r) => r.state === RollCallState.PresentAndVoting).length || 0;
  const pValue = data?.room?.rollCalls[current]?.records
    .filter((r) => r.state === RollCallState.Present).length || 0;
  const totalValue = aValue + pnvValue + pValue || 1;

  return (
    <div className={classes.root}>
      <div className={classes.splitted}>
        <Space v gap={80} />
        <div className={classes.itemList}>
          {data?.room?.rollCalls.map((r, i) => <RollCallButton active={current === i} value={i + 1} onClick={() => setCurrent(i)} />)}
        </div>
        <Space v gap={30} />
        {current > -1 && (
          <>
            <div className={classes.title}>
              #
              {current + 1}
              <Space h gap={20} />
              <div>{moment(new Date(data!.room!.rollCalls[current].time)).fromNow()}</div>
            </div>
            <Space h gap={20} />
            <div className={classes.result}>
              <div className={classes.sum}>
                <Icon className={classes.invert} icon="bracket" size={100} />
                {' '}
                <span>{pnvValue + pValue}</span>
              </div>
              <div className={classes.resultItem} style={{ color: "var(--color-primary)" }}>
                Present
                {' '}
                <span style={{ background: "var(--color-primary)" }}>
                  {pValue}
                  {' '}
                  (
                  {Math.round((pValue / totalValue) * 100)}
                  %)
                </span>
              </div>
              <div className={classes.resultItem} style={{ color: "var(--color-secondary)" }}>
                Present And Voting
                <span style={{ background: "var(--color-secondary)" }}>
                  {pnvValue}
                  {' '}
                  (
                  {Math.round((pnvValue / totalValue) * 100)}
                  %)
                </span>
              </div>
              <div className={classes.resultItem} style={{ color: 'red' }}>
                Absent
                {' '}
                <span style={{ background: 'red' }}>
                  {aValue}
                  {' '}
                  (
                  {Math.round((aValue / totalValue) * 100)}
                  %)
                </span>
              </div>
              <Space v gap={40} />
              <div className={classes.timestamp}>
                {/* 2021/6/16 PM 10:05:13 */}
                {Formate(new Date(data!.room!.rollCalls[current].time), 'yyyy/MM/dd hh:mm:ss')}
              </div>
            </div>
          </>
        )}
      </div>

      <Space h gap={20} />
      <div className={classes.splitted}>
        {data?.room && current > -1
          && (
            <RollCallList
              record={data!.room!.rollCalls[current].records}
              id={data!.room!.rollCalls[current]._id}
            />
          )}
      </div>
    </div>
  );
}
