import React, { useEffect, useRef, useState } from 'react';
import RatioGraph from 'components/composed/RatioGraph/index';
import Input from 'components/basic/Input/index';
import { ICoreProps } from 'components/module/moduleDefine';
import Space from 'components/basic/Space/index';
import IconButton from 'components/basic/IconButton';
import { VoteModuleData, VoteStatus } from 'graphql/generated/graphql';
import classes from './style.module.css';

function NumberInput({ value, setValue }: InputProps) {
  const me = useRef<HTMLInputElement>(null);

  function changeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    if (parseInt(val, 10) < 0) {
      setValue(0);
    } else if (!Number.isNaN(parseInt(val, 10))) {
      setValue(parseInt(val, 10));
    } else if (val === '') {
      setValue(0);
      me.current!.select();
    }
  }

  return (
    <Input
      ref={me}
      type="number"
      value={value.toString()}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e)}
      style={{ width: '30%' }}
      alignment="center"
    />
  );
}

type InputProps = {
  value: number
  setValue: (value: number) => void
};

export default function MunativeVote({
  data,
  setEditData,
  dispatchUpdateModule,
  isEdit,
  setEdit,
  realDim, audience,
}: ICoreProps<VoteModuleData>) {
  const [title, setTitle] = useState('Vote');
  const [For, setFor] = useState(0);
  const [Against, setAgainst] = useState(0);
  const [Abstain, setAbstain] = useState(0);
  const titleRef = useRef(null);

  const thick = realDim.w * 0.12;
  const width = realDim.w * 0.7;
  const valueTextSize = realDim.w * 0.05;
  const resultButtonTextSize = realDim.w * 0.075;
  const actionSpacing = realDim.w * 0.075;

  useEffect(() => {
    setTitle(data.title);
    setFor(data.votes.for);
    setAgainst(data.votes.against);
    setAbstain(data.votes.abstain);
  }, [data]);

  useEffect(() => {
    if (isEdit) {
      setEditData({
        vote: {
          title,
          votes: {
            for: For,
            against: Against,
            abstain: Abstain,
          },
        },
      });
    }
  }, [isEdit, setEditData, title, Abstain, Against, For]);

  function setResult(result: VoteStatus) {
    setEditData({
      vote: {
        voteStatus: result,
      },
    });

    dispatchUpdateModule();
  }

  if (!audience) {
    return (
      <>
        <div className={classes.title} ref={titleRef}>
          {title}
        </div>
        {Abstain === 0 && For === 0 && Against === 0 ? (
          <div className="defaultView">
            <RatioGraph
              datas={[{ key: 'none', value: 100, color: '#9B9B9B' }]}
              thick={thick}
              withText={false}
              width={width}
            >
              <div className={classes.votesDisplay}>
                <div className={classes.abstainText} style={{fontSize: valueTextSize}}>Pending</div>
              </div>
            </RatioGraph>
            <Space v gap={actionSpacing} />
            <div className={classes.vacantHint} style={{fontSize: valueTextSize}}>
              Please enter the vote count
              <div 
                className={classes.hereHref} 
                style={{ color: "var(--color-primary)"}} 
                onClick={() => setEdit(true)}>
                Here
              </div>
            </div>
          </div>
        ) : (
          <div className="defaultView">
            <RatioGraph
              thick={thick}
              datas={[
                { key: 'for', value: For, color: '#1989FF' },
                { key: 'against', value: Against, color: '#FFAE65' },
                { key: 'abstain', value: Abstain, color: '#9B9B9B' },
              ]}
              width={width}
            >
              <div className={classes.votesDisplay}>
                <div className={classes.forText} style={{fontSize: valueTextSize}}>
                  For
                  {For}
                </div>
                <div className={classes.againstText} style={{fontSize: valueTextSize}}>
                  Against
                  {Against}
                </div>
                <div className={classes.abstainText} style={{fontSize: valueTextSize}}>
                  Abstain
                  {Abstain}
                </div>
              </div>
            </RatioGraph>
            <Space v gap={actionSpacing} />
            <div className={classes.actionTab}>
              <span 
                className={[classes.button, classes.failed, (data.voteStatus === VoteStatus.Fail && classes.active)].join(' ')} 
                onClick={() => setResult(VoteStatus.Fail)}
                style={{fontSize: resultButtonTextSize}}
              >
                Fail
              </span>
              <span 
                className={[classes.button, classes.passed,( data.voteStatus === VoteStatus.Pass && classes.active)].join(' ')} 
                onClick={() => setResult(VoteStatus.Pass)}
                style={{fontSize: resultButtonTextSize}}
              >
                Pass
              </span>
            </div>
          </div>
        )}

        <div className={['editView', isEdit ? 'active' : ''].join(' ')}>
          <Input
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            style={{ width: '70%' }}
            alignment="center"
          />
          <div className={classes.form}>
            <div className={classes.row}>
              <span style={{ color: '#1989FF', width: '30%' }}>For</span>
              <NumberInput value={For} setValue={setFor} />
              <IconButton icon="add" color={"var(--color-primary)"} onClick={() => setFor(For + 1)} />
            </div>
            <div className={classes.row}>
              <span style={{ color: "var(--color-secondary)", width: '30%' }}>Against</span>
              <NumberInput value={Against} setValue={setAgainst} />
              <IconButton icon="add" color={"var(--color-secondary)"} onClick={() => setAgainst(Against + 1)} />
            </div>
            <div className={classes.row}>
              <span style={{ color: '#9B9B9B', width: '30%' }}>Abstain</span>
              <NumberInput value={Abstain} setValue={setAbstain} />
              <IconButton icon="add" color="#9B9B9B" onClick={() => setAbstain(Abstain + 1)} />
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={classes.title} ref={titleRef}>
        {title}
      </div>
      {!data.voteStatus ? (
        <div className="defaultView">
          <RatioGraph
            datas={[{ key: 'none', value: 100, color: '#9B9B9B' }]}
            thick={thick}
            withText={false}
            width={width}
          >
            <div className={classes.votesDisplay}>
              <div className={classes.abstainText}>Pending</div>
            </div>
          </RatioGraph>
          <Space v gap={actionSpacing} />
          <div className={classes.vacantHint} style={{fontSize: valueTextSize}}>
            Please wait for the vote result
          </div>
        </div>
      ) : (
        <div className="defaultView">
          <RatioGraph
            thick={thick}
            datas={[
              { key: 'for', value: For, color: '#1989FF' },
              { key: 'against', value: Against, color: '#FFAE65' },
              { key: 'abstain', value: Abstain, color: '#9B9B9B' },
            ]}
            width={width}
          >
            <div className={classes.votesDisplay}>
              <div className={classes.forText}>
                For
                {For}
              </div>
              <div className={classes.againstText}>
                Against
                {Against}
              </div>
              <div className={classes.abstainText}>
                Abstain
                {Abstain}
              </div>
            </div>
          </RatioGraph>
          <Space v gap={actionSpacing} />
          <div className={classes.actionTab}>
            {data.voteStatus === VoteStatus.Pass
              ? <span className={[classes.button, classes.passed, classes.active].join(' ')} style={{fontSize: resultButtonTextSize}}>Pass</span>
              : <span className={[classes.button, classes.failed, classes.active].join(' ')} style={{fontSize: resultButtonTextSize}}>Fail</span>}
          </div>
        </div>
      )}
    </>
  );
}
