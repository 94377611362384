import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import theme, { Theme } from 'theme';
import Input from 'components/basic/Input';
import Space from 'components/basic/Space';
import Button from 'components/basic/Button';
import { useHistory, useParams } from 'react-router-dom';
import Check from 'components/basic/Check';
import Card from 'components/basic/Card';
import IndividualSelector from 'components/section/IndividualSelector/index';
import {
  useRoomQuery,
  useUpdateRoomMutation,
  useCreateBatchIndividualMutation,
  RoomUpdatedSubscription,
  RoomUpdatedSubscriptionVariables,
  CreateBatchIndividualDataInputType,
  IndividualType,
  useClearAllIndividualMutation,
} from 'graphql/generated/graphql';
import { ROOM_UPDATED_SUBSCRIPTION } from 'graphql/subscriptions';
import {
  UNSC,
  ECOSOC,
  UNHRC,
  ARF,
  OPEC,
  EU,
  OSCE,
} from 'constant';
import { isOrg } from 'utils/individual';
import { useDebouncedCallback } from 'use-debounce';
import classes from './style.module.css';

type OrganizationType = 'UNSC' | 'ECOSOC' | 'UNHRC' | 'ARF' | 'OPEC' | 'EU' | 'OSCE' | '';

export default function RoomPanel() {
  const { roomId } = useParams<{ roomId: string }>();
  const history = useHistory();
  const {
    loading, data, subscribeToMore,
  } = useRoomQuery({ variables: { RID: roomId } });

  const [updateRoom] = useUpdateRoomMutation({
    // onError: (e) => console.log(e),
  });

  const [clearAllIndividual, { loading: clearAllLoading }] = useClearAllIndividualMutation({ variables: { RID: roomId } });

  const [createBatchIndividual, { loading: createBatchLoading }] = useCreateBatchIndividualMutation({
    // onError: (e) => console.log(e),
  });

  const [roomName, setRoomName] = useState<string>('');
  const [isLogOut, setLogOut] = useState<boolean>(false);

  const [allowTemplate, setAllowTemplate] = useState<boolean>(true);

  function createBatchIndividualHelper(type: OrganizationType) {
    let targetOrganization: string[] = [];
    switch (type) {
      case 'UNSC': {
        targetOrganization = UNSC;
        break;
      }
      case 'ECOSOC': {
        targetOrganization = ECOSOC;
        break;
      }
      case 'UNHRC': {
        targetOrganization = UNHRC;
        break;
      }
      case 'ARF': {
        targetOrganization = ARF;
        break;
      }
      case 'OPEC': {
        targetOrganization = OPEC;
        break;
      }
      case 'EU': {
        targetOrganization = EU;
        break;
      }
      case 'OSCE': {
        targetOrganization = OSCE;
        break;
      }
      default: {
        break;
      }
    }
    const createBatchIndividualPayload: CreateBatchIndividualDataInputType[] = [];

    targetOrganization.forEach((member) => {
      createBatchIndividualPayload.push({
        individualData: {
          RID: roomId,
          type: isOrg(member) ? IndividualType.Org : IndividualType.Country,
          individualCode: member,
        },
      });
    });

    if (createBatchIndividualPayload.length > 0) {
      setAllowTemplate(false);
      clearAllIndividual().then((result) => {
        if (result.data?.clearAllIndividual.ok) {
          createBatchIndividual({
            variables: {
              data: createBatchIndividualPayload,
            },
          }).then(() => {
            setTimeout(() => {
              setAllowTemplate(true);
            }, 1000);
          });
        }
      });
    }
  }

  useEffect(() => subscribeToMore<RoomUpdatedSubscription, RoomUpdatedSubscriptionVariables>({
    document: ROOM_UPDATED_SUBSCRIPTION,
    variables: { RID: roomId },
    updateQuery: (prev, { subscriptionData }) => {
      const updatedRoom = subscriptionData.data.roomUpdated;
      if (updatedRoom) {
        return {
          ...prev,
          room: updatedRoom,
        };
      }
      return prev;
    },
  }), [roomId, subscribeToMore]);

  useEffect(() => {
    setRoomName(data?.room?.name || '');
  }, [data?.room?.name]);

  const handleRoomNameUpdate = useDebouncedCallback((newName: string) => {
    updateRoom({ variables: { RID: roomId, name: newName } });
  }, 500);

  function logout() {
    localStorage.removeItem('host-token');
    localStorage.removeItem('room-id');
    history.replace('/');
  }

  const templateArray: OrganizationType[] = ['UNSC', 'ECOSOC', 'UNHRC', 'ARF', 'OPEC', 'EU', 'OSCE'];
  const templateDisabled = clearAllLoading || createBatchLoading || !allowTemplate;

  return (
    <div className={classes.root}>
      <div className={classes.leftCol}>
        <Card className={classes.nameArea}>
          <div className={classes.title}>Room Name</div>
          <Space v gap={20} />
          <Input
            value={roomName}
            onChange={(e) => {
              handleRoomNameUpdate(e.target.value);
              setRoomName(e.target.value);
            }}
            style={{ padding: '10px 20px' }}
            disabled={loading}
          />
        </Card>
        <Card className={classes.templateArea}>
          <div className={classes.title}>Templates</div>
          <div className={classes.templateList}>
            {templateArray.map((item) => (
              <Button
                key={item}
                disabled={templateDisabled}
                onClick={() => createBatchIndividualHelper(item)}
                className={classes.templateButton}
              >
                {item}
              </Button>
            ))}
          </div>
        </Card>
        <Card className={classes.logoutArea}>
          <div className={classes.title}>Log Out</div>
          <Space v gap={20} />
          <Check checked={isLogOut} setCheck={setLogOut} color={theme.color.primary} label="I want to log out." />
          <Space v gap={20} />
          <Button disabled={!isLogOut} onClick={() => logout()}>Confirm</Button>
        </Card>
      </div>
      <div className={classes.rightCol}>
        <IndividualSelector roomId={roomId} />
      </div>
    </div>
  );
}
